import React, {useEffect, useImperativeHandle, useState} from 'react';
import {Col, Divider, Form, Input, notification, Radio, Row, Select, Spin} from 'antd';
import './index.less';
import AjaxService from "../../../../Services/AjaxService";

const {TextArea} = Input;

const FeedbackForm = React.forwardRef((props: any, ref: any) => {

    let isCancelled: any = false;

    const [form] = Form.useForm();
    const {Option} = Select;

    const [formState, setFormState] = useState({
        complaints: [] as any,
        loadingComplaints: false,

        other1Active: false,
        other2Active: false,
    });

    const searchComplaints = async (search: any) => {
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingComplaints: true,
                complaints: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`cases`, {
                filter_query: search,
                filter_type: ['complaint', 'bab']
            }, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingComplaints: false,
                complaints: (response ? response.data : previous.complaints)
            };
        });
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        form.resetFields();
        return () => {
            isCancelled = true;
        };
    }, []);

    const onSubmit = async (values: any) => {

        try {
            let body = JSON.parse(JSON.stringify(values));
            body.type = 'feedback';

            let response;
            if (props.action === 'edit') {
                response = await AjaxService.put(`cases/${props.id}`, body);
            } else {
                response = await AjaxService.post('cases', body);
            }
            props.onAdd(response.data.id, (response.data.reference ? response.data.reference : response.data.id), response.data.type.value, props.action);
        } catch (e: any) {
            notification.error({
                message: (props.action === 'edit' ? 'Edit' : 'Add') + ' Feedback',
                description: 'There was an issue ' + (props.action === 'edit' ? 'updat' : 'creat') + 'ing the case. Please try again.'
            });
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loading: false
                };
            });
        }

    }

    useImperativeHandle(ref, () => ({
        submitForm() {
            form.submit();
        }
    }));

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="Complaint/BAB Case"
                        name="meta:Complaint"
                        rules={[{required: true, message: 'Please select a Complaint or BAB Case!'}]}
                    >
                        <Select
                            placeholder="Search for a Complaint/BAB Case"
                            showSearch
                            onSearch={searchComplaints}
                            filterOption={false}
                            notFoundContent={formState.loadingComplaints ? <Spin size="small"/> : null}
                            loading={formState.loadingComplaints}
                        >
                            {formState.complaints.map((item: any) => (
                                <Option key={item.id} value={item.id}>
                                    <span
                                        className={`TrafficLight Is-${item.traffic_light}`}/> {item.reference ?? '#' + item.id} - {item.contact.first_name} {item.contact.last_name}
                                    <small style={{display: 'block'}}>{item.operator && item.operator.title}</small>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}><Divider style={{marginTop: 0}}>Feedback</Divider></Col>
                <Col span={24}>
                    <div className="ant-form-item-label">
                        <label className="ant-form-item-required">How did you first contact Bus Users UK?</label>
                    </div>
                    <Input.Group compact>
                        <Form.Item
                            name="meta:Feedback - 1: How did you first contact Bus Users UK?"
                            rules={[{required: true, message: 'Please answer the question!'}]}
                        >
                            <Radio.Group
                                options={[
                                    {label: 'E-mail', value: 'E-mail'},
                                    {label: 'Telephone', value: 'Telephone'},
                                    {label: 'Letter', value: 'Letter'},
                                    {label: 'Other', value: 'Other'}
                                ]}
                                optionType="button"
                                onChange={(e: any) => setFormState({
                                    ...formState,
                                    other1Active: (e.target.value === 'Other')
                                })}
                            />
                        </Form.Item>
                        {formState.other1Active && (
                            <Form.Item
                                name="meta:Feedback - 1: How did you first contact Bus Users UK? - Other"
                                rules={[{required: true, message: 'Please answer the question!'}]}
                            >
                                <Input placeholder="Other" style={{marginLeft: '.5rem', width: 'auto'}}/>
                            </Form.Item>
                        )}
                    </Input.Group>
                </Col>
                <Col span={24}>
                    <div className="ant-form-item-label">
                        <label className="ant-form-item-required">How did you hear about us?</label>
                    </div>
                    <Input.Group compact>
                        <Form.Item
                            name="meta:Feedback - 2: How did you hear about us?"
                            rules={[{required: true, message: 'Please answer the question!'}]}
                        >
                            <Select
                                style={{width: '310px'}}
                                placeholder="How did you hear about us?"
                                showSearch
                                options={[
                                    {label: 'Bus Stop/Station', value: 'Bus Stop/Station'},
                                    {label: 'Publications', value: 'Publications'},
                                    {label: 'On the bus/coach', value: 'On the bus/coach'},
                                    {
                                        label: 'Referred by bus/coach company',
                                        value: 'Referred by bus/coach company'
                                    },
                                    {label: 'Internet', value: 'Internet'},
                                    {label: 'Other', value: 'Other'}
                                ]}
                                onChange={(value: any) => setFormState({
                                    ...formState,
                                    other2Active: (value === 'Other')
                                })}
                            />
                        </Form.Item>
                        {formState.other2Active && (
                            <Form.Item
                                name="meta:Feedback - 2: How did you hear about us? - Other"
                                rules={[{required: true, message: 'Please answer the question!'}]}
                            >
                                <Input placeholder="Other" style={{marginLeft: '.5rem', width: 'auto'}}/>
                            </Form.Item>
                        )}
                    </Input.Group>
                </Col>
                <Col span={24}>
                    <p><strong>How much do you agree or disagree with the following statements about how your
                        contact with the Bus Appeals Body was handled?</strong></p>
                    <Form.Item
                        label="It was easy to contact the Bus Appeals Body"
                        name="meta:Feedback - 3: It was easy to contact Bus Users UK"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Strongly agree', value: 'Strongly agree'},
                                {label: 'Agree', value: 'Agree'},
                                {label: 'Neither agree nor disagree', value: 'Neither agree nor disagree'},
                                {label: 'Disagree', value: 'Disagree'},
                                {label: 'Strongly disagree', value: 'Strongly disagree'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="I was happy with the time it took to get through to someone who could help"
                        name="meta:Feedback - 4: I was happy with the time it took to get through to someone who could help"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Strongly agree', value: 'Strongly agree'},
                                {label: 'Agree', value: 'Agree'},
                                {label: 'Neither agree nor disagree', value: 'Neither agree nor disagree'},
                                {label: 'Disagree', value: 'Disagree'},
                                {label: 'Strongly disagree', value: 'Strongly disagree'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="The person I spoke to was polite and courteous"
                        name="meta:Feedback - 5: The person I spoke to was polite and courteous"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Strongly agree', value: 'Strongly agree'},
                                {label: 'Agree', value: 'Agree'},
                                {label: 'Neither agree nor disagree', value: 'Neither agree nor disagree'},
                                {label: 'Disagree', value: 'Disagree'},
                                {label: 'Strongly disagree', value: 'Strongly disagree'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="They understood my complaint"
                        name="meta:Feedback - 6: They understood my complaint"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Strongly agree', value: 'Strongly agree'},
                                {label: 'Agree', value: 'Agree'},
                                {label: 'Neither agree nor disagree', value: 'Neither agree nor disagree'},
                                {label: 'Disagree', value: 'Disagree'},
                                {label: 'Strongly disagree', value: 'Strongly disagree'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="They took my complaint seriously"
                        name="meta:Feedback - 7: They took my complaint seriously"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Strongly agree', value: 'Strongly agree'},
                                {label: 'Agree', value: 'Agree'},
                                {label: 'Neither agree nor disagree', value: 'Neither agree nor disagree'},
                                {label: 'Disagree', value: 'Disagree'},
                                {label: 'Strongly disagree', value: 'Strongly disagree'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="They were helpful"
                        name="meta:Feedback - 8: They were helpful"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Strongly agree', value: 'Strongly agree'},
                                {label: 'Agree', value: 'Agree'},
                                {label: 'Neither agree nor disagree', value: 'Neither agree nor disagree'},
                                {label: 'Disagree', value: 'Disagree'},
                                {label: 'Strongly disagree', value: 'Strongly disagree'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="They had the knowledge to deal with my complaint"
                        name="meta:Feedback - 9: They had the knowledge to deal with my complaint"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Strongly agree', value: 'Strongly agree'},
                                {label: 'Agree', value: 'Agree'},
                                {label: 'Neither agree nor disagree', value: 'Neither agree nor disagree'},
                                {label: 'Disagree', value: 'Disagree'},
                                {label: 'Strongly disagree', value: 'Strongly disagree'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="I was happy with the time it took to get a response/decision"
                        name="meta:Feedback - 10: I was happy with the time it took to get a response/decision"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Strongly agree', value: 'Strongly agree'},
                                {label: 'Agree', value: 'Agree'},
                                {label: 'Neither agree nor disagree', value: 'Neither agree nor disagree'},
                                {label: 'Disagree', value: 'Disagree'},
                                {label: 'Strongly disagree', value: 'Strongly disagree'}
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Overall, how satisfied were you with the way we handled your complaint?"
                        name="meta:Feedback - 11: Overall, how satisfied were you with the way we handled your complaint?"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Very satisfied', value: 'Very satisfied'},
                                {label: 'Satisfied', value: 'Satisfied'},
                                {
                                    label: 'Neither satisfied nor dissatisfied',
                                    value: 'Neither satisfied nor dissatisfied'
                                },
                                {label: 'Dissatisfied', value: 'Dissatisfied'},
                                {label: 'Very dissatisfied', value: 'Very dissatisfied'},
                                {label: `Don't know`, value: `Don't know`}
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="How satisfied were you with the outcome of your contact with the Bus Appeals Body?"
                        name="meta:Feedback - 12: How satisfied were you with the outcome of your contact with Bus Users UK?"
                        rules={[{required: true, message: 'Please answer the question!'}]}
                    >
                        <Select
                            style={{width: '310px'}}
                            placeholder="Select an answer"
                            showSearch
                            options={[
                                {label: 'Very satisfied', value: 'Very satisfied'},
                                {label: 'Satisfied', value: 'Satisfied'},
                                {
                                    label: 'Neither satisfied nor dissatisfied',
                                    value: 'Neither satisfied nor dissatisfied'
                                },
                                {label: 'Dissatisfied', value: 'Dissatisfied'},
                                {label: 'Very dissatisfied', value: 'Very dissatisfied'},
                                {label: `Don't know`, value: `Don't know`}
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Anything you would like to add?"
                        name="meta:Feedback - 13: Anything you would like to add?"
                    >
                        <TextArea placeholder="Anything you would like to add?" rows={6}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
})

export default FeedbackForm;
