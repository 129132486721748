import React from 'react';
import {Transfer, Tree} from 'antd';

function PermissionTree({permissions, onChange, value}: any) {

    const {TreeNode} = Tree;

    const onTreeChange = (node: any, checked: any, fn: any) => {
        const traverse = (node: any, keys: any = []) => {
            if (node.isLeaf) {
                keys.push(node.key.toString());
            } else {
                node.children.forEach((n: any) => {
                    keys = traverse(n, keys);
                });
            }
            return keys;
        }

        fn(traverse(node), checked);
    }

    const buildPermissionTree = (permissions: any, selected: any = false, tree: any = {}) => {
        permissions.forEach((permission: any) => {
            if (Array.isArray(selected) && selected.indexOf(permission.id.toString()) === -1) {
                return true;
            }
            let tmp: any = tree;
            permission.path.forEach((path: any) => {
                if (!(path in tmp)) {
                    tmp[path] = {
                        title: path,
                        key: path,
                        children: {},
                        permission_ids: [],
                        isLeaf: false
                    };
                }
                tmp[path].permission_ids.push(permission.id);
                tmp = tmp[path].children;
            });
            tmp[permission.id] = {
                key: permission.id.toString(),
                id: permission.id,
                title: permission.title,
                description: permission.description,
                path: [...permission.path, permission.id],
                isLeaf: true
            }
        });

        const toArray = (el: any) => {
            if ('children' in el) {
                el.children = Object.keys(el.children).map(i => toArray(el.children[i]));
            }
            return el;
        }

        tree = Object.keys(tree).map(i => toArray(tree[i]));

        return tree;
    }

    const buildTree = ({children, ...rest}: any) => {
        return (
            <TreeNode {...rest}>
                {children && children.map((child: any) => {
                    return buildTree(child);
                })}
            </TreeNode>
        );
    }

    const flatten = (list: any = [], output: any = []) => {
        list.forEach((item: any) => {
            output.push(item);
            output = flatten(item.children, output);
        });
        return output;
    }

    const flattenedPermissions = flatten(permissions);

    return (
        <Transfer
            showSelectAll={false}
            dataSource={flattenedPermissions}
            targetKeys={value}
            onChange={(keys: any) => {
                if (onChange) {
                    onChange([...keys]);
                }
            }}
            locale={{
                itemUnit: 'permissions',
                itemsUnit: 'permissions'
            }}
        >
            {({direction, onItemSelectAll, selectedKeys}) => {
                let treeData: any = buildPermissionTree(permissions, (direction === 'left' ? false : value));
                return (
                    <Tree
                        blockNode
                        checkable
                        checkedKeys={selectedKeys}
                        onCheck={(checked: any, e: any) => {
                            onTreeChange(e.node, e.checked, onItemSelectAll);
                        }}
                        onSelect={(selected: any, e: any) => {
                            onTreeChange(e.node, e.checked, onItemSelectAll);
                        }}
                    >
                        {treeData && treeData.map((n: any) => {
                            return buildTree(n);
                        })}
                    </Tree>
                );
            }}
        </Transfer>
    );
}

export default PermissionTree;