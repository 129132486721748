import React, {useEffect, useState} from 'react';
import {Alert, Col, Form, Input, Modal, notification, Row, Select} from 'antd';
import './index.less';
import AjaxService from "../../Services/AjaxService";

function CaseStatusModal(props: any) {

    const [form] = Form.useForm();

    const [modalState, setModalState] = useState({
        loading: false,
        error: '',
        search: ''
    });

    useEffect(() => {
        if (props.current) {
            props.current.final = props.current.final.toString();
            form.setFieldsValue(props.current);
        }
    }, [props, form])

    const addStatus = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            let response = await AjaxService.post('case-statuses', values);
            props.onAdd(response.data);
            closeModal();
        } catch (e: any) {
            if (!('title' in e.data.errors)) {
                notification.error({
                    message: 'Add Case Status',
                    description: 'There was an issue adding the case status. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    error: ('title' in e.data.errors ? e.data.errors.title[0] : ''),
                    loading: false
                };
            });
        }
    }

    const editStatus = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            let response = await AjaxService.put('case-statuses/' + props.current.id, values);
            props.onEdit(response.data);
            closeModal();
        } catch (e: any) {
            if (!('title' in e.data.errors)) {
                notification.error({
                    message: 'Edit Case Status',
                    description: 'There was an issue editing the case status. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    error: ('title' in e.data.errors ? e.data.errors.title[0] : ''),
                    loading: false
                };
            });
        }
    }

    const closeModal = async () => {
        setModalState((previous: any) => {
            return {
                ...previous,
                loading: false
            };
        });
        form.resetFields();
        props.onClose();
    }

    return (
        <>
            <Modal
                visible
                title={props.current ? 'Edit Case Status' : 'Add Case Status'}
                onOk={() => form.submit()}
                onCancel={closeModal}
                destroyOnClose
                forceRender
                confirmLoading={modalState.loading}
            >
                <Form
                    form={form}
                    onFinish={(values: any) => {
                        (props.current ? editStatus(values) : addStatus(values));
                    }}
                    layout="vertical"
                >
                    {modalState.error !== '' && (
                        <Alert style={{marginBottom: '1rem'}} message={modalState.error} type="error" showIcon/>
                    )}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Name"
                                name="title"
                                rules={[{required: true, message: 'Name is required!'}]}
                            >
                                <Input placeholder="Name"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Final"
                                name="final"
                                rules={[{required: true, message: 'Final is required!'}]}
                            >
                                <Select
                                    placeholder="Select a value"
                                >
                                    <Select.Option key="0" value="0">No</Select.Option>
                                    <Select.Option key="1" value="1">Yes, case closed!</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default CaseStatusModal;