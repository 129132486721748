import React, {useEffect, useState} from 'react';
import {Col, notification, Row, Skeleton} from 'antd';
import AjaxService from '../../../../Services/AjaxService';
import './index.less';
import moment from 'moment';

function ComplaintRow(props: any) {

    let isCancelled = false;

    const [rowState, setRowState] = useState({
        loading: true,
        rowData: false as any
    });

    const fetchData = async () => {
        try {
            console.log('Complaint/Row: fetching data...');
            let response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta']});
            if (!isCancelled) {
                setRowState({...rowState, loading: false, rowData: response.data});
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        if ('id' in props && props.id && !rowState.rowData) {
            fetchData();
        } else {
            setRowState({...props});
        }
        return () => {
            isCancelled = true;
        };
    }, [props.id, props.rowData]);

    return (
        <Skeleton active loading={rowState.loading}>
            {!rowState.loading && rowState.rowData !== false && (
                <Row>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Case Number</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.reference ? rowState.rowData.reference : rowState.rowData.id)}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Handler</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.assigned_to ? rowState.rowData.assigned_to.initials : 'N/A')}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Complainant</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.contact ? `${rowState.rowData.contact.first_name} ${rowState.rowData.contact.last_name}` : 'N/A'}</Col>
                         {/* </Row>style={{padding: '4px 0'}}>{rowState.rowData.meta['Name'] ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Type</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Types'] && Object.keys(rowState.rowData.meta['Types']).length ? Object.keys(rowState.rowData.meta['Types']).map((key: any) => {
                        return <div key={key}>{key}. {rowState.rowData.meta['Types'][key]}</div>
                    }) : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Operator/Authority</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.operator ? rowState.rowData.operator.title + (rowState.rowData.operator.deleted_at ? ' (D)' : '') : 'N/A')}</Col>
                         {/* style={{padding: '4px 0'}}>{rowState.rowData.operator ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Location</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.location ? `${rowState.rowData.location.title}, ${rowState.rowData.location.region}` : 'N/A'}</Col>
                         {/* style={{padding: '4px 0'}}>{rowState.rowData.location ?? 'N/A'}</Col> */}

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Cross Border</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{Number.parseInt(rowState.rowData.meta['Cross Border']) ? 'Yes' : 'No'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Contact</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.contact && rowState.rowData.contact.email ? rowState.rowData.contact.email : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Non-Acceptance of Complaint Code</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Non-Acceptance of Complaint Code'] ?? 'N/A'}</Col>
                         {/* style={{padding: '4px 0'}}>{rowState.rowData.contact ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Country</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Country'] ? rowState.rowData.meta['Country'] : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Authorised by Passenger</strong></Col>
                    <Col span={24} sm={12} lg={8}
                        style={{padding: '4px 0'}}>{(rowState.rowData.meta['Authorised by Passenger'] ? moment(rowState.rowData.meta['Authorised by Passenger']).format('DD/MM/YYYY HH:mm') : 'No')}</Col>

                </Row>
            )}
        </Skeleton>
    );
}

export default ComplaintRow;