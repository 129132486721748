import React from 'react';
import {ReactComponent as Logo} from '../../../Assets/logo-red.svg';
import './index.less';

function Auth(props: any) {
    return (
        <div className="Auth">
            <header>
                <Logo/>
            </header>
            {props.children}
        </div>
    );
}

export default Auth;