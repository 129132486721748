import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Divider, PageHeader, Row, Tag, Tooltip} from 'antd';
import {CheckOutlined, EyeInvisibleTwoTone, EyeOutlined, HistoryOutlined} from '@ant-design/icons';
import FeedbackRow from '../Row';
import './index.less';
import {ProfileContext, useCan} from "../../../../Contexts/ProfileContext";

function Feedback(props: any) {

    const [tabState, setTabState] = useState(props);

    useEffect(() => {
        setTabState(props);
    }, [props]);

    const {profile} = useContext(ProfileContext);
    const {can} = useCan(profile);

    const feedbackClassMap: any = {
        'Strongly agree': 5,
        'Agree': 4,
        'Neither agree nor disagree': 3,
        'Disagree': 2,
        'Strongly disagree': 1,
        'Very satisfied': 5,
        'Satisfied': 4,
        'Neither satisfied nor dissatisfied': 3,
        'Dissatisfied': 2,
        'Very dissatisfied': 1
    };

    let feedbackQuestions: any = {};
    if (tabState.rowData.meta) {
        Object.keys(tabState.rowData.meta).forEach((key: any) => {
            if (key.indexOf('Feedback - ') === 0) {
                const keyMatches: any = /Feedback - (\d+): (.*)/gi.exec(key);
                if (keyMatches) {

                    let value = tabState.rowData.meta[key],
                        newKey = keyMatches[2].replace(' - Other', '');

                    if (key.indexOf('- Other') > -1 && value) {
                        if (newKey in feedbackQuestions) {
                            feedbackQuestions[newKey].value = value;
                            return true;
                        }
                    }

                    if (!(newKey in feedbackQuestions)) {
                        feedbackQuestions[newKey] = {
                            sort: keyMatches[1],
                            value: value,
                            key: newKey,
                            classes: (Object.keys(feedbackClassMap).indexOf(value) > -1 ? `Feedback-Status-${feedbackClassMap[value]}` : '')
                        };
                    }
                }
            }
        });
        feedbackQuestions = Object.keys(feedbackQuestions).map((key: any) => feedbackQuestions[key]);
        feedbackQuestions.sort((a: any, b: any) => {
            return a.sort - b.sort;
        });
    }

    return (
        <div className="CaseFeedback">
            <PageHeader title="Feedback" extra={[
                (can('cases.watch') && (
                    <Tooltip title={(tabState.rowData.watched ? 'Unwatch' : 'Watch') + ' Feedback'} key="watch">
                        <Button onClick={() => props.watch(!tabState.rowData.watched)}
                                icon={(tabState.rowData.watched ? <EyeInvisibleTwoTone twoToneColor="#52c41a"/> :
                                    <EyeOutlined/>)}/>
                    </Tooltip>
                )),
                (can('cases.feedback.archive') && (tabState.rowState?.status?.title || tabState.rowState?.status?.title !== 'Archived') && (
                    <Tooltip title={'Archive Feedback'} key="archive">
                        <Button onClick={() => props.archiveCase(tabState.rowData.id)} icon={<HistoryOutlined />}/>
                    </Tooltip>
                )),
                (can('cases.feedback.mark_read') && (
                    <Tooltip title="Mark as read" key="edit">
                        <Button icon={<CheckOutlined/>}/>
                    </Tooltip>
                ))
            ]} tags={[
                <Tag key="status-tag" color="green">{tabState.rowData.status.title}</Tag>
            ]}/>
            <FeedbackRow {...tabState} openTab={props.openTab}/>
            <Divider>Response</Divider>
            <Row>
                {feedbackQuestions.map((question: any) => {
                    return (
                        <React.Fragment key={question.key}>
                            <Col span={24} sm={12}
                                 style={{padding: '4px 0'}}><strong>{question.key}</strong></Col>
                            <Col span={24} sm={12}
                                 style={{padding: '4px 0'}}
                                 className={question.classes}>{question.value.split('\n').map((chunk: any, key: any) => {
                                return (
                                    <React.Fragment key={key}>
                                        {chunk}
                                        <br/>
                                    </React.Fragment>
                                );
                            })}</Col>
                        </React.Fragment>
                    );
                })}
            </Row>
        </div>
    );
}

export default Feedback;