import { Radio, Steps, Form, Button, Col, Row, Input, DatePicker, Select, notification } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Master from '../../../Components/Layouts/Master';
import AjaxService from '../../../Services/AjaxService';
import './index.less';

function List(props: any) {
    
    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;

    const [formState, setFormState] = useState({
        step: 0,
        reportKey: '',
        reportValid: false,
        reportOperator: '',
        reportRegion: '',
        reportDates: [] as any,
        countries: [] as any,
        loadingCountries: true
    });

    const fetchCountries = async () => {
        try {
            let response = await AjaxService.get(`cases/meta/`, {
                filter_case_type: 'complaint',
                filter_key: 'Country'
            }, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingCountries: false,
                    countries: response.data.map((country: any) => {
                        return {
                            label: country,
                            value: country
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const filterSelect = (input: any, option: any) => {
        let value = ('children' in option ? option.children : option.label);
        if (Array.isArray(value)) {
            value = value.join('');
        }
        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const onSubmit = async (values: any) => {
        console.log(values);
    }

    const handleReportChange = (e: any) => {
        setFormState({...formState, reportKey: e.target.value});
    }

    const validateReportOptions = (key: any, e: any) => {
        let state = {...formState, [key]: (e && typeof e !== 'string' && 'target' in e ? e.target.value : e)};

        switch (formState.reportKey) {
            case 'operator':
                state.reportValid = (state.reportOperator !== '' && state.reportDates.length > 0);
                break;
            case 'adr':
                state.reportValid = (state.reportRegion !== '' && state.reportDates.length > 0);
                break;
            case 'schedule5':
                state.reportValid = (state.reportDates.length > 0);
                break;
            case 'natex':
                state.reportValid = (state.reportOperator !== '' && state.reportDates.length > 0);
                break;
        }

        setFormState({...state});
    }

    const generateReport = async (e: any) => {
        try {
            setFormState((previous: any) => {
                return {...previous, reportLoading: true}
            });

            let data = {
                report: formState.reportKey
            } as any;
            switch (formState.reportKey) {
                case 'operator':
                    data = {...data, operator: formState.reportOperator, startDate: formState.reportDates[0].format('YYYY-MM-DD'), endDate: formState.reportDates[1].format('YYYY-MM-DD')};
                    break;
                case 'adr':
                    data = {...data, region: formState.reportRegion, startDate: formState.reportDates[0].format('YYYY-MM-DD'), endDate: formState.reportDates[1].format('YYYY-MM-DD')};
                    break;
                case 'schedule5':
                    data = {...data, startDate: formState.reportDates[0].format('YYYY-MM-DD'), endDate: formState.reportDates[1].format('YYYY-MM-DD')};
                    break;
                case 'natex':
                    data = {...data, operator: formState.reportOperator, startDate: formState.reportDates[0].format('YYYY-MM-DD'), endDate: formState.reportDates[1].format('YYYY-MM-DD')};
                    break;
            }

            let response = await AjaxService.post(`reports/generate`, data);
            if (response.report) {
                form.resetFields();
                window.location.href = response.report;
                setFormState((previous: any) => {
                    return {
                        ...previous,
                        step: 0,
                        reportKey: '',
                        reportDates: [],
                        reportOperator: '',
                        reportRegion: '',
                        reportValid: false,
                        reportLoading: false
                    }
                });
            } else {
                notification.error({
                    message: 'There was an error',
                    description: 'Unfortunately we could not load the required information. Please try again.'
                });
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    useEffect(() => {
        // eslint-disable-next-line
        form.resetFields();
        fetchCountries();
        return () => {
        };
    }, []);

    return (
        <Master className={`Reports`}>
            <div className="CaseTableActions">
                <span className="CaseTableTitle">Reporting</span>
            </div>
            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    reportKey: '',
                    region: '',
                    date: []
                }}
                onFinish={onSubmit}
            >
                <Steps current={formState.step} size="small">
                    <Steps.Step title="Select report"></Steps.Step>
                    <Steps.Step title="Report options"></Steps.Step>
                    <Steps.Step title="Generate"></Steps.Step>
                </Steps>

                {formState.step == 0 && (
                    <div className="StepContent">
                        <Radio.Group name="reportKey" value={formState.reportKey}>
                            <ul className="ReportList">
                                <li>
                                    <label>
                                        <Radio onChange={handleReportChange} key="operator" value="operator"></Radio> <strong>Operator Report</strong>
                                        <span></span>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <Radio onChange={handleReportChange} key="adr" value="adr"></Radio> <strong>ADR Report</strong>
                                        <span></span>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <Radio onChange={handleReportChange} key="schedule5" value="schedule5"></Radio> <strong>Schedule 5 Report</strong>
                                        <span></span>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <Radio onChange={handleReportChange} key="natex" value="natex"></Radio> <strong>National Express Report</strong>
                                        <span></span>
                                    </label>
                                </li>
                            </ul>
                        </Radio.Group>
                        <div className="StepContentButtons">
                            <Button className="Next" type="primary" disabled={!formState.reportKey} onClick={() => setFormState({...formState, step: formState.step + 1})}>Next</Button>
                        </div>
                    </div>
                )}

                {formState.step == 1 && (
                    <div className="StepContent">
                        <div className="OptionsBox">
                            <Row>
                                {['operator','natex'].indexOf(formState.reportKey) > -1 && (
                                    <Col span={24}>
                                        <Form.Item
                                            label="Operator"
                                            name="operator"
                                            rules={[{required: true, message: 'Please enter the operator!'}]}
                                        >
                                            <Input placeholder="Operator" onChange={e => validateReportOptions('reportOperator', e)}/>
                                        </Form.Item>
                                    </Col>
                                )}
                                {['adr'].indexOf(formState.reportKey) > -1 && (
                                    <Col span={24}>
                                        <Form.Item
                                            label="Region"
                                            name="region"
                                            rules={[{required: true, message: 'Please select the region!'}]}
                                        >
                                            <Select
                                                style={{width: '100%'}}
                                                showSearch
                                                filterOption={filterSelect}
                                                placeholder="Select the region"
                                                onChange={e => validateReportOptions('reportRegion', e)}
                                            >
                                                <Select.Option key="England" value="England">England</Select.Option>
                                                <Select.Option key="Scotland" value="Scotland">Scotland</Select.Option>
                                                <Select.Option key="Wales" value="Wales">Wales</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                                <Col span={24}>
                                    <Form.Item
                                        label="Date Range"
                                        name="date"
                                        rules={[{required: true, message: 'Please select a date range!'}]}
                                    >
                                        <RangePicker
                                            style={{width: '100%'}}
                                            onChange={e => validateReportOptions('reportDates', e)}
                                            ranges={{
                                                Today: [moment(), moment()],
                                                'This Year': [moment().startOf('year'), moment().endOf('year')],
                                                'Last Year': [moment().subtract(1, 'year').startOf('month'), moment().subtract(1, 'year').endOf('year')],
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col span={12}>
                                    <Form.Item
                                        label="Country"
                                        name="meta:Country"
                                        rules={[{required: false, message: 'Please select a country or create a new one!'}]}
                                    >
                                        <Select
                                            style={{width: '100%'}}
                                            showSearch
                                            filterOption={filterSelect}
                                            loading={formState.loadingCountries}
                                            placeholder="Search for a country"
                                        >
                                            {formState.countries && formState.countries.map((country: any) => {
                                                return <Select.Option key={country.value}
                                                                    value={country.value}>{country.label}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col> */}
                            </Row> 
                        </div>
                        <div className="StepContentButtons">
                            <Button className="Prev" onClick={() => setFormState({...formState, step: formState.step-1})}>Back</Button>
                            <Button className="Next" disabled={!formState.reportValid} type="primary" onClick={generateReport}>Generate</Button>
                        </div>
                    </div>
                )}

                {formState.step == 2 && (
                    <p>Generate report</p>
                )}
            </Form>
        </Master>
    );
}

export default List;