import React, {useEffect, useState} from 'react';
import {Alert, Col, Form, Input, Modal, notification, Row, Select} from 'antd';
import './index.less';
import AjaxService from "../../Services/AjaxService";

function ContactModal(props: any) {

    const [form] = Form.useForm();

    const [modalState, setModalState] = useState({
        loading: false,
        errors: [] as any,
        contact_type: ('contactType' in props ? props.contactType : 'passenger')
        // contact_type: 'passenger'
    });

    const addContact = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                errors: [],
                loading: true
            };
        });

        try {
            let response = await AjaxService.post('contacts', values);
            props.onAdd(response.data);
            closeModal();
        } catch (e: any) {
            if (!('errors' in e.data)) {
                notification.error({
                    message: 'Add Contact',
                    description: 'There was an issue adding the contact. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    errors: ('errors' in e.data ? e.data.errors : []),
                    loading: false
                };
            });
        }
    }

    const editContact = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                errors: [],
                loading: true
            };
        });

        try {
            let response = await AjaxService.put('contacts/' + props.current.id, values);
            props.onEdit(response.data);
            closeModal();
        } catch (e: any) {
            if (!('errors' in e.response.data)) {
                notification.error({
                    message: 'Edit Contact',
                    description: 'There was an issue editing the contact. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    errors: ('errors' in e.data ? e.data.errors : []),
                    loading: false
                };
            });
        }
    }

    const closeModal = async () => {
        setModalState((previous: any) => {
            return {
                ...previous,
                loading: false
            };
        });
        form.resetFields();
        props.onClose();
    }

    useEffect(() => {
        if (props.current) {
            form.setFieldsValue(props.current);
        }
    }, [props, form])

    return (
        <Modal
            visible
            title={props.current ? 'Edit Contact' : 'Add Contact'}
            onOk={() => form.submit()}
            onCancel={closeModal}
            destroyOnClose
            forceRender
            confirmLoading={modalState.loading}
        >
            <Form
                form={form}
                onFinish={(values: any) => {
                    (props.current ? editContact(values) : addContact(values));
                }}
                layout="vertical"
                initialValues={{
                    contact_type: ('contactType' in props ? props.contactType : 'passenger')
                }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[{required: true, message: 'First Name is required!'}]}
                        >
                            <Input placeholder="First Name"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                        >
                            <Input placeholder="Last Name"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            {...('email' in modalState.errors) && {
                                help: modalState.errors.email[0],
                                validateStatus: 'error'
                            }}
                            rules={[
                                {required: modalState.contact_type === 'operator', message: 'Email Address is required!'},
                                {type: 'email', message: 'Invalid email address!'},
                            ]}
                        >
                            <Input type="email" placeholder="Email Address"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Telephone Number"
                            name="telephone"
                            required
                        >
                            <Input type="telephone" placeholder="Telephone"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Contact Type"
                            name="contact_type"
                            rules={[{required: true, message: 'Contact Type is required!'}]}
                            {...('contact_type' in modalState.errors) && {
                                help: modalState.errors.contact_type[0],
                                validateStatus: 'error'
                            }}
                        >
                            <Select onChange={(contact_type: any) => setModalState({...modalState, contact_type})}>
                                <Select.Option key="operator" value="operator">Operator</Select.Option>
                                <Select.Option key="passenger" value="passenger">Passenger</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {modalState.contact_type === 'passenger' && (
                        <>
                            <Col span={12} />
                            <Col span={12}>
                                <Form.Item
                                    label="Address Line 1"
                                    name="address_line_1"
                                >
                                    <Input type="text" placeholder="Address Line 1"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Address Line 2"
                                    name="address_line_2"
                                >
                                    <Input type="text" placeholder="Address Line 2"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Town"
                                    name="town"
                                >
                                    <Input type="text" placeholder="Town"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="County"
                                    name="county"
                                >
                                    <Input type="text" placeholder="County"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Post Code"
                                    name="postcode"
                                >
                                    <Input type="text" placeholder="Post Code"/>
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            </Form>
        </Modal>
    );
}

export default ContactModal;