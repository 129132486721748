import React, {useEffect, useState} from 'react';
import {Col, notification, Row, Skeleton} from "antd";
import AjaxService from '../../../../Services/AjaxService';
import moment from 'moment';
import './index.less';

function BABRow(props: any) {

    let isCancelled = false;

    const [rowState, setRowState] = useState({
        loading: true,
        rowData: false as any
    });

    const fetchData = async () => {
        try {
            console.log('BAB/Row: fetching data...');
            let response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta']});
            if (!isCancelled) {
                setRowState({...rowState, loading: false, rowData: response.data});
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        if ('id' in props && props.id && !rowState.rowData) {
            fetchData();
        } else {
            setRowState({...props});
        }
        return () => {
            isCancelled = true;
        };
    }, [props.id, props.rowData]);

    return (
        <Skeleton active loading={rowState.loading}>
            {!rowState.loading && rowState.rowData !== false && (
                <Row>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>BAB No</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.reference ? rowState.rowData.reference : rowState.rowData.id)}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Appellant</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.contact ? `${rowState.rowData.contact.first_name} ${rowState.rowData.contact.last_name}` : 'N/A')}</Col>
                         {/* style={{padding: '4px 0'}}>{rowState.rowData.contact ?? 'N/A'}</Col> */}

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Bus Users UK No</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Complaint'] ? (
                        <span onClick={() => props.openTab(
                            rowState.rowData.meta['Complaint'].id,
                            rowState.rowData.meta['Complaint'].reference,
                            'complaint'
                        )}
                              className="clickable">{(rowState.rowData.meta['Complaint'].reference ? rowState.rowData.meta['Complaint'].reference : rowState.rowData.meta['Complaint'].id)}</span>) : 'N/A'}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Received by Pax Rep</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.meta['Received by Pax Rep'] ? moment(rowState.rowData.meta['Received by Pax Rep'], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'N/A')}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Date Referred</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.meta['Date Referred'] ? moment(rowState.rowData.meta['Date Referred'], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'N/A')}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Emailed to BAB Admin</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.meta['Emailed to BAB Admin'] ? moment(rowState.rowData.meta['Emailed to BAB Admin'], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'N/A')}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Operator</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{(rowState.rowData.operator ? rowState.rowData.operator.title + (rowState.rowData.operator.deleted_at ? ' (D)' : '') : 'N/A')}</Col>
                    {/* <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{rowState.rowData.operator ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Status Information</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.meta['Status Information'] && rowState.rowData.meta['Status Information'].length ? rowState.rowData.meta['Status Information'].join(', ') : 'N/A')}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Contact</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{(rowState.rowData.contact ? rowState.rowData.contact.email : 'N/A')}</Col>
                    {/* <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{rowState.rowData.meta['Contact'] ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Handler</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.assigned_to ? rowState.rowData.assigned_to.initials : 'N/A')}</Col>

                    {/*<Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Current Status</strong></Col>*/}
                    {/*<Col span={24} sm={12} lg={8}*/}
                    {/*     style={{padding: '4px 0'}}>{(rowState.rowData.meta['Current Status'] ?? 'N/A')}</Col>*/}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Feedback Form Posted</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.meta['Feedback Form Posted'] === '1' ? 'Yes' : 'No')}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Type</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Types'] && Object.keys(rowState.rowData.meta['Types']).length ? Object.keys(rowState.rowData.meta['Types']).map((key: any) => {
                        return <div key={key}>{key}. {rowState.rowData.meta['Types'][key]}</div>
                    }) : 'N/A'}</Col>
                </Row>
            )}
        </Skeleton>
    );
}

export default BABRow;