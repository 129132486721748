import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Auth from '../../../Components/Layouts/Auth';
import {Alert, Button, Form, Input, notification} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import AjaxService from '../../../Services/AjaxService';
import './index.less';
import {ProfileContext} from "../../../Contexts/ProfileContext";

function Login() {

    const [formState, setFormState] = useState({
        loading: false,
        errors: {} as any
    });

    const history = useHistory();
    const {updateProfile} =  useContext(ProfileContext);

    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const getProfile = async () => {
        const profile = await AjaxService.get('users/profile');
        updateProfile(profile.data);
        return profile.data;
    }

    const onFinish = async (values: any) => {
        try {
            setFormState({...formState, errors: {}, loading: true});
            const result = await AjaxService.post('users/create-token', values);
            window.localStorage.setItem('auth_token', result.token);
            await getProfile();
            history.push('/');
            notification.success({
                message: 'Login Successful',
                description: `Welcome ${result.first_name}`
            });
        } catch (e: any) {
            console.log(e);
            setFormState({
                ...formState, loading: false, errors: ('errors' in e.data ? e.data.errors : {
                    generic: ['An error has occurred, please try again']
                })
            });
        }
    };

    return (
        <Auth>
            <Form
                {...layout}
                name="login"
                onFinish={onFinish}
            >
                {'generic' in formState.errors && formState.errors.generic && (
                    <Alert
                        message={formState.errors.generic[0]}
                        type="error"
                    />
                )}
                <Form.Item
                    name="email"
                    rules={[
                        {required: true, message: 'Please input your email address!'},
                        {type: 'email', message: 'Your email address is invalid!'},
                    ]}
                    validateStatus={('email' in formState.errors && formState.errors.email ? 'error' : undefined)}
                    help={('email' in formState.errors && formState.errors.email ? formState.errors.email[0] : undefined)}
                >
                    <Input type="email" autoComplete="off" placeholder="Email address" prefix={<UserOutlined/>}/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{required: true, message: 'Please input your password!'}]}
                >
                    <Input.Password autoComplete="off" placeholder="Password" prefix={<LockOutlined/>}/>
                </Form.Item>

                <Form.Item>
                    <Button loading={formState.loading} type="primary" block={true} htmlType="submit">
                        {formState.loading ? 'Checking' : 'Login'}
                    </Button>
                    {/*<Link id="forgotten-password" to="/forgotten">Forgotten Password?</Link>*/}
                </Form.Item>
            </Form>
        </Auth>
    );
}

export default Login;