import React, {useEffect, useState} from 'react';
import {Col, notification, Row, Skeleton} from 'antd';
import AjaxService from '../../../../Services/AjaxService';
import './index.less';
import moment from "moment";

function FeedbackRow(props: any) {

    let isCancelled = false;

    const [rowState, setRowState] = useState({
        loading: true,
        rowData: false as any
    });

    const feedbackScoreMap: any = {
        'Very Poor': {max: 1.5, id: 1},
        'Poor': {max: 2.5, id: 2},
        'Average': {max: 3.5, id: 3},
        'Good': {max: 4.5, id: 4},
        'Very Good': {max: 5, id: 5}
    };

    const fetchData = async () => {
        try {
            console.log('Feedback/Row: fetching data...');
            let response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta']});
            if (!isCancelled) {
                setRowState({...rowState, loading: false, rowData: response.data});
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        if ('id' in props && props.id && !rowState.rowData) {
            fetchData();
        } else {
            setRowState({...props});
        }
        return () => {
            isCancelled = true;
        };
    }, [props.id, props.rowData]);

    let scoreRating: any;
    if (rowState.rowData && rowState.rowData.meta['Score']) {
        let score = parseFloat(rowState.rowData.meta['Score']);
        Object.keys(feedbackScoreMap).some((title: any) => {
            if (score >= feedbackScoreMap[title].max) {
                return false;
            }
            scoreRating = {title, id: feedbackScoreMap[title].id};
            return true;
        });
    }

    return (
        <Skeleton active loading={rowState.loading}>
            {!rowState.loading && rowState.rowData !== false && (
                <Row>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Date</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{moment(rowState.rowData.created_at).format('DD/MM/YYYY')}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Percentage</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Percentage'] ? rowState.rowData.meta['Percentage'] + '%' : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Name</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.meta['Complaint'] && rowState.rowData.meta['Complaint'].contact ? rowState.rowData.meta['Complaint'].contact : 'N/A')}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Case</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{rowState.rowData.meta['Complaint'] ? (
                        <span onClick={() => props.openTab(
                            rowState.rowData.meta['Complaint'].id,
                            rowState.rowData.meta['Complaint'].reference,
                            'complaint'
                        )}
                              className="clickable">{(rowState.rowData.meta['Complaint'].reference ? rowState.rowData.meta['Complaint'].reference : rowState.rowData.meta['Complaint'].id)}</span>) : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Overall</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{scoreRating && rowState.rowData.meta['Score'] ? <><span
                        className={`Feedback-Status-${scoreRating.id}`}>{scoreRating.title}</span> ({parseFloat(rowState.rowData.meta['Score']).toFixed(2).replace('.00', '')})</> : 'N/A'}</Col>
                    {rowState.rowData.meta['Source'] === 'Online' && (
                        <>
                            <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>IP Address</strong></Col>
                            <Col span={24} sm={12} lg={8}
                                 style={{padding: '4px 0'}}>{rowState.rowData.meta['IP Address'] ? rowState.rowData.meta['IP Address'] : 'N/A'}</Col>
                        </>
                    )}
                </Row>
            )}
        </Skeleton>
    );
}

export default FeedbackRow;