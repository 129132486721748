import React, {useContext, useEffect, useState} from 'react';
import Master from '../../../Components/Layouts/Master';
import CaseTable from '../../../Components/CaseTable';
import CaseWrapper from '../../../Components/CaseWrapper/View';
import ConfirmModal from '../../../Components/ConfirmModal';
import useLocalStorage from '../../../Helpers/State/useLocalStorage';
import EnquiryForm from '../Enquiry/Form';
import ComplaintForm from '../Complaint/Form';
import BABForm from '../BAB/Form';
import YBMForm from '../YBM/Form';
import FeedbackForm from '../Feedback/Form';
import {Button, Drawer, Modal, notification, Tabs} from 'antd';
import './index.less';
import {ProfileContext, useCan} from "../../../Contexts/ProfileContext";
import AjaxService from "../../../Services/AjaxService";
import { useHistory } from 'react-router-dom';

const {TabPane} = Tabs;

function AllCases(props: any) {

    let case_filter: any = {
        filter: 'all',
        title: 'All Cases'
    };

    const history = useHistory();
    const [openTabs, setOpenTabs] = useLocalStorage('open-tabs', []);
    const [activeTab, setActiveTab] = useLocalStorage('active-tab', 'default');
    const {profile} = useContext(ProfileContext);
    const {canAny} = useCan(profile);
    const [formState, setFormState] = useState({
        visible: false,
        action: false as any,
        type: false as any,
        id: false as any,
        title: false as any,
        loading: false as any,
        extra: false as any,
        checksum: false as any
    });
    const [filterState, setFilterState] = useState(props.match?.params?.filter);

    const openTab = async (id: any, key: any, type: any, forceRefresh: any = false, readOnly: any = false) => {
        if (canAny(['cases.' + type + '.view_any', 'cases.' + type + '.view_own'])) {

            if (!readOnly) {
                // Get the current lock status.
                let lockStatus: any = await checkTabLock(true, id, key, type, forceRefresh);
                if (!lockStatus.result) {
                    return;
                }
                if (lockStatus.result === 'read-only') {
                    readOnly = true;
                }

                // If the case is not already locked, lock it.
                if (!lockStatus.response.data.locked_by) {
                    let response = await AjaxService.put(`cases/lock/${id}`, {});
                    if (response.data.status !== 'success') {
                        notification.error({
                            message: 'There was an error',
                            description: response.data.message
                        });
                        return;
                    }
                }
            }

            let exists = false,
                newTabs = [];
            newTabs = openTabs.map((tab: any) => {
                if (tab.id === id) {
                    exists = true;
                    if (forceRefresh || tab.readOnly !== readOnly) {
                        tab.checksum = Date.now();
                        tab.readOnly = readOnly;
                    }
                }
                return tab;
            });
            if (!exists) {
                newTabs.push({
                    id: id,
                    key: key,
                    type: type,
                    checksum: Date.now(),
                    readOnly
                });
            }
            setActiveTab(id.toString());
            setOpenTabs([...newTabs]);
        } else {
            notification.error({
                message: 'Unauthorised Action',
                description: 'You are not permitted to complete this action.'
            });
        }
    }

    const checkTabLock = async (interactive: any, id: any, key: any, type: any, forceRefresh: any = false, showReadOnly: any = true) => {

        // Get the current lock status for this case.
        let response = await AjaxService.get(`cases/lock-status/${id}`);

        if (response && response.data.locked_by && !response.data.locked_by.me) {
            // If the case is locked by someone else, check if the current user
            // can forcibly unlock this case.
            if (canAny(['cases.unlock'])) {
                // Notify the user and ask if they want to forcibly unlock the case.
                let confirmCode = await ConfirmModal({
                    footerBtns: [
                        {btnProps: {type: 'primary'}, code: 'take-over', btnChild: 'Take Over'},
                        {code: 'read-only', btnChild: 'Read Only'},
                        {code: 'cancel', btnChild: 'Cancel'},
                    ].filter((b: any) => {return (showReadOnly || b.code !== 'read-only')}),
                    modalProps: {
                        title: 'Case Locked',
                        content: <div
                            dangerouslySetInnerHTML={{__html: [`This case is currently locked by ${response.data.locked_by.first_name} ${response.data.locked_by.last_name}.`, `Would you like to take over the case?`,].join('<br /><br />')}}/>,
                    },
                });
                if (interactive && confirmCode === 'read-only') {
                    // Open the case now it is locked to this user.
                    await openTab(id, key, type, forceRefresh, true);
                    confirmCode = false;
                }
                if (interactive && confirmCode === 'take-over') {
                    // Lock the case to the current user.
                    let response = await AjaxService.put(`cases/lock/${id}/true`, {});
                    if (response.data.status !== 'success') {
                        notification.error({
                            message: 'There was an error',
                            description: response.data.message
                        });
                        confirmCode = false;
                    } else {
                        // Open the case now it is locked to this user.
                        await openTab(id, key, type, forceRefresh);
                        confirmCode = false;
                    }
                }
                return {
                    result: (confirmCode === 'cancel' ? false : confirmCode),
                    response
                };
            } else {
                let confirmCode = await ConfirmModal({
                    footerBtns: [
                        {btnProps: {type: 'primary'}, code: 'read-only', btnChild: 'Read Only'},
                        {code: 'cancel', btnChild: 'Cancel'},
                    ].filter((b: any) => {return (showReadOnly || b.code !== 'read-only')}),
                    modalProps: {
                        title: 'Case Locked',
                        content: <div
                            dangerouslySetInnerHTML={{__html: [`This case is currently locked by ${response.data.locked_by.first_name} ${response.data.locked_by.last_name}.`].join('<br /><br />')}}/>,
                    },
                });
                if (interactive && confirmCode === 'read-only') {
                    // Open the case now it is locked to this user.
                    await openTab(id, key, type, forceRefresh, true);
                    confirmCode = false;
                }
                return {
                    result: (confirmCode === 'cancel' ? false : confirmCode),
                    response
                };
            }
        }
        return {
            result: true,
            response
        };
    }

    const onChange = (id: any) => {
        setActiveTab(id);
    }

    const closeTab = async (id: any) => {
        let idx = -1;
        id = parseInt(id, 10);
        openTabs.forEach((tab: any, _idx: any) => {
            if (tab.id.toString() === id.toString()) {
                idx = _idx;
                return false;
            }
        });
        if (idx > -1) {
            openTabs.splice(idx, 1);
        }
        try {
            let response = await AjaxService.put(`cases/unlock/${id}`, {});
            if (['success', 'locked'].indexOf(response.data.status) === -1) {
                notification.error({
                    message: 'There was an error',
                    description: response.data.message
                });
            }
        } catch (e) {}
        setActiveTab('default');
        setOpenTabs([...openTabs]);
    }

    const closeForm = () => {
        // confirm({
        //     title: `Are you sure you want to cancel ${formState.action}ing this case?`,
        //     icon: <ExclamationCircleOutlined/>,
        //     content: 'Any unsaved changes will be lost if you continue.',
        //     onOk() {
        //         setFormState({...formState, visible: false});
        //     },
        //     onCancel() {
        //     },
        // });
        setFormState({...formState, visible: false, extra: false});
    }

    const editCase = async (type: any, id: any = false) => {
        let title = 'Edit';
        switch (type) {
            case 'ybm':
            case 'bab':
                title += ' ' + type.toUpperCase();
                break;
            default:
                title += ' ' + type.substring(0, 1).toUpperCase() + type.substring(1).toLowerCase();
                break;
        }

        // Get the current lock status.
        let lockStatus:any = await checkTabLock(true, id, id, type);
        if (lockStatus.status !== false && lockStatus.status !== 'read-only') {
            // If the case is not already locked, lock it.
            if (!lockStatus.response.data.locked_by) {
                let response = await AjaxService.put(`cases/lock/${id}`, {});
                if (response.data.status !== 'success') {
                    notification.error({
                        message: 'There was an error',
                        description: response.data.message
                    });
                    return;
                }
            }
            setFormState({...formState, action: 'edit', type: type, id, visible: true, title});
        }
    }

    const addCase = (type: any, extra: any = {}, overrideTitle: any = false) => {
        let title = 'Add';
        switch (type) {
            case 'ybm':
            case 'bab':
                title += ' ' + type.toUpperCase();
                break;
            default:
                title += ' ' + type.substring(0, 1).toUpperCase() + type.substring(1).toLowerCase();
                break;
        }
        if (overrideTitle !== false) {
            title = overrideTitle;
        }
        setFormState({...formState, action: 'add', type: type, id: false, visible: true, title, extra});
    }

    const onCaseAction = async (id: any, key: any, type: any, action: any) => {
        let hasExtra: any = (formState.extra !== false);
        setFormState({...formState, action: '', type: false, id: false, checksum: Date.now(), visible: false, extra: false, title: ''});
        if (action === 'edit') {
            notification.success({
                message: 'Edit Case',
                description: `The case was successfully modified.`
            });
        } else {
            notification.success({
                message: 'Add Case',
                description: `The case was successfully created.`
            });
        }
        openTab(id, key, type, true);
    }

    const archiveCase = async (id: any) => {
        let confirmCode = await ConfirmModal({
            footerBtns: [
                {btnProps: {type: 'primary'}, code: 'archive', btnChild: 'Yes'},
                {code: 'cancel', btnChild: 'No'},
            ],
            modalProps: {
                title: 'Archive Case',
                content: 'Are you sure you want to archive this case?'
            }
        });
        if (confirmCode !== 'cancel') {
            await AjaxService.put(`cases/${id}/archive`, {});
            setFormState({...formState, checksum: Date.now()});
            closeTab(id);
        }
    }

    useEffect(() => {
        if (filterState !== props.match?.params?.filter) {
            setActiveTab('default');
            setFilterState(props.match?.params?.filter);
        }
    }, [props.match?.params?.filter, filterState, setFilterState]);

    useEffect(() => {
        if (profile && props.match.params.filter === 'view') {
            const [case_id, type, reference] = props.location.pathname.replace('/cases/view/' ,'').split('/');
            openTab(case_id, reference.replace('-', '/'), type);
            setActiveTab(case_id.toString());
            history.push('/cases/my');
        }
    }, [profile]);

    let formRef = React.createRef();

    if (props.match.params && 'filter' in props.match.params) {
        switch (props.match.params.filter) {
            case 'complaint':
                case_filter = {
                    filter: 'complaint',
                    title: 'Complaints'
                };
                break;
            case 'enquiry':
                case_filter = {
                    filter: 'enquiry',
                    title: 'Enquiries'
                };
                break;
            case 'my':
                case_filter = {
                    filter: 'my',
                    title: 'My Cases'
                };
                break;
            case 'new':
                case_filter = {
                    filter: 'new',
                    title: 'New Cases'
                };
                break;
            case 'watched':
                case_filter = {
                    filter: 'watched',
                    title: 'Watched Cases'
                };
                break;
        }
    }

    return (
        <Master className={`Cases-${case_filter.filter}`}>
            <Tabs
                hideAdd
                onChange={onChange}
                onEdit={closeTab}
                activeKey={activeTab}
                className="CaseTabs"
                type="editable-card"
            >
                <TabPane tab={case_filter.title} key="default" closable={false}>
                    <CaseTable filter={case_filter} checksum={formState.checksum} openTab={openTab} addCase={addCase}/>
                </TabPane>
                {openTabs.map((tab: any) => {
                    return (
                        <TabPane tab={tab.key} key={tab.id} closable={true}>
                            <CaseWrapper key={tab.id} id={tab.id} readOnly={tab.readOnly} checksum={tab.checksum} type={tab.type}
                                         openTab={openTab}
                                         addCase={addCase}
                                         activeTab={activeTab == tab.id}
                                         editCase={editCase}
                                         caseAction={onCaseAction}
                                         closeTab={closeTab}
                                         checkTabLock={checkTabLock}
                                         archiveCase={archiveCase}
                            />
                        </TabPane>
                    );
                })}
            </Tabs>
            {canAny([
                'cases.enquiry.add',
                'cases.complaint.add',
                'cases.bab.add',
                'cases.ybm.add',
                'cases.feedback.add'
            ]) && (
                <Drawer
                    placement="right"
                    destroyOnClose
                    forceRender
                    visible={formState.visible}
                    onClose={closeForm}
                    title={formState.title}
                    footer={
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Button loading={formState.loading} onClick={() => closeForm()}>
                                Discard
                            </Button>
                            <Button loading={formState.loading} type="primary"
                                    onClick={() => (formRef as any).submitForm()}
                                    style={{marginLeft: 'auto'}}>
                                Save
                            </Button>
                        </div>
                    }
                >
                    {formState.type === 'enquiry' &&
                    <EnquiryForm ref={(ref: any) => formRef = ref} id={formState.id} action={formState.action}
                                 formState={formState} onAdd={onCaseAction} setFormState={setFormState}
                                 openTab={openTab} checkTabLock={checkTabLock}/>}
                    {formState.type === 'complaint' &&
                    <ComplaintForm ref={(ref: any) => formRef = ref} id={formState.id} action={formState.action}
                                   formState={formState} onAdd={onCaseAction} setFormState={setFormState}
                                   openTab={openTab} checkTabLock={checkTabLock}/>}
                    {formState.type === 'bab' &&
                    <BABForm ref={(ref: any) => formRef = ref} id={formState.id} action={formState.action}
                             formState={formState} onAdd={onCaseAction} setFormState={setFormState} openTab={openTab} checkTabLock={checkTabLock}/>}
                    {formState.type === 'ybm' &&
                    <YBMForm ref={(ref: any) => formRef = ref} id={formState.id} action={formState.action}
                             formState={formState} onAdd={onCaseAction} setFormState={setFormState} openTab={openTab} checkTabLock={checkTabLock}/>}
                    {formState.type === 'feedback' &&
                    <FeedbackForm ref={(ref: any) => formRef = ref} id={formState.id} action={formState.action}
                                  formState={formState} onAdd={onCaseAction} setFormState={setFormState}
                                  openTab={openTab} checkTabLock={checkTabLock}/>}
                </Drawer>
            )}
        </Master>
    );
}

export default AllCases;
