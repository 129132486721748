import React, {useEffect, useState, useContext} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import useLocalStorage from '../../../Helpers/State/useLocalStorage';
import {ReactComponent as Logo} from '../../../Assets/logo-white.svg';
import {ProfileContext, useCan} from '../../../Contexts/ProfileContext';
import moment from 'moment';
import {Badge, Drawer, Layout, Menu, notification, Popconfirm, Popover, Spin, Tooltip} from 'antd';
import {
    BellOutlined,
    ContactsOutlined,
    DashboardOutlined,
    DeleteOutlined,
    LineChartOutlined,
    LogoutOutlined,
    MailOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    SnippetsOutlined,
    UserOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import './index.less';
import AjaxService from "../../../Services/AjaxService";

const {SubMenu} = Menu;
const {Header, Sider, Content} = Layout;

function Master(props: any) {

    const [siderCollapsed, setSiderCollapsed] = useLocalStorage('navigation-state', window.innerWidth < 768);
    const [siderWidth, setSiderWidth] = useState(0);
    const [notificationState, setNotificationState] = useState({
        visible: false,
        loading: false,
        notifications: [] as any
    });
    const [unreadNotifications, setUnreadNotifications] = useState({
        visible: false,
        notifications: [] as any
    });

    const {profile} =  useContext(ProfileContext);
    const {canAny, canAll} = useCan(profile);

    const history = useHistory();
    const location = useLocation();

    const logout = () => {
        localStorage.removeItem('auth_token');
        history.push('/');
    }

    const fetchUnreadNotifications = async () => {
        try {
            const response = await AjaxService.get('notifications', {filter: 'unread'});
            setUnreadNotifications({...unreadNotifications, notifications: response.data});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchNotifications = async (visible: any) => {
        try {
            const response = await AjaxService.get('notifications');
            setNotificationState({
                ...notificationState,
                notifications: response.data,
                loading: false,
                visible: (visible ? response.data.length > 0 : false)
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const showNotifications = () => {
        setUnreadNotifications({...unreadNotifications, visible: false});
        setNotificationState({...notificationState, loading: true});
        fetchNotifications(true);
    }

    const openNotification = async (item: any, visible: any) => {
        try {
            await AjaxService.put('notifications/read', {
                ids: [item.id]
            });
            fetchUnreadNotifications();
            fetchNotifications(visible);
            // history.push('/cases/view/' + item.case.id + '/' + item.case.type + '/' + item.case.key.replace('/', '-'));
            window.location.href = '/cases/view/' + item.case.id + '/' + item.case.type + '/' + item.case.key.replace('/', '-');
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const removeNotification = async (id: any) => {
        try {
            await AjaxService.delete(`notifications/${id}`);
            await fetchUnreadNotifications();
            await fetchNotifications(notificationState.visible);
        } catch (e: any) {
            notification.error({
                message: 'Delete Notification',
                description: 'There was an issue deleting the notification. Please try again.'
            });
        }
    }

    useEffect(() => {
        fetchUnreadNotifications();
        const interval = setInterval(() => {
            fetchUnreadNotifications();
        }, 30000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const notificationContent = (
        <>
            {unreadNotifications.notifications.length > 0 && (
                <ul className="NotificationList">
                    {unreadNotifications.notifications.map((item: any) => {
                        return <li key={item.id} className={`Notification ${(item.read_at ? '' : 'Unread')}`}>
                            <div className="NotificationTitle">
                                <span onClick={() => openNotification(item, false)}>{item.title}</span>
                                <Popconfirm overlayClassName="NotificationPopover" placement="topRight"
                                            arrowPointAtCenter title="Remove this notification?"
                                            onConfirm={() => removeNotification(item.id)} okText="Yes" cancelText="No">
                                    <DeleteOutlined className="Remove"/>
                                </Popconfirm>
                            </div>
                            {item.message}
                            <small>{moment(item.created_at).fromNow()}</small>
                        </li>
                    })}
                </ul>
            )}
            {unreadNotifications.notifications.length <= 0 && (
                <>You have no unread notifications.</>
            )}
        </>
    );

    const buildMenu = () => {

        const canI = (result: any, menuItem: any) => {
            // Must have all of the rules listed in all.
            if (menuItem.rules.all.length && !canAll(menuItem.rules.all)) {
                return result;
            }
            // Must have at least one of the rules listed in any.
            if (menuItem.rules.any.length && !canAny(menuItem.rules.any)) {
                return result;
            }
            if ('subMenuItems' in menuItem && menuItem.subMenuItems.length) {
                menuItem.subMenuItems = menuItem.subMenuItems.reduce(canI, []);
                // If we've removed all sub-menu items, don't bother adding the parent.
                if (menuItem.subMenuItems.length === 0) {
                    return result;
                }
            }
            result.push(menuItem);
            return result;
        }

        return [{
            title: 'Dashboard',
            path: '/',
            icon: <DashboardOutlined/>,
            subMenuItems: [],
            rules: { any: [], all: []}
        }, {
            title: 'Cases',
            path: '/cases',
            icon: <SnippetsOutlined/>,
            subMenuItems: [{
                title: 'My Cases',
                path: '/cases/my',
                rules: { any: [], all: []}
            }, {
                title: 'Watched Cases',
                path: '/cases/watched',
                rules: { any: [], all: ['cases.watch']}
            }, {
                title: 'New Cases',
                path: '/cases/new',
                rules: { any: [], all: []}
            }, {
                title: 'All Cases',
                path: '/cases',
                rules: { any: [], all: []}
            }, {
                title: 'Complaints',
                path: '/cases/complaint',
                rules: { any: [], all: []}
            }, {
                title: 'Enquiries',
                path: '/cases/enquiry',
                rules: { any: [], all: []}
            }],
            rules: { any: [
                'cases.enquiry.view_any',
                'cases.enquiry.view_own',
                'cases.complaint.view_any',
                'cases.complaint.view_own',
                'cases.bab.view_any',
                'cases.bab.view_own',
                'cases.ybm.view_any',
                'cases.ybm.view_own',
                'cases.feedback.view_any',
                'cases.feedback.view_own'
            ], all: []}
        }, /*{
            title: 'Address Book',
            path: '/address-book',
            icon: <ContactsOutlined/>,
            subMenuItems: [],
            rules: { any: [], all: []}
        },*/ {
            title: 'Contacts',
            path: '/contacts',
            icon: <ContactsOutlined/>,
            subMenuItems: [{
                title: 'Passengers',
                path: '/contacts/passenger',
                rules: { any: [], all: [
                    'contacts.passenger.view'
                ]}
            }, {
                title: 'Operators',
                path: '/contacts/operator',
                rules: { any: [], all: [
                    'contacts.operator.view'
                ]}
            }],
            rules: { any: [], all: []}
        }, {
            title: 'Mail',
            path: '/mail',
            icon: <MailOutlined/>,
            subMenuItems: [{
                title: 'Inbox',
                path: '/mail/inbox',
                rules: { any: [], all: [
                    'mail.inbox'
                ]}
            }, {
                title: 'Sent Items',
                path: '/mail/sent',
                rules: { any: [], all: [
                    'mail.sent'
                ]}
            }, {
                title: 'Trash',
                path: '/mail/trash',
                rules: { any: [], all: [
                    'mail.trash'
                ]}
            }],
            rules: { any: [], all: []}
        }, {
            title: 'Reporting',
            path: '/reporting',
            icon: <LineChartOutlined/>,
            subMenuItems: [{
                title: 'Reporting',
                path: '/reporting',
                rules: { any: [], all: []}
            }],
            rules: { any: [], all: []}
        }, {
            title: 'Users',
            path: '/users',
            icon: <UserOutlined/>,
            subMenuItems: [{
                title: 'Users',
                path: '/users',
                rules: { any: [
                    'users.add',
                    'users.edit',
                    'users.delete',
                ], all: []}
            }, {
                title: 'Roles',
                path: '/users/roles',
                rules: { any: [
                    'roles.add',
                    'roles.edit',
                    'roles.delete',
                ], all: []}
            }, {
                title: 'Audit Trail',
                path: '/users/audit-trail',
                rules: { any: [], all: [
                    'audit_trail'
                ]}
            }],
            rules: { any: [], all: []}
        }, {
            title: 'Settings',
            path: '/settings',
            icon: <SettingOutlined/>,
            subMenuItems: [{
                title: 'Case Statuses',
                path: '/settings/case-statuses',
                rules: { any: [
                    'settings.case_statuses.add',
                    'settings.case_statuses.edit',
                    'settings.case_statuses.delete',
                ], all: []}
            }, {
                title: 'Locations',
                path: '/settings/locations',
                rules: { any: [
                    'settings.locations.add',
                    'settings.locations.edit',
                    'settings.locations.delete',
                ], all: []}
            }, {
                title: 'Regions',
                path: '/settings/regions',
                rules: { any: [
                    'settings.regions.add',
                    'settings.regions.edit',
                    'settings.regions.delete',
                ], all: []}
            }, {
                title: 'Operators',
                path: '/settings/operators',
                rules: { any: [
                    'settings.operators.add',
                    'settings.operators.edit',
                    'settings.operators.delete',
                ], all: []}
            }, {
                title: 'Case Types',
                path: '/settings/case-types',
                rules: { any: [
                    'settings.case_types.add',
                    'settings.case_types.edit',
                    'settings.case_types.delete',
                ], all: []}
            }],
            rules: { any: [], all: []}
        }].reduce(canI, [])
    }

    const menuItems: any = buildMenu();
    const [menuState, setMenuState] = useState({
        selectedKeys: ['/'],
        openKeys: []
    });

    useEffect(() => {
        if (menuState.selectedKeys[0] !== location.pathname) {
            let pathParts: any = location.pathname.substring(1).split('/'),
                tmpPath: any = '',
                openKeys: any = [],
                selectedKey: any = location.pathname;

            if (pathParts.length > 1) {
                pathParts.pop();
            }
            pathParts.forEach((part: any) => {
                tmpPath += '/' + part;
                openKeys.push(tmpPath);
            });
            setMenuState({selectedKeys: [selectedKey], openKeys});
        }
    }, [location, menuState, menuItems]);

    const metadata: any = require('../../../metadata.json');
    const env: any = (process.env.REACT_APP_ENV || 'development');
    const niceEnv: any = env.toUpperCase().substring(0, 1) + env.toLowerCase().substring(1);

    if (!(env in metadata)) {
        metadata[env] = {
            build: 1,
            build_date: moment().format('DD/MM/YYYY HH:mm')
        }
    }

    return (
        <Spin spinning={!profile}>
            <Layout className={`${props.className} Master`} onClick={(e: any) => {
                if (unreadNotifications.visible) {
                    let found = false, parent = e.target;
                    while (parent && !found) {
                        console.log([parent, found]);
                        found = (parent && parent.classList && parent.classList.contains('NotificationPopover'));
                        parent = parent.parentNode;
                    }
                    if (!found) {
                        setUnreadNotifications({...unreadNotifications, visible: false});
                    }
                }
            }}>
                <Drawer
                    title="Notifications"
                    visible={notificationState.visible}
                    destroyOnClose
                    forceRender
                    className="NotificationDrawer"
                    onClose={() => setNotificationState({...notificationState, visible: false})}
                >
                    <ul className="NotificationList">
                        {notificationState.notifications.map((item: any) => {
                            return <li key={item.id} className={`Notification ${(item.read_at ? '' : 'Unread')}`}>
                                <div className="NotificationTitle">
                                    <span onClick={() => openNotification(item, true)}>{item.title}</span>
                                    <Popconfirm overlayClassName="NotificationPopover" placement="topRight"
                                                arrowPointAtCenter title="Remove this notification?"
                                                onConfirm={() => removeNotification(item.id)} okText="Yes" cancelText="No">
                                        <DeleteOutlined className="Remove"/>
                                    </Popconfirm>
                                </div>
                                {item.message}
                                <small>{moment(item.created_at).fromNow()}</small>
                            </li>
                        })}
                    </ul>
                </Drawer>
                <Sider
                    breakpoint="sm"
                    collapsedWidth={siderWidth}
                    trigger={null}
                    onBreakpoint={broken => {
                        setSiderWidth((broken ? 0 : 80));
                    }}
                    defaultCollapsed={true}
                    collapsible
                    collapsed={siderCollapsed}
                >
                    <Link to="/" className="logo">
                        <Logo/>
                    </Link>
                    <div className="MenuWrapper">
                        <Menu theme="dark" onOpenChange={(openKeys: any) => {setMenuState({...menuState, openKeys})}} mode="inline" openKeys={menuState.openKeys} selectedKeys={menuState.selectedKeys}>
                            {menuItems.map((item: any) => (
                                item.subMenuItems.length ? <SubMenu key={item.path} icon={item.icon} title={item.title}>
                                    {item.subMenuItems.map((subItem: any) => (
                                        <Menu.Item key={subItem.path} icon={subItem.icon}>
                                            <Link to={subItem.path}>{subItem.title}</Link>
                                        </Menu.Item>
                                    ))}
                                </SubMenu> : <Menu.Item key={item.path} icon={item.icon}>
                                    <Link to={item.path}>{item.title}</Link>
                                </Menu.Item>
                            ))}
                        </Menu>
                        <small className="AppVersion">
                            <Tooltip placement="right" title={`${niceEnv} Build #${metadata[env].build} - ${metadata[env].build_date}`}>
                                <InfoCircleOutlined />
                                {!siderCollapsed && (
                                    <span style={{display: 'inline-block', marginLeft: '.25rem'}}>{niceEnv} Build: #{metadata[env].build}</span>
                                )}
                            </Tooltip>
                        </small>
                    </div>
                </Sider>
                <Layout className="site-layout">
                    <Header>
                        {React.createElement(siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: () => setSiderCollapsed(!siderCollapsed),
                        })}
                        <div>
                            <Popover
                                placement="bottomRight"
                                arrowPointAtCenter
                                content={notificationContent}
                                visible={unreadNotifications.visible}
                                trigger="click"
                                overlayClassName="NotificationPopover"
                                title={(
                                    <div className="NotificationHeader">
                                        Notifications
                                        <span className="clickable" onClick={() => showNotifications()}>View all</span>
                                    </div>
                                )}>
                                <Badge count={unreadNotifications.notifications.length} size="small">
                                    <BellOutlined
                                        onClick={() => setUnreadNotifications({...unreadNotifications, visible: true})}/>
                                </Badge>
                            </Popover>
                            <Popconfirm placement="topRight" arrowPointAtCenter
                                        title="Are you sure you would like to logout?" onConfirm={logout} okText="Yes"
                                        cancelText="No">
                                <LogoutOutlined/>
                            </Popconfirm>
                        </div>
                    </Header>
                    <Content className="site-layout-background">
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </Spin>
    );
}

export default Master;