import React, {useContext, useEffect, useState} from 'react';
import Master from '../../../../Components/Layouts/Master';
import OperatorModal from '../../../../Components/OperatorModal';
import './index.less';
import {
    Button,
    Col,
    Drawer,
    Dropdown,
    Form,
    Input,
    Menu,
    Modal,
    notification,
    Row,
    Select,
    Spin,
    Table,
    Tooltip
} from "antd";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    FilterOutlined,
    FilterTwoTone,
    QuestionCircleOutlined
} from "@ant-design/icons";
import moment from "moment";
import AjaxService from "../../../../Services/AjaxService";
import {ProfileContext, useCan} from "../../../../Contexts/ProfileContext";

function List(props: any) {

    let isCancelled = false;

    const [filterForm] = Form.useForm();

    const {profile} = useContext(ProfileContext);
    const {can, canAny} = useCan(profile);

    const filterFormLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const [tableState, setTableState] = useState({
        loading: true,
        filterOpen: false,
        selectedRows: [],
        height: 100,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sorter: {
            field: 'created_at',
            order: 'descend'
        },
        filterActive: false,
        filters: {
            query: ''
        },
        loadingContacts: false,
        contacts: [] as any,
        data: [] as any
    });

    const [viewState, setViewState] = useState({
        open: false,
        current: false as any,
        loading: false
    });

    const [formState, setFormState] = useState({
        visible: false,
        loading: false,
        current: false as any
    });

    const deleteSingle = (operator: any) => {
        if (operator.case_count) {
            return notification.error({
                message: 'Delete Operator',
                description: 'This operator cannot be deleted as it assigned to one or more cases.'
            });
        }
        Modal.confirm({
            title: `Delete Operator`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to delete this operator?',
            onOk() {
                AjaxService.delete(`operators/${operator.id}`).then((result) => {
                    if (result !== false) {
                        notification.success({
                            message: 'Delete Operator',
                            description: 'The Operator has been successfully deleted.'
                        });
                    }
                    setViewState({...viewState, current: false, open: false});
                    fetchData({...tableState, selectedRows: [], loading: true});
                });
            },
            onCancel() {
            },
        });
    }

    const searchContacts = async (search: any) => {
        setTableState((previous: any) => {
            return {
                ...previous,
                loadingContacts: true,
                contacts: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`contacts`, {filter_query: search});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        setTableState((previous: any) => {
            return {
                ...previous,
                loadingContacts: false,
                contacts: (response ? response.data : previous.contacts)
            };
        });
    }

    const fetchData = async (params: any = tableState) => {
        setTableState((previous: any) => {
            return {
                ...previous,
                loading: true
            }
        });

        let request: any = {};

        if ('pagination' in params && Object.keys(params.pagination).length > 0) {
            request.page = params.pagination.current;
            request.limit = params.pagination.pageSize;
        }

        if ('sorter' in params && 'field' in params.sorter) {
            request.sort = (typeof params.sorter.field === 'string' ? params.sorter.field : params.sorter.field.join('.'));
            if (params.sorter.order) {
                request.direction = (params.sorter.order === 'descend' ? 'desc' : 'asc');
            }
        }

        if ('filters' in params && Object.keys(params.filters).length > 0) {
            Object.keys(params.filters).forEach((key: any) => {
                if (params.filters[key]) {
                    request['filter_' + key] = params.filters[key];
                }
            });
        }

        let response: any;
        try {
            response = await AjaxService.get(`operators/`, request);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setTableState((previous: any) => {
            if (!('filters' in params)) {
                params.filters = previous.filters;
            }
            if (!('sorter' in params)) {
                params.sorter = previous.sorter;
            }
            if (!('pagination' in params)) {
                params.pagination = previous.pagination;
            }
            if (!('filterActive' in params)) {
                params.filterActive = previous.filterActive;
            }
            if (!response) {
                return {...previous, loading: false}
            }
            return {
                ...previous,
                ...params,
                loading: false,
                filterOpen: false,
                data: response.data,
                pagination: {
                    ...params.pagination,
                    total: response.meta.total
                }
            }
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            ...tableState,
            pagination,
            sorter
        });
    }

    const editOperator = (operator: any) => {
        setFormState({...formState, visible: true, current: operator});
    }

    const columns = [{
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
        render: (text: any, row: any) => {
            return <span className="clickable" onClick={() => editOperator(row)}>{row.title}</span>;
        },
        sorter: true
    }, {
        title: 'Contact',
        dataIndex: 'contact',
        sorter: true,
        render: (text: any, row: any) => {
            return `${row.contact.first_name} ${row.contact.last_name}`;
        }
    }, {
        title: 'Created',
        dataIndex: 'created_at',
        sorter: true,
        render: (text: any, record: any, index: any) => moment(text).format('DD/MM/YYYY HH:mm')
    }, (canAny(['settings.operators.edit', 'settings.operators.delete']) ? {
        title: '',
        key: 'actions',
        align: 'right' as 'right',
        render: (text: any, row: any) => <Dropdown overlay={operatorMenu(row)} placement="bottomRight">
            <Button icon={<DownOutlined/>}/>
        </Dropdown>
    } : null)];

    const operatorMenu = (row: any, viewing: any = false) => (
        <Menu>
            <Menu.Item key="edit" onClick={() => editOperator(row)} icon={<EditOutlined/>}>Edit</Menu.Item>
            {row.id !== 0 && (
                <Menu.Item key="delete" onClick={() => deleteSingle(row)} icon={<DeleteOutlined/>}>Delete</Menu.Item>
            )}
        </Menu>
    );

    const onFilter = (values: any) => {
        fetchData({
            ...tableState,
            filters: {...values},
            pagination: {
                ...tableState.pagination,
                current: 1
            },
            filterActive: true
        });
    }

    const onClearFilter = () => {
        filterForm.resetFields();
        fetchData({
            ...tableState,
            filterActive: false,
            filters: {
                query: ''
            }
        });
    }

    const addOperator = () => {
        setFormState({...formState, visible: true, current: false});
    }

    const operatorChanged = (add: any, operator: any) => {
        fetchData();
        if (operator) {
            if (add) {
                notification.success({
                    message: 'Add Operator',
                    description: `The operator was successfully created.`
                });
            } else {
                notification.success({
                    message: 'Edit Operator',
                    description: `The operator was successfully modified.`
                });
            }
        }
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        fetchData();
        window.addEventListener('resize', resizeTable);
        return () => {
            isCancelled = true;
            window.removeEventListener('resize', resizeTable);
        };
    }, [props]);

    const resizeTable = () => {
        let wrappers = document.getElementsByClassName('ant-table-wrapper');
        Array.from(wrappers).forEach((wrapper: any) => {
            let newHeight = wrapper.parentNode.clientHeight - 180;
            if (tableState.height !== newHeight) {
                setTableState((previous: any) => {
                    return {...previous, height: newHeight}
                });
            }
        });
    }

    useEffect(() => {
        resizeTable();
    });

    return (
        <Master className={`RegionList`}>
            <Drawer
                title="Filter Operators"
                visible={tableState.filterOpen}
                closable={true}
                destroyOnClose
                forceRender
                onClose={() => setTableState({...tableState, filterOpen: false})}
                footer={
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Button onClick={() => onClearFilter()} type="text" danger={true}>
                            Clear Filter
                        </Button>
                        <Button onClick={() => setTableState({...tableState, filterOpen: false})}
                                style={{marginRight: 8, marginLeft: 'auto'}}>
                            Close
                        </Button>
                        <Button onClick={() => filterForm.submit()} type="primary">
                            Apply Filters
                        </Button>
                    </div>
                }
            >
                <Form
                    {...filterFormLayout}
                    form={filterForm}
                    onFinish={onFilter}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Search"
                                name="query"
                            >
                                <Input placeholder="Search" value={tableState.filters.query}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="primary_contact_id"
                                label="Contact"
                            >
                                <Select
                                    placeholder="Search for a contact"
                                    showSearch
                                    onSearch={searchContacts}
                                    filterOption={false}
                                    notFoundContent={tableState.loadingContacts ? <Spin size="small"/> : null}
                                    loading={tableState.loadingContacts}
                                >
                                    {tableState.contacts.map((contact: any) => {
                                        return (
                                            <Select.Option key={contact.id.toString()}
                                                           value={contact.id.toString()}>
                                                {contact.first_name} {contact.last_name}
                                                <small style={{display: 'block'}}>{contact.email}</small>
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <div className="CaseTableActions">
                <span className="CaseTableTitle">Operators</span>
                {can('settings.operators.add') && (
                    <Button style={{marginLeft: '.5rem'}} onClick={() => addOperator()}>Add Operator</Button>
                )}
                <Tooltip title="Filter Operators" placement="left">
                    <Button
                        icon={(tableState.filterActive ? <FilterTwoTone twoToneColor="#52c41a"/> : <FilterOutlined/>)}
                        onClick={() => setTableState({...tableState, filterOpen: true})}
                        style={{marginLeft: '.5rem'}}
                    />
                </Tooltip>
            </div>
            <Table
                className="OperatorTable"
                columns={columns.filter(c => c != null) as any}
                dataSource={tableState.data}
                loading={tableState.loading}
                rowKey="id"
                onChange={handleTableChange}
                pagination={tableState.pagination}
                scroll={{y:tableState.height}}
            />
            {can('settings.operators.add') && (
                <Button className={tableState.data.length ? 'PaginationButton' : 'mt-15'} onClick={() => addOperator()}>Add
                    Operator</Button>
            )}
            {can('settings.operators.add') && formState.visible && (
                <OperatorModal onAdd={(operator: any) => operatorChanged(true, operator)}
                               onEdit={(operator: any) => operatorChanged(false, operator)} current={formState.current}
                               onClose={() => setFormState({...formState, visible: false})}/>
            )}
        </Master>
    );
}

export default List;