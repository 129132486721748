import {notification} from "antd";

export function getAPIUrl() {
    let url = 'http://bus-users.test/api/';
    if (process.env.REACT_APP_API_URL) {
        url = process.env.REACT_APP_API_URL;
    }
    return url;
}

const buildQuery = (base_url: any, params: any = {}) => {
    let output = base_url;
    Object.keys(params).forEach((key: any) => {
        if (Array.isArray(params[key])) {
            params[key].forEach((value: any) => {
                output += (output.indexOf('?') === -1 ? '?' : '&') + key + '[]=' + value;
            });
        } else {
            output += (output.indexOf('?') === -1 ? '?' : '&') + key + '=' + params[key];
        }
    });
    return output;
}

export function addAuthenticationHeaders(options: any) {
    // Ensure we have a headers object to use.
    if (!('headers' in options)) {
        options.headers = {};
    }

    // Add the bearer token if it exists.
    const token = window.localStorage.getItem('auth_token');
    if (token) {
        options.headers.Authorization = `Bearer ${token}`;
    } else {
        // do we include the client details instead?
    }

    // Ensure we're telling the server what we want in return.
    options.headers.accept = 'application/json';

    return options;
}

const get = (url: any, params: any = null, paginate: any = false, data: any = []): any => {
    const requestOptions = addAuthenticationHeaders({
        method: 'GET'
    });
    if (url.indexOf('http') === -1) {
        url = getAPIUrl() + url;
    }
    if (params) {
        url = buildQuery(url, params);
    }
    return fetch(url, requestOptions).then(handleResponse).catch(handleError).then((response: any) => {
        if (!paginate) {
            return response;
        }
        data = (Array.isArray(response.data) ? [...data, ...response.data] : {...data, ...response.data});
        if (response.links && response.links.next) {
            return get(response.links.next, params, paginate, data);
        }
        return {
            data: data
        };
    });
}

const post = (url: any, body: any) => {
    const requestOptions = addAuthenticationHeaders({
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    });
    if (url.indexOf('http') === -1) {
        url = getAPIUrl() + url;
    }
    return fetch(url, requestOptions).then(handleResponse).catch(handleError);
}

const put = (url: any, body: any) => {
    const requestOptions = addAuthenticationHeaders({
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
    });
    if (url.indexOf('http') === -1) {
        url = getAPIUrl() + url;
    }
    return fetch(url, requestOptions).then(handleResponse).catch(handleError);
}

const _delete = (url: any, params: any = null) => {
    const requestOptions = addAuthenticationHeaders({
        method: 'DELETE'
    });
    if (url.indexOf('http') === -1) {
        url = getAPIUrl() + url;
    }
    if (params) {
        url = buildQuery(url, params);
    }
    return fetch(url, requestOptions).then(handleResponse).catch(handleError);
}

const handleError = (response: any) => {
    if (response.status === 403) {
        notification.error({
            message: 'Unauthorised Action',
            description: 'You are not permitted to complete this action.'
        });
        return false;
    }

    if (response.status === 401) {
        window.localStorage.removeItem('auth_token');
        window.location.href = '/login';
    }

    //return { data: { status: 'error' }, errors: ('errors' in response.data ? response.data.errors : [])};
    throw response;
}

const handleResponse = (response: any) => {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject({message: error, data, response});
        }

        return data;
    }).catch((error: any) => {

        if (response.status === 403) {
            notification.error({
                message: 'Unauthorised Action',
                description: 'You are not permitted to complete this action.'
            });
            return false;
        }

        if (response.status === 401) {
            window.localStorage.removeItem('auth_token');
            window.location.href = '/login';
        }

        return Promise.reject(error);
    });
}

const AjaxService = {
    get,
    post,
    put,
    delete: _delete
};

export default AjaxService;