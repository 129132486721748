import React, {useEffect, useState} from 'react';
import {Alert, Col, Form, Input, Modal, notification, Row} from 'antd';
import './index.less';
import AjaxService from "../../Services/AjaxService";

function CaseTypeModal(props: any) {

    const [form] = Form.useForm();

    const [modalState, setModalState] = useState({
        loading: false,
        error: '',
        search: ''
    });

    const addType = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            let response = await AjaxService.post('case-types', values);
            props.onAdd(response.data);
            closeModal();
        } catch (e: any) {
            if (!('title' in e.data.errors)) {
                notification.error({
                    message: 'Add Case Type',
                    description: 'There was an issue adding the case type. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    error: ('title' in e.data.errors ? e.data.errors.title[0] : ''),
                    loading: false
                };
            });
        }
    }

    const editType = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            let response = await AjaxService.put('case-types/' + props.current.id, values);
            props.onAdd(response.data);
            closeModal();
        } catch (e: any) {
            if (!('title' in e.data.errors)) {
                notification.error({
                    message: 'Edit Case Type',
                    description: 'There was an issue editing the case type. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    error: ('title' in e.data.errors ? e.data.errors.title[0] : ''),
                    loading: false
                };
            });
        }
    }

    const closeModal = async () => {
        setModalState((previous: any) => {
            return {
                ...previous,
                loading: false
            };
        });
        form.resetFields();
        props.onClose();
    }

    useEffect(() => {
        if (props.current) {
            form.setFieldsValue(props.current);
        }
    }, [props, form])

    return (
        <>
            <Modal
                visible
                title={props.current ? 'Edit Type' : 'Add Type'}
                onOk={() => form.submit()}
                onCancel={closeModal}
                destroyOnClose
                forceRender
                confirmLoading={modalState.loading}
            >
                <Form
                    form={form}
                    onFinish={(values: any) => {
                        (props.current ? editType(values) : addType(values));
                    }}
                    layout="vertical"
                >
                    {modalState.error !== '' && (
                        <Alert style={{marginBottom: '1rem'}} message={modalState.error} type="error" showIcon/>
                    )}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Code"
                                name="code"
                                rules={[{required: true, message: 'Code is required!'}]}
                            >
                                <Input placeholder="Code"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Type"
                                name="title"
                                rules={[{required: true, message: 'Type is required!'}]}
                            >
                                <Input placeholder="Type"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default CaseTypeModal;