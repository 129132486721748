import React from 'react';
import { Modal, Button } from 'antd'
import './index.less';

export default function ConfirmModal(props: any) {
    const { footerBtns, modalProps } = props;

    return new Promise((resolve: any) => {
        const content = (
            <>
                {modalProps.content}
                <div className="confirm_btns">
                    {footerBtns.map((b: any) => (
                        <Button
                            {...b.btnProps}
                            key={b.code}
                            onClick={() => {
                                modalRef.destroy();
                                resolve(b.code);
                            }}
                        >
                            {b.btnChild}
                        </Button>
                    ))}
                </div>
            </>
        );

        const modalRef = Modal.confirm({
            ...modalProps,
            className: 'async_confirm',
            content,
        });
    });
}