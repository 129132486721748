import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment'
import {Button, Cascader, Col, Drawer, Dropdown, Form, Input, Menu, notification, Row, Select, Spin, Table, Tooltip} from 'antd';
import {DownOutlined, FilterOutlined, FilterTwoTone, SyncOutlined} from "@ant-design/icons";
import BABRow from '../../Pages/Cases/BAB/Row';
import ComplaintRow from '../../Pages/Cases/Complaint/Row';
import EnquiryRow from '../../Pages/Cases/Enquiry/Row';
import YBMRow from '../../Pages/Cases/YBM/Row';
import FeedbackRow from '../../Pages/Cases/Feedback/Row';
import AjaxService from '../../Services/AjaxService';
import {ProfileContext, useCan} from '../../Contexts/ProfileContext';
import './index.less';

function CaseTable(props: any) {

    let isCancelled = false;

    const {profile} = useContext(ProfileContext);
    const {can, canAny} = useCan(profile);

    const [form] = Form.useForm();

    const {Option} = Select;

    const filterFormLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const [filterState, setFilterState] = useState({
        statuses: [],
        users: [],
        locations: [],
        operators: [],
        contacts: [],
        countries: [],
        loadingContacts: false,
        loadingLocations: true
    });

    const [tableState, setTableState] = useState({
        loading: true,
        filterOpen: false,
        height: 100,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sorter: {
            field: 'created_at',
            order: 'descend'
        },
        filterActive: false,
        filters: {
            query: '',
            contact: '',
            id: '',
            location: [],
            country: '',
            operator: '',
            assigned_to: '',
            type: '',
            status: ''
        },
        data: []
    });

    const fetchCaseStatuses = async () => {
        try {
            let response = await AjaxService.get(`case-statuses/`);
            setFilterState((previous: any) => {
                return {
                    ...previous,
                    statuses: response.data.map((status: any) => {
                        return {
                            label: status.title,
                            value: status.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    // const fetchOperators = async () => {
    //     try {
    //         let response = await AjaxService.get(`operators/`);
    //         setFilterState((previous: any) => {
    //             return {
    //                 ...previous,
    //                 operators: response.data.map((status: any) => {
    //                     return {
    //                         label: status.title,
    //                         value: status.id
    //                     }
    //                 })
    //             }
    //         });
    //     } catch (e: any) {
    //         notification.error({
    //             message: 'There was an error',
    //             description: 'Unfortunately we could not load the required information. Please try again.'
    //         });
    //     }
    // }

    const fetchOperators = async () => {
        setFilterState((previous: any) => {
            return {
                ...previous,
                loadingOperators: true,
                operators: []
            }
        });

        let operators: any = [],
            response: any;
        try {
            let page = 1;
            while (true) {
                response = await AjaxService.get(`operators/`, {
                    'page': page
                });
                if (response) {
                    response.data.forEach((item: any) => {
                        operators.push({
                            label: item.title,
                            value: item.id
                        })
                    });
                    if (page < response.meta.last_page) {
                        page++;
                    } else {
                        break;
                    }
                }
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFilterState((previous: any) => {
            return {
                ...previous,
                loadingOperators: false,
                operators
            };
        });
    }

    const fetchUsers = async () => {
        setFilterState((previous: any) => {
            return {
                ...previous,
                loadingUsers: true,
                users: []
            }
        });

        let users: any = [],
            response: any;
        try {
            let page = 1;
            while (true) {
                response = await AjaxService.get(`users/`, {
                    'page': page
                });
                if (response) {
                    response.data.forEach((user: any) => {
                        users.push({
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id
                        });
                    })
                    if (page < response.meta.last_page) {
                        page++;
                    } else {
                        break;
                    }
                }
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFilterState((previous: any) => {
            return {
                ...previous,
                loadingUsers: false,
                users
            };
        });
    }

    const fetchLocationTree = async () => {
        setFilterState((previous: any) => {
            return {
                ...previous,
                loadingLocations: true,
                locations: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`locations/tree`, {});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFilterState((previous: any) => {
            return {
                ...previous,
                loadingLocations: false,
                locations: (response ? response : previous.locations)
            };
        });
    }

    // const fetchLocations = async () => {
    //     try {
    //         let response = await AjaxService.get(`locations/`);
    //         setFilterState((previous: any) => {
    //             return {
    //                 ...previous,
    //                 locations: response.data.map((location: any) => {
    //                     return {
    //                         label: `${location.title} (${location.region})`,
    //                         value: location.id
    //                     }
    //                 })
    //             }
    //         });
    //     } catch (e: any) {
    //         notification.error({
    //             message: 'There was an error',
    //             description: 'Unfortunately we could not load the required information. Please try again.'
    //         });
    //     }
    // }

    // const fetchUsers = async () => {
    //     try {
    //         let response = await AjaxService.get(`users/`);
    //         setFilterState((previous: any) => {
    //             return {
    //                 ...previous,
    //                 users: response.data.map((user: any) => {
    //                     return {
    //                         label: `${user.first_name} ${user.last_name}`,
    //                         value: user.id
    //                     }
    //                 })
    //             }
    //         });
    //     } catch (e: any) {
    //         notification.error({
    //             message: 'There was an error',
    //             description: 'Unfortunately we could not load the required information. Please try again.'
    //         });
    //     }
    // }

    const fetchCountries = async () => {
        try {
            let response = await AjaxService.get(`cases/meta/`, {
                filter_case_type: 'complaint',
                filter_key: 'Country'
            }, true);
            setFilterState((previous: any) => {
                let countries: any = response.data.reduce((acc: any, country: any) => {
                    if (acc.indexOf(country) === -1) {
                        acc.push(country);
                    }
                    return acc;
                }, [
                    'England 1st Line', 
                    'Scotland 1st Line', 
                    'Wales 1st Line', 
                    'England 2nd Stage', 
                    'Scotland 2nd Stage', 
                    'Wales 2nd Stage'
                ]).map(function(country: any) {
                    return {
                        value: country,
                        label: country
                    };
                });
                return {
                    ...previous,
                    loadingCountries: false,
                    countries
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchData = async (params: any = tableState) => {
        setTableState((previous: any) => {
            return {
                ...previous,
                loading: true
            }
        });

        let request: any = {};

        if ('pagination' in params && Object.keys(params.pagination).length > 0) {
            request.page = params.pagination.current;
            request.limit = params.pagination.pageSize;
        }

        if ('sorter' in params && 'field' in params.sorter) {
            request.sort = (typeof params.sorter.field === 'string' ? params.sorter.field : params.sorter.field.join('.'));
            if (params.sorter.order) {
                request.direction = (params.sorter.order === 'descend' ? 'desc' : 'asc');
            }
        }

        if ('filters' in params && Object.keys(params.filters).length > 0) {
            Object.keys(params.filters).forEach((key: any) => {
                if (params.filters[key]) {
                    request['filter_' + key] = params.filters[key];
                }
            });
        }

        if ('filter' in props && props.filter.filter) {
            request.filter = props.filter.filter;
        }

        let response: any = false;
        try {
            response = await AjaxService.get(`cases/`, request);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }


        if (isCancelled) {
            return;
        }

        setTableState((previous: any) => {
            if (!('filters' in params)) {
                params.filters = previous.filters;
            }
            if (!('sorter' in params)) {
                params.sorter = previous.sorter;
            }
            if (!('pagination' in params)) {
                params.pagination = previous.pagination;
            }
            if (!('filterActive' in params)) {
                params.filterActive = previous.filterActive;
            }
            if (!response) {
                return {...previous, loading: false};
            }
            return {
                ...previous,
                ...params,
                loading: false,
                filterOpen: false,
                data: response.data,
                pagination: {
                    ...params.pagination,
                    total: response.meta.total
                }
            }
        });

        resizeTable();
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        fetchData({
            loading: true,
            filterOpen: false,
            pagination: {
                current: 1,
                pageSize: 10,
            },
            sorter: {
                field: 'created_at',
                order: 'descend'
            },
            filterActive: false,
            filters: {
                query: '',
                contact: '',
                id: '',
                location: '',
                operator: '',
                assigned_to: '',
                type: '',
                status: ''
            },
            data: []
        });
        fetchCaseStatuses();
        fetchCountries();
        fetchUsers();
        fetchLocationTree();
        // fetchLocations();
        fetchOperators();
        resizeTable();
        window.addEventListener('resize', resizeTable);
        return () => {
            isCancelled = true;
            window.removeEventListener('resize', resizeTable);
        };
    }, [props.checksum, props.filter.filter]);

    const columns = [{
        title: '',
        dataIndex: 'traffic_light',
        sorter: true,
        width: 36,
        render: (text: any, record: any, index: any) => {
            return (
                <Tooltip title={moment(record.created_at).fromNow()} placement="right">
                    <span className={`TrafficLight Is-${text}`}/>
                </Tooltip>
            );
        }
    }, {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        render: (text: any, record: any, index: any) => <><span className="clickable" key={`id_${index}`}
                                                              onClick={() => props.openTab(record.id, (record.reference ? record.reference : text), record.type.value)}>{(record.reference ? record.reference : text)}</span>{(record.assigned_to ? ` (${record.assigned_to.initials})` : '')}</>
    }, {
        title: 'Type',
        dataIndex: ['type', 'label'],
        sorter: true
    }, {
        title: 'Name',
        dataIndex: 'contact',
        sorter: true,
        render: (text: any) => (text ? <>{text.first_name} {text.last_name}</> : 'N/A')
        // render: (text: any) => (text ?? 'N/A')
    }, {
        title: 'Operator',
        dataIndex: ['operator', 'title'],
        // dataIndex: ['operator'],
        sorter: true,
        render: (text: any, record: any) => (text ? text + (record.operator.deleted_at ? ' (D)' : '') : 'N/A')
    }, {
        title: 'Location',
        dataIndex: 'location',
        sorter: true,
        render: (text: any) => (text ? <>{text.title}, {text.region}</> : 'N/A')
        // render: (text: any) => (text ?? 'N/A')
    }, {
        title: 'Status',
        dataIndex: ['status', 'title'],
        sorter: true,
        render: (text: any, record: any, index: any) => <>{text}{record.country_key ? ' (' + record.country_key.substring(0, 2).toUpperCase() + ')' : ''}</>
    }, {
        title: 'Created',
        dataIndex: 'created_at',
        sorter: true,
        render: (text: any, record: any, index: any) => moment(text).format('DD/MM/YYYY HH:mm')
    }];

    const addMenu = (
        <Menu onClick={(key: any) => props.addCase(key.key)}>
            {can('cases.enquiry.add') && (
                <Menu.Item key="enquiry">Enquiry</Menu.Item>
            )}
            {can('cases.complaint.add') && (
                <Menu.Item key="complaint">Complaint</Menu.Item>
            )}
            {can('cases.bab.add') && (
                <Menu.Item key="bab">BAB</Menu.Item>
            )}
            {can('cases.ybm.add') && (
                <Menu.Item key="ybm">YBM (Your Bus Matters)</Menu.Item>
            )}
            {can('cases.feedback.add') && (
                <Menu.Item key="feedback">Feedback</Menu.Item>
            )}
        </Menu>
    );

    const onFilter = (values: any) => {
        fetchData({
            ...tableState,
            filters: {...values},
            pagination: {
                ...tableState.pagination,
                current: 1
            },
            filterActive: true
        });
    }

    const onClearFilter = () => {
        form.resetFields();
        fetchData({
            ...tableState,
            filterActive: false,
            filters: {
                query: '',
                contact: '',
                id: '',
                location: [],
                operator: '',
                assigned_to: '',
                type: '',
                status: ''
            }
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            ...tableState,
            pagination,
            sorter
        });
    }

    const expandedRowRender = (record: any, index: any, indent: any, expanded: any) => {
        switch (record.type.value) {
            case 'bab':
                return <BABRow id={record.id} openTab={props.openTab} addCase={props.addCase}/>;
            case 'complaint':
                return <ComplaintRow id={record.id} openTab={props.openTab} addCase={props.addCase}/>;
            case 'enquiry':
                return <EnquiryRow id={record.id} openTab={props.openTab} addCase={props.addCase}/>;
            case 'ybm':
                return <YBMRow id={record.id} openTab={props.openTab} addCase={props.addCase}/>;
            case 'feedback':
                return <FeedbackRow id={record.id} openTab={props.openTab} addCase={props.addCase}/>;
        }
    }

    const filterSelect = (input: any, option: any) => {
        let value = ('children' in option ? option.children : option.label);
        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const searchContacts = async (search: any) => {
        setFilterState((previous: any) => {
            return {
                ...previous,
                loadingContacts: true,
                contacts: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`contacts`, {filter_query: search});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFilterState((previous: any) => {
            return {
                ...previous,
                loadingContacts: false,
                contacts: (response ? response.data : previous.contacts)
            };
        });
    }

    const resizeTable = () => {
        let wrappers = document.getElementsByClassName('ant-table-wrapper');
        Array.from(wrappers).forEach((wrapper: any) => {
            let newHeight = wrapper.parentNode.clientHeight - 150;
            if (tableState.height !== newHeight) {
                setTableState((previous: any) => {
                    return {...previous, height: newHeight}
                });
            }
        });
    }

    let resizeInterval: any;
    useEffect(() => {
        if (resizeInterval) {
            clearInterval(resizeInterval);
        }
        resizeInterval = setInterval(resizeTable, 500);
        return () => {
            if (resizeInterval) {
                clearInterval(resizeInterval);
            }
        };
    }, [props]);

    // useEffect(() => {
    //     resizeTable();
    // });

    return (
        <>
            <Drawer
                title="Filter Cases"
                visible={tableState.filterOpen}
                closable={true}
                destroyOnClose
                forceRender
                onClose={() => setTableState({...tableState, filterOpen: false})}
                footer={
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Button onClick={() => onClearFilter()} type="text" danger={true}>
                            Clear Filter
                        </Button>
                        <Button onClick={() => setTableState({...tableState, filterOpen: false})}
                                style={{marginRight: 8, marginLeft: 'auto'}}>
                            Close
                        </Button>
                        <Button onClick={() => form.submit()} type="primary">
                            Apply Filters
                        </Button>
                    </div>
                }
            >
                <Form
                    {...filterFormLayout}
                    form={form}
                    onFinish={onFilter}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Search"
                                name="query"
                            >
                                <Input placeholder="Search" value={tableState.filters.query}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Contact"
                                name="contact"
                            >
                                <Select
                                    placeholder="Search for a contact"
                                    showSearch
                                    onSearch={searchContacts}
                                    filterOption={false}
                                    notFoundContent={filterState.loadingContacts ? <Spin size="small"/> : null}
                                    loading={filterState.loadingContacts}
                                    value={tableState.filters.contact}
                                >
                                    {filterState.contacts.map((contact: any) => {
                                        return (
                                            <Option key={contact.id.toString()} value={contact.id.toString()}>
                                                {contact.first_name} {contact.last_name}
                                                <small style={{display: 'block'}}>{contact.email}</small>
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item
                                label="Contact"
                                name="contact"
                            >
                                <Input placeholder="Contact" value={tableState.filters.contact}/>
                            </Form.Item>
                        </Col> */}
                        <Col span={24} lg={12}>
                            <Form.Item
                                label="Case ID/Number"
                                name="id"
                            >
                                <Input placeholder="Case ID/Number" value={tableState.filters.id}/>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                            <Form.Item
                                label="Location"
                                name="location"
                            >
                                <Cascader
                                    disabled={filterState.loadingLocations}
                                    options={filterState.locations}
                                    allowClear
                                    style={{width: '100%'}}
                                    value={tableState.filters.location}
                                    showSearch={{
                                        filter: (inputValue, path) => {
                                            return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                                        }
                                    }}
                                    placeholder="Location"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                            <Form.Item
                                label="Country"
                                name="country"
                            >
                                <Select placeholder="Country" value={tableState.filters.country}
                                        options={filterState.countries} filterOption={filterSelect} allowClear showSearch/>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                            <Form.Item
                                label="Bus Operator"
                                name="operator"
                            >
                                <Select placeholder="Bus Operator" value={tableState.filters.operator}
                                        options={filterState.operators} filterOption={filterSelect} allowClear
                                        showSearch/>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                            <Form.Item
                                label="Assigned To"
                                name="assigned_to"
                            >
                                <Select placeholder="Assigned To" value={tableState.filters.assigned_to}
                                        options={filterState.users} filterOption={filterSelect} allowClear showSearch/>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                            <Form.Item
                                label="Type"
                                name="type"
                            >
                                <Select placeholder="Type" value={tableState.filters.type} filterOption={filterSelect}
                                        allowClear showSearch>
                                    <Select.Option key="bab" value="bab">BAB</Select.Option>
                                    <Select.Option key="complaint" value="complaint">Complaint</Select.Option>
                                    <Select.Option key="enquiry" value="enquiry">Enquiry</Select.Option>
                                    <Select.Option key="feedback" value="feedback">Feedback</Select.Option>
                                    <Select.Option key="ybm" value="ybm">YBM</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={12}>
                            <Form.Item
                                label="Status"
                                name="status"
                            >
                                <Select placeholder="Status" value={tableState.filters.status}
                                        options={filterState.statuses} filterOption={filterSelect} allowClear
                                        showSearch/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <div className="CaseTableActions">
                {canAny([
                    'cases.enquiry.add',
                    'cases.complaint.add',
                    'cases.bab.add',
                    'cases.ybm.add',
                    'cases.feedback.add',
                ]) && (
                    <Dropdown overlay={addMenu}>
                        <Button>
                            Add Case <DownOutlined/>
                        </Button>
                    </Dropdown>
                )}
                <Tooltip title="Refresh Cases" placement="left">
                    <Button
                        icon={<SyncOutlined/>}
                        style={{marginLeft: 'auto'}}
                        onClick={() => fetchData()}
                    />
                </Tooltip>
                <Tooltip title="Filter Cases" placement="left">
                    <Button
                        icon={(tableState.filterActive ? <FilterTwoTone twoToneColor="#52c41a"/> : <FilterOutlined/>)}
                        onClick={() => setTableState({...tableState, filterOpen: true})}
                        style={{marginLeft: '.5rem'}}
                    />
                </Tooltip>
            </div>
            <Table
                className="CaseTable"
                columns={columns}
                dataSource={tableState.data}
                loading={tableState.loading}
                rowKey="id"
                expandable={{
                    expandedRowRender
                }}
                onChange={handleTableChange}
                pagination={tableState.pagination}
                scroll={{y:tableState.height}}
            />
        </>
    );
}

export default CaseTable;