import React, {useContext, useEffect, useState, useRef} from 'react';
import {Button, PageHeader, Tag, Tooltip} from 'antd';
import {
    EditOutlined,
    EyeInvisibleTwoTone,
    EyeOutlined,
    PlusOutlined,
    ArrowRightOutlined,
    HistoryOutlined
} from '@ant-design/icons';
import CaseTabs from '../../../../Components/CaseTabs';
import Row from '../Row';
import './index.less';
import {ProfileContext, useCan} from "../../../../Contexts/ProfileContext";

function Enquiry(props: any) {

    const [tabState, setTabState] = useState(props);
    const [modalState, setModalState] = useState({
        visible: false,
        loading: false,
        response_type: 'external',
        addResponse: false as any
    });

    const {profile} = useContext(ProfileContext);
    const {can, canCaseAction} = useCan(profile);

    let caseTabsRef = useRef();

    useEffect(() => {
        setTabState(props);
    }, [props]);

    return (
        <div className="CaseEnquiry">
            <PageHeader title="Enquiry" extra={[
                (can('cases.watch') && (
                    <Tooltip title={(tabState.rowData.watched ? 'Unwatch' : 'Watch') + ' Enquiry'} key="watch">
                        <Button onClick={() => props.watch(!tabState.rowData.watched)}
                                icon={(tabState.rowData.watched ? <EyeInvisibleTwoTone twoToneColor="#52c41a"/> :
                                    <EyeOutlined/>)}/>
                    </Tooltip>
                )),
                (can('cases.enquiry.edit') && (
                    <Tooltip title="Edit Enquiry" key="edit">
                        <Button onClick={() => props.editCase('enquiry', tabState.rowData.id)} icon={<EditOutlined/>}/>
                    </Tooltip>
                )),
                (can('cases.complaint.add') && (
                    <Tooltip title={(tabState.rowData.meta['Complaint'] ? 'View' : 'Add') + ' Complaint'} key="complaint">
                        <Button onClick={() => (tabState.rowData.meta['Complaint'] ? props.openTab(tabState.rowData.meta['Complaint'].id, tabState.rowData.meta['Complaint'].reference, 'complaint') : props.addCase('complaint', tabState.rowData, 'Add Complaint from Enquiry'))} icon={<ArrowRightOutlined/>}/>
                    </Tooltip>
                )),
                (can('cases.enquiry.archive') && (tabState.rowState?.status?.title || tabState.rowState?.status?.title !== 'Archived') && (
                    <Tooltip title={'Archive Enquiry'} key="archive">
                        <Button onClick={() => props.archiveCase(tabState.rowState)} icon={<HistoryOutlined />}/>
                    </Tooltip>
                )),
                (canCaseAction(tabState.rowData, 'respond') && (
                    <Button key="respond" onClick={() => modalState.addResponse({loading: false, visible: true, response_type: 'external'})}
                            icon={<PlusOutlined/>}>
                        Add response
                    </Button>
                ))
            ]} tags={[
                <Tag key="status-tag" color="green">{tabState.rowData.status.title}</Tag>
            ]}/>
            <Row {...tabState} openTab={props.openTab}/>
            <CaseTabs case={tabState.rowData} checksum={props.checksum} modalState={modalState}
                      setModalState={setModalState} checkTabLock={props.checkTabLock}/>
        </div>
    );
}

export default Enquiry;
