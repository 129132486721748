import React, {useEffect, useState} from 'react';
import Master from '../../../Components/Layouts/Master';
import './index.less';
import {Button, Col, Drawer, Form, Input, notification, Row, Table, Tooltip} from "antd";
import {FilterOutlined, FilterTwoTone} from "@ant-design/icons";
import moment from "moment";
import AjaxService from "../../../Services/AjaxService";

function List(props: any) {

    let isCancelled = false;

    const [filterForm] = Form.useForm();

    const filterFormLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const [tableState, setTableState] = useState({
        loading: true,
        filterOpen: false,
        selectedRows: [],
        height: 100,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sorter: {
            field: 'created_at',
            order: 'descend'
        },
        filterActive: false,
        filters: {
            query: ''
        },
        data: [] as any
    });

    const fetchData = async (params: any = tableState) => {
        setTableState((previous: any) => {
            return {
                ...previous,
                loading: true
            }
        });

        let request: any = {};

        if ('pagination' in params && Object.keys(params.pagination).length > 0) {
            request.page = params.pagination.current;
            request.limit = params.pagination.pageSize;
        }

        if ('sorter' in params && 'field' in params.sorter) {
            request.sort = (typeof params.sorter.field === 'string' ? params.sorter.field : params.sorter.field.join('.'));
            if (params.sorter.order) {
                request.direction = (params.sorter.order === 'descend' ? 'desc' : 'asc');
            }
        }

        if ('filters' in params && Object.keys(params.filters).length > 0) {
            Object.keys(params.filters).forEach((key: any) => {
                if (params.filters[key]) {
                    request['filter_' + key] = params.filters[key];
                }
            });
        }

        let response: any;
        try {
            response = await AjaxService.get(`audit-trail/`, request);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setTableState((previous: any) => {
            if (!('filters' in params)) {
                params.filters = previous.filters;
            }
            if (!('sorter' in params)) {
                params.sorter = previous.sorter;
            }
            if (!('pagination' in params)) {
                params.pagination = previous.pagination;
            }
            if (!('filterActive' in params)) {
                params.filterActive = previous.filterActive;
            }
            if (!response) {
                return {...previous, loading: false}
            }
            return {
                ...previous,
                ...params,
                loading: false,
                filterOpen: false,
                data: response.data,
                pagination: {
                    ...params.pagination,
                    total: response.meta.total
                }
            }
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            ...tableState,
            pagination,
            sorter
        });
    }

    const columns = [{
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        sorter: true
    }, {
        title: 'User',
        dataIndex: 'author',
        render: (text: any, row: any) => `${row.author.first_name} ${row.author.last_name}`
    }, {
        title: 'Type',
        dataIndex: ['affected', 'title']
    }, {
        title: 'Action',
        dataIndex: 'action',
        sorter: true
    }, {
        title: 'Date',
        dataIndex: 'created_at',
        sorter: true,
        render: (text: any, record: any, index: any) => moment(text).format('DD/MM/YYYY HH:mm')
    }, {
        title: '',
        key: 'actions',
        align: 'right' as 'right'
    }];

    const onFilter = (values: any) => {
        fetchData({
            ...tableState,
            filters: {...values},
            pagination: {
                ...tableState.pagination,
                current: 1
            },
            filterActive: true
        });
    }

    const onClearFilter = () => {
        filterForm.resetFields();
        fetchData({
            ...tableState,
            filterActive: false,
            filters: {
                query: ''
            }
        });
    }

    const resizeTable = () => {
        let wrappers = document.getElementsByClassName('ant-table-wrapper');
        Array.from(wrappers).forEach((wrapper: any) => {
            let newHeight = wrapper.parentNode.clientHeight - 180;
            if (tableState.height !== newHeight) {
                setTableState((previous: any) => {
                    return {...previous, height: newHeight}
                });
            }
        });
    }

    useEffect(() => {
        resizeTable();
    });

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        fetchData();
        window.addEventListener('resize', resizeTable);
        return () => {
            isCancelled = true;
            window.removeEventListener('resize', resizeTable);
        };
    }, [props]);

    return (
        <Master className={`ItemList`}>
            <Drawer
                title="Filter Audit Trail"
                visible={tableState.filterOpen}
                closable={true}
                destroyOnClose
                forceRender
                onClose={() => setTableState({...tableState, filterOpen: false})}
                footer={
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Button onClick={() => onClearFilter()} type="text" danger={true}>
                            Clear Filter
                        </Button>
                        <Button onClick={() => setTableState({...tableState, filterOpen: false})}
                                style={{marginRight: 8, marginLeft: 'auto'}}>
                            Close
                        </Button>
                        <Button onClick={() => filterForm.submit()} type="primary">
                            Apply Filters
                        </Button>
                    </div>
                }
            >
                <Form
                    {...filterFormLayout}
                    form={filterForm}
                    onFinish={onFilter}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Search"
                                name="query"
                            >
                                <Input placeholder="Search" value={tableState.filters.query}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <div className="CaseTableActions">
                <span className="CaseTableTitle">Audit Trail</span>
                <Tooltip title="Filter Items" placement="left">
                    <Button
                        icon={(tableState.filterActive ? <FilterTwoTone twoToneColor="#52c41a"/> : <FilterOutlined/>)}
                        onClick={() => setTableState({...tableState, filterOpen: true})}
                        style={{marginLeft: '.5rem'}}
                    />
                </Tooltip>
            </div>
            <Table
                className="ItemTable"
                columns={columns}
                dataSource={tableState.data}
                loading={tableState.loading}
                rowKey="id"
                onChange={handleTableChange}
                pagination={tableState.pagination}
                scroll={{y:tableState.height}}
            />
        </Master>
    );
}

export default List;