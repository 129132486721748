import React, {useContext, useEffect, useState} from 'react';
import Master from '../../../Components/Layouts/Master';
import PermissionTree from '../../../Components/PermissionTree';
import './index.less';
import {Alert, Button, Col, Divider, Drawer, Dropdown, Form, Input, Menu, Modal, notification, Row, Table} from "antd";
import {DeleteOutlined, DownOutlined, EditOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import AjaxService from "../../../Services/AjaxService";
import {ProfileContext, useCan} from "../../../Contexts/ProfileContext";

function List(props: any) {

    let isCancelled = false;

    const [form] = Form.useForm();

    const {profile} = useContext(ProfileContext);
    const {can, canAny} = useCan(profile);

    const [tableState, setTableState] = useState({
        loading: true,
        selectedRows: [],
        height: 100,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sorter: {
            field: 'created_at',
            order: 'descend'
        },
        filterActive: false,
        filters: {
            query: ''
        },
        data: [] as any
    });

    const [formState, setFormState] = useState({
        visible: false,
        loading: false,
        title: '',
        error: '',
        current: false as any,
        permissions: [],
        roles: [],
        loadingPermissions: false,
        loadingRoles: false
    });

    const deleteSingle = (role: any) => {
        Modal.confirm({
            title: `Delete Role`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to delete this role?',
            onOk() {
                AjaxService.delete(`roles/${role.id}`,).then((result) => {
                    if (result !== false) {
                        notification.success({
                            message: 'Delete Role',
                            description: 'The role has been successfully deleted.'
                        });
                    }
                    fetchData({...tableState, selectedRows: [], loading: true});
                }).catch((e: any) => {
                    notification.error({
                        message: 'Delete Role',
                        description: e.message
                    });
                    fetchData({...tableState, selectedRows: [], loading: false});
                });
            },
            onCancel() {
            },
        });
    }

    const fetchPermissions = async () => {
        setFormState((previous: any) => {
            return {...previous, loadingPermissions: true};
        });
        let response: any;
        try {
            response = await AjaxService.get('permissions');
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingPermissions: false,
                permissions: (response ? response.data : previous.permissions)
            };
        });
    }

    const fetchData = async (params: any = tableState) => {
        setTableState((previous: any) => {
            return {
                ...previous,
                loading: true
            }
        });

        let request: any = {};

        if ('pagination' in params && Object.keys(params.pagination).length > 0) {
            request.page = params.pagination.current;
            request.limit = params.pagination.pageSize;
        }

        if ('sorter' in params && 'field' in params.sorter) {
            request.sort = (typeof params.sorter.field === 'string' ? params.sorter.field : params.sorter.field.join('.'));
            if (params.sorter.order) {
                request.direction = (params.sorter.order === 'descend' ? 'desc' : 'asc');
            }
        }

        if ('filters' in params && Object.keys(params.filters).length > 0) {
            Object.keys(params.filters).forEach((key: any) => {
                if (params.filters[key]) {
                    request['filter_' + key] = params.filters[key];
                }
            });
        }

        let response: any;
        try {
            response = await AjaxService.get(`roles/`, request);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setTableState((previous: any) => {
            if (!('filters' in params)) {
                params.filters = previous.filters;
            }
            if (!('sorter' in params)) {
                params.sorter = previous.sorter;
            }
            if (!('pagination' in params)) {
                params.pagination = previous.pagination;
            }
            if (!('filterActive' in params)) {
                params.filterActive = previous.filterActive;
            }
            if (!response) {
                return {...previous, loading: false}
            }
            return {
                ...previous,
                ...params,
                loading: false,
                data: response.data,
                pagination: {
                    ...params.pagination,
                    total: response.meta.total
                }
            }
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            ...tableState,
            pagination,
            sorter
        });
    }

    const editRole = async (role: any) => {

        try {
            let response = await AjaxService.get('roles/' + role.id, {
                mode: 'advanced'
            });
            setFormState({...formState, visible: true, title: 'Edit Role', current: response.data});
            console.log(response.data.permissions);
            form.setFieldsValue({
                name: response.data.name,
                permissions: response.data.permissions.map((v: any) => v.toString())
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const columns = [{
        title: 'Name',
        key: 'name',
        render: (text: any, row: any) => {
            return <span className="clickable" onClick={() => editRole(row)}>{row.name}</span>;
        }
    }, {
        title: 'Created',
        dataIndex: 'created_at',
        sorter: true,
        render: (text: any, record: any, index: any) => moment(text).format('DD/MM/YYYY HH:mm')
    }, (canAny(['roles.edit', 'roles.delete']) ? {
        title: '',
        key: 'actions',
        align: 'right' as 'right',
        render: (text: any, row: any) => <Dropdown overlay={roleMenu(row)} placement="bottomRight">
            <Button icon={<DownOutlined/>}/>
        </Dropdown>
    } : null)];

    const roleMenu = (row: any, viewing: any = false) => (
        <Menu>
            {can('roles.edit') && (
                <Menu.Item key="edit" onClick={() => editRole(row)} icon={<EditOutlined/>}>Edit</Menu.Item>
            )}
            {row.id !== 0 && can('roles.delete') && (
                <Menu.Item key="delete" onClick={() => deleteSingle(row)} icon={<DeleteOutlined/>}>Delete</Menu.Item>
            )}
        </Menu>
    );

    const closeForm = () => {
        form.resetFields();
        setFormState({...formState, visible: false, loading: false});
    }

    const addRole = () => {
        form.resetFields();
        setFormState({...formState, visible: true, title: 'Add Role'});
    }

    const onSubmit = async (values: any) => {
        setFormState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        let response: any;
        try {
            if (formState.current) {
                response = await AjaxService.put('roles/' + formState.current.id, values);
            } else {
                response = await AjaxService.post('roles', values);
            }
        } catch (e: any) {
            if (!('email' in e.data.errors)) {
                notification.error({
                    message: (formState.current ? 'Edit' : 'Add') + ' Role',
                    description: 'There was an issue ' + (formState.current ? 'updat' : 'creat') + 'ing the role. Please try again.'
                });
            }
            setFormState((previous: any) => {
                return {
                    ...previous,
                    error: ('email' in e.data.errors ? e.data.errors.email[0] : ''),
                    loading: false
                };
            });
        }

        if (response) {
            notification.success({
                message: (formState.current ? 'Edit' : 'Add') + ' Role',
                description: 'The role was successfully ' + (formState.current ? 'updat' : 'creat') + 'ed.'
            });
            setFormState({...formState, current: false, visible: false});
            form.resetFields();
            fetchData();
        }

    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        fetchData();
        window.addEventListener('resize', resizeTable);
        return () => {
            isCancelled = true;
            window.removeEventListener('resize', resizeTable);
        };
    }, []);

    const resizeTable = () => {
        let wrappers = document.getElementsByClassName('ant-table-wrapper');
        Array.from(wrappers).forEach((wrapper: any) => {
            let newHeight = wrapper.parentNode.clientHeight - 180;
            if (tableState.height !== newHeight) {
                setTableState((previous: any) => {
                    return {...previous, height: newHeight}
                });
            }
        });
    }

    useEffect(() => {
        resizeTable();
    });

    useEffect(() => {
        if (formState.visible && formState.roles.length === 0) {
            fetchPermissions();
        }
    }, [formState.visible]);

    return (
        <Master className={`RolesList`}>
            {canAny(['roles.add', 'roles.edit']) && (
                <Drawer
                    title={formState.title}
                    visible={formState.visible}
                    closable={true}
                    destroyOnClose
                    forceRender
                    onClose={() => setFormState({...formState, visible: false, loading: false})}
                    footer={
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Button loading={formState.loading} onClick={() => closeForm()}>
                                Discard
                            </Button>
                            <Button loading={formState.loading} type="primary" onClick={() => form.submit()}
                                    style={{marginLeft: 'auto'}}>
                                Save
                            </Button>
                        </div>
                    }
                >
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onSubmit}
                        className="RoleForm"
                    >
                        {formState.error !== '' && (
                            <Alert style={{marginBottom: '1rem'}} message={formState.error} type="error" showIcon/>
                        )}
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label="Role Name"
                                    rules={[{required: true, message: 'Role Name is required!'}]}
                                >
                                    <Input placeholder="Role Name"/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Divider>Permissions</Divider>
                                <Form.Item
                                    name="permissions"
                                    valuePropName="value"
                                    initialValue={[]}
                                >
                                    <PermissionTree permissions={formState.permissions}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            )}
            <div className="CaseTableActions">
                <span className="CaseTableTitle">Roles</span>
                {can('roles.add') && (
                    <Button style={{marginLeft: '.5rem'}} onClick={() => addRole()}>Add Role</Button>
                )}
            </div>
            <Table
                className="RoleTable"
                columns={columns.filter(c => c != null) as any}
                dataSource={tableState.data}
                loading={tableState.loading}
                rowKey="id"
                onChange={handleTableChange}
                pagination={tableState.pagination}
                scroll={{y: tableState.height}}
            />
            {can('roles.add') && (
                <Button className={tableState.data.length ? 'PaginationButton' : 'mt-15'} onClick={() => addRole()}>Add
                    Role</Button>
            )}
        </Master>
    );
}

export default List;