import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Modal, notification, PageHeader, Tag, Tooltip, Select, DatePicker} from 'antd';
import {EditOutlined, EyeInvisibleTwoTone, EyeOutlined, PlusOutlined, ArrowRightOutlined, HistoryOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import CaseTabs from '../../../../Components/CaseTabs';
import Row from '../Row';
import './index.less';
import {ProfileContext, useCan} from "../../../../Contexts/ProfileContext";
import AjaxService from '../../../../Services/AjaxService';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { format } from 'path';

function Complaint(props: any) {

    const [tabState, setTabState] = useState(props as any);
    const [modalState, setModalState] = useState({
        visible: false,
        loading: false,
        response_type: 'external'
    });

    const [formState, setFormState] = useState({
        statusSearch: '',
        statuses: [] as any,
        closeModalVisible: false,
        completeModalVisible: false,
        acceptModalVisible: false,
        authoriseModalVisible: false,
        loadingStatuses: true
    });

    const [form] = Form.useForm();

    const {profile} = useContext(ProfileContext);
    const {can, canCaseAction} = useCan(profile);

    let caseTabsRef = React.createRef();

    const fetchStatuses = async () => {
        try {
            let response = await AjaxService.get(`case-statuses/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingStatuses: false,
                    statuses: response.data.map((status: any) => {
                        return {
                            label: status.title,
                            value: status.id.toString()
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const outOfRemit = () => {
        Modal.confirm({
            title: `Close Case?`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to close this case, marking it as out of remit?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                // do it...
                try {
                    AjaxService.put(`cases/${tabState.rowData.id}`, {
                        basic: 1,
                        closed_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                        closure_reason: 'Out of Remit',
                        status_id: 'Out of Remit'
                    }).then(() => {
                        tabState.openTab(tabState.rowData.id, (tabState.rowData.reference ? tabState.rowData.reference : tabState.rowData.id), 'complaint', true);
                        notification.success({
                            message: 'Out of Remit',
                            description: 'The case has been successfully marked as out of remit.'
                        });
                    });
                } catch (e: any) {
                    notification.error({
                        message: 'Out of Remit',
                        description: 'There was an issue closing the case. Please try again.'
                    });
                }
            },
            onCancel() {
            },
        });
    }

    const acceptComplaint = () => {
        setFormState({...formState, acceptModalVisible: true});
    }

    const authoriseComplaint = (key: any) => {
        setFormState({...formState, authoriseModalVisible: key});
    }

    const filterSelect = (input: any, option: any) => {
        let value = ('children' in option ? option.children : option.label);
        if (Array.isArray(value)) {
            value = value.join('');
        }
        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const completeComplaint = () => {
        setFormState({...formState, completeModalVisible: true});
    }

    useEffect(() => {
        setTabState(props);
    }, [props]);

    useEffect(() => {
        // eslint-disable-next-line
        fetchStatuses();
        return () => {
        };
    }, []);

    return (
        <div className="CaseComplaint">
            <PageHeader title="Complaint" extra={[
                (tabState.rowData.accepted_at === null && tabState.rowData.closed_at === null && (
                    <Button className="Text-Green" key="accept" onClick={acceptComplaint}>Accept Case</Button>
                )),
                (tabState.rowData.accepted_at !== null && !('Authorised by Passenger' in tabState.rowData.meta) && tabState.rowData.closed_at === null && (
                    <Button className="Text-Green" key="passenger-auth" onClick={() => authoriseComplaint('Authorised by Passenger')}>Authorised By Passenger</Button>
                )),
                (tabState.rowData.accepted_at !== null && ('Authorised by Passenger' in tabState.rowData.meta) && tabState.rowData.completed_at === null && tabState.rowData.closed_at === null && (
                    <Button className="Text-Green" key="complete" onClick={completeComplaint}>Complete Case</Button>
                )),
                (tabState.rowData.closed_at === null && (
                    <Button className="Text-Red" key="close" onClick={() => setFormState({...formState, closeModalVisible: true})}>Close Case</Button>
                )),
                (can('cases.watch') && (
                    <Tooltip title={(tabState.rowData.watched ? 'Unwatch' : 'Watch') + ' Complaint'} key="watch">
                        <Button onClick={() => props.watch(!tabState.rowData.watched)}
                                icon={(tabState.rowData.watched ? <EyeInvisibleTwoTone twoToneColor="#52c41a"/> :
                                    <EyeOutlined/>)}/>
                    </Tooltip>
                )),
                (can('cases.complaint.edit') && (
                    <Tooltip title="Edit Complaint" key="edit">
                        <Button onClick={() => props.editCase('complaint', tabState.rowData.id)} icon={<EditOutlined/>}/>
                    </Tooltip>
                )),
                (can('cases.bab.add') && (
                    <Tooltip title={(tabState.rowData.meta['BAB'] ? 'View' : 'Add') + ' BAB'} key="bab">
                        <Button onClick={() => (tabState.rowData.meta['BAB'] ? props.openTab(tabState.rowData.meta['BAB'].id, tabState.rowData.meta['BAB'].reference, 'bab') : props.addCase('bab', tabState.rowData, 'Add BAB from Complaint'))} icon={<ArrowRightOutlined/>}/>
                    </Tooltip>
                )),
                (can('cases.complaint.archive') && (tabState.rowState?.status?.title || tabState.rowState?.status?.title !== 'Archived') && (
                    <Tooltip title={'Archive Complaint'} key="archive">
                        <Button onClick={() => props.archiveCase(tabState.rowData.id)} icon={<HistoryOutlined />}/>
                    </Tooltip>
                )),
                (canCaseAction(tabState.rowData, 'respond') && (
                    <Button key="respond" onClick={() => setModalState({...modalState, visible: true, response_type: 'external'})}
                            icon={<PlusOutlined/>}>
                        Add response
                    </Button>
                ))
            ]} tags={[
                <Tag key="status-tag" color="green">{tabState.rowData.status.title}</Tag>
            ]}/>
            <Row {...tabState} openTab={props.openTab}/>
            <CaseTabs case={tabState.rowData} ref={caseTabsRef} checksum={props.checksum} modalState={modalState}
                      setModalState={setModalState} checkTabLock={props.checkTabLock}/>
            <Modal
                visible={formState.closeModalVisible}
                title="Close Case?"
                destroyOnClose
                onOk={() => form.submit()}
                onCancel={() => setFormState({...formState, closeModalVisible: false})}
            >
                <Form form={form} preserve={false} layout="vertical" onFinish={(values: any) => {
                    try {
                        AjaxService.put(`cases/${tabState.rowData.id}`, {
                            basic: 1,
                            closed_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                            closure_reason: values.closure_reason,
                            status_id: values.status_id
                        }).then(() => {
                            setFormState({...formState, closeModalVisible: false});
                            tabState.openTab(tabState.rowData.id, (tabState.rowData.reference ? tabState.rowData.reference : tabState.rowData.id), 'complaint', true);
                            notification.success({
                                message: 'Case Closed',
                                description: 'The case has been successfully closed.'
                            });
                        });
                    } catch (e: any) {
                        notification.error({
                            message: 'Close Case',
                            description: 'There was an issue closing the case. Please try again.'
                        });
                    }
                }}>
                    <Form.Item
                        label="Status"
                        name="status_id"
                        rules={[{required: true, message: 'Please select a status!'}]}
                    >
                        <Select
                            style={{width: '100%'}}
                            showSearch
                            onSearch={(value: any) => setFormState({...formState, statusSearch: value})}
                            filterOption={filterSelect}
                            loading={formState.loadingStatuses}
                            onChange={(value: any, option: any) => {
                                if (option.key === 'custom') {
                                    setFormState({
                                        ...formState,
                                        statusSearch: '',
                                        statuses: [
                                            ...formState.statuses, {
                                                value: value,
                                                label: value
                                            }
                                        ]
                                    });
                                    form.setFieldsValue({
                                        status_id: value
                                    });
                                }
                            }}
                            placeholder="Search for a status"
                        >
                            {formState.statuses && formState.statuses.map((status: any) => {
                                return <Select.Option key={status.value}
                                                        value={status.value}>{status.label}</Select.Option>
                            })}
                            {formState.statusSearch && (
                                <Select.Option key="custom"
                                                value={formState.statusSearch}>Add &ldquo;{formState.statusSearch}&rdquo;</Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Closure Reason"
                        name="closure_reason"
                        rules={[{required: true, message: 'Enter a reason for closure!'}]}
                    >
                        <TextArea rows={4} placeholder="Reason for closure" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={formState.acceptModalVisible}
                title="Accept Case?"
                destroyOnClose
                onOk={() => form.submit()}
                onCancel={() => setFormState({...formState, acceptModalVisible: false})}
            >
                <Form initialValues={{
                    accepted_at: moment()
                }} form={form} preserve={false} layout="vertical" onFinish={(values: any) => {
                    try {
                        AjaxService.put(`cases/${tabState.rowData.id}`, {
                            basic: 1,
                            accepted_at: values.accepted_at.format('YYYY-MM-DD HH:mm:ss'),
                            status_id: 'Accepted'
                        }).then(() => {
                            setFormState({...formState, acceptModalVisible: false});
                            tabState.openTab(tabState.rowData.id, (tabState.rowData.reference ? tabState.rowData.reference : tabState.rowData.id), 'complaint', true);
                            notification.success({
                                message: 'Case Accepted',
                                description: 'The case has been successfully accepted.'
                            });
                        });
                    } catch (e: any) {
                        notification.error({
                            message: 'Accept Case',
                            description: 'There was an issue accepting the case. Please try again.'
                        });
                    }
                }}>
                    <p>Please select when this case was accepted if different from today.</p>
                    <Form.Item
                        label="Acceptance Date"
                        name="accepted_at"
                        rules={[{required: true, message: 'Select the date'}]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={formState.authoriseModalVisible !== false}
                title={formState.authoriseModalVisible}
                destroyOnClose
                onOk={() => form.submit()}
                onCancel={() => setFormState({...formState, acceptModalVisible: false})}
            >
                <Form initialValues={{
                    authorised_at: moment()
                }} form={form} preserve={false} layout="vertical" onFinish={(values: any) => {
                    try {
                        let fields: any = {
                            basic: 1,
                            meta: {}
                        };
                        fields.meta[formState.authoriseModalVisible as any] = values.authorised_at.format('YYYY-MM-DD HH:mm:ss');
                        AjaxService.put(`cases/${tabState.rowData.id}`, fields).then(() => {
                            setFormState({...formState, authoriseModalVisible: false});
                            tabState.openTab(tabState.rowData.id, (tabState.rowData.reference ? tabState.rowData.reference : tabState.rowData.id), 'complaint', true);
                            notification.success({
                                message: 'Case Updated',
                                description: 'The case has been successfully updated.'
                            });
                        });
                    } catch (e: any) {
                        notification.error({
                            message: 'Update Case',
                            description: 'There was an issue updating the case. Please try again.'
                        });
                    }
                }}>
                    <p>Please select when this case was authorised if different from today.</p>
                    <Form.Item
                        label="Authorisation Date"
                        name="authorised_at"
                        rules={[{required: true, message: 'Select the date'}]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={formState.completeModalVisible}
                title="Complete Case?"
                destroyOnClose
                onOk={() => form.submit()}
                onCancel={() => setFormState({...formState, completeModalVisible: false})}
            >
                <Form initialValues={{
                    completed_at: moment()
                }} form={form} preserve={false} layout="vertical" onFinish={(values: any) => {
                    try {
                        AjaxService.put(`cases/${tabState.rowData.id}`, {
                            basic: 1,
                            completed_at: values.completed_at.format('YYYY-MM-DD HH:mm:ss'),
                            status_id: 'Completed'
                        }).then(() => {
                            setFormState({...formState, completeModalVisible: false});
                            tabState.openTab(tabState.rowData.id, (tabState.rowData.reference ? tabState.rowData.reference : tabState.rowData.id), 'complaint', true);
                            notification.success({
                                message: 'Case Completed',
                                description: 'The case has been successfully completed.'
                            });
                        });
                    } catch (e: any) {
                        notification.error({
                            message: 'Complete Case',
                            description: 'There was an issue completing the case. Please try again.'
                        });
                    }
                }}>
                    <p>Please select when this case was completed if different from today.</p>
                    <Form.Item
                        label="Completion Date"
                        name="completed_at"
                        rules={[{required: true, message: 'Select the date'}]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Complaint;
