import React, {useContext, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import Master from '../../../Components/Layouts/Master';
import LinkModal from '../../../Components/LinkModal';
import './index.less';
import {
    Button,
    Col, DatePicker,
    Drawer,
    Dropdown,
    Form,
    Input,
    Menu,
    Modal,
    notification,
    Row,
    Select,
    Spin,
    Table,
    Tag,
    Tooltip,
    Upload
} from "antd";
import {
    DeleteOutlined,
    DownOutlined,
    FilterOutlined,
    FilterTwoTone,
    LinkOutlined,
    PaperClipOutlined,
    QuestionCircleOutlined,
    SendOutlined,
    SyncOutlined,
    UndoOutlined,
    UpOutlined,
    ForwardOutlined,
    UserSwitchOutlined,
    UploadOutlined,
    RocketOutlined
} from "@ant-design/icons";
import AjaxService from "../../../Services/AjaxService";
import moment from "moment";
import {ProfileContext, useCan} from "../../../Contexts/ProfileContext";
import { debounce } from 'lodash';

const {confirm} = Modal;

function List(props: any) {

    let isCancelled = false;
    const history = useHistory();

    const [filterForm] = Form.useForm();
    const [replyForm] = Form.useForm();
    const [composeForm] = Form.useForm();
    const [forwardForm] = Form.useForm();
    const [assignForm] = Form.useForm();
    const [processForm] = Form.useForm();


    const {profile} = useContext(ProfileContext);
    const {can, canAny} = useCan(profile);

    const {Option} = Select;

    let mail_filter: any = {
        filter: 'inbox',
        title: 'Inbox'
    };

    if (props.match.params && 'filter' in props.match.params && can('mail.' + props.match.params.filter)) {
        switch (props.match.params.filter) {
            case 'trash':
                mail_filter = {
                    filter: 'trash',
                    title: 'Trash'
                };
                break;
            case 'sent':
                mail_filter = {
                    filter: 'sent',
                    title: 'Sent Items'
                };
                break;
        }
    }

    const filterFormLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const [viewState, setViewState] = useState({
        open: false,
        current: false as any,
        showReply: false,
        showAssign: false,
        assign: false as any,
        showProcess: false,
        bulk: true,
        process: false as any,
        attachments: [] as any,
        sending: false,
        linkModalVisible: false,
        linkedMail: false as any,
        loadingUsers: false,
        users: [] as any
    });

    const [composeState, setComposeState] = useState({
        open: false,
        sending: false,
        attachments: [] as any,
        loadingCases: false,
        cases: [] as any
    });

    const [contactState, setContactState] = useState({
        loading: false,
        contacts: [] as any
    });

    const [forwardState, setForwardState] = useState({
        open: false,
        current: false as any,
        sending: false,
        attachments: [] as any
    });

    const [tableState, setTableState] = useState({
        loading: true,
        filterOpen: false,
        selectedRows: [],
        height: 100,
        pagination: {
            current: 1,
            pageSize: 10,
        },
        sorter: {
            field: 'created_at',
            order: 'descend'
        },
        filterActive: false,
        filters: {
            query: ''
        },
        data: [] as any
    });

    const composeToTagRenderer = (props: any) => {
        const {label, value, closable, onClose} = props;

        return (
            <Tag color={(/.*@.*\..*/.test(value) ? 'green' : 'red')} closable={closable} onClose={onClose}
                 style={{marginRight: 3}}>
                {value}
            </Tag>
        );
    }

    const viewMail = async (id: any, showReply: any = false, showForward: any = false) => {
        setTableState({...tableState, loading: true});

        let response: any;
        try {
            response = await AjaxService.get(`mails/${id}`, {
                include: 'attachments'
            });
            if (response && response.data.locked_by && !response.data.locked_by.me) {
                // If the case is locked by someone else, check if the current user
                // can forcibly unlock this case.
                if (canAny(['mail.unlock'])) {
                    // Notify the user and ask if they want to forcibly unlock the case.
                    confirm({
                        title: 'Mail Locked',
                        content: <div dangerouslySetInnerHTML={{__html:[`This mail is currently locked by ${response.data.locked_by.first_name} ${response.data.locked_by.last_name}.`, `Would you like to take over the mail?`,].join('<br /><br />')}} />,
                        okText: 'Take Over',
                        onOk: async () => {
                            // Lock the case to the current user.
                            let response = await AjaxService.put(`mails/lock/${id}/true`, {});
                            if (response.data.status !== 'success') {
                                notification.error({
                                    message: 'There was an error',
                                    description: response.data.message
                                });
                                setTableState({...tableState, loading: false});
                            } else {
                                // Open the case now it is locked to this user.
                                await viewMail(id, showReply, showForward);
                            }
                        },
                        onCancel: () => {
                            setTableState({...tableState, loading: false});
                        }
                    });
                    return;
                } else {
                    Modal.warn({
                        title: 'Mail Locked',
                        content: `This mail is currently locked by ${response.data.locked_by.first_name} ${response.data.locked_by.last_name}.`
                    });
                    setTableState({...tableState, loading: false});
                    return;
                }
            }

            // If the case is not already locked, lock it.
            if (!response.data.locked_by) {
                let lockResponse = await AjaxService.put(`mails/lock/${id}`, {});
                if (lockResponse.data.status !== 'success') {
                    notification.error({
                        message: 'There was an error',
                        description: lockResponse.data.message
                    });
                    setTableState({...tableState, loading: false});
                    return;
                }
            }
            setViewState({...viewState, current: response.data, open: (viewState.open ? true : !showForward), showReply});
            setForwardState({...forwardState, current: response.data, open: showForward});
            if (showForward) {
                forwardForm.setFieldsValue({
                    subject: `FW: ${response.data.subject.replace(/(re:|fw:)[ ]+/gi, '')}`
                });
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        setTableState({...tableState, loading: false});

        if (response) {
            try {
                await AjaxService.put(`mails/read`, {ids: response.data.id});
                tableState.data.some((mail: any, idx: any) => {
                    if (mail.id === id) {
                        mail.viewed_at = moment().format();
                        tableState.data[idx] = mail;
                        setTableState({...tableState, data: tableState.data});
                        return true;
                    }
                    return false;
                });
            } catch (e: any) {
            }
        }
    }

    const closeMail = async (type: any, params: any) => {

        if (viewState.current && viewState.current.id) {
            try {
                let response = await AjaxService.put(`mails/unlock/${viewState.current.id}`, {});
                if (['success', 'locked'].indexOf(response.data.status) === -1) {
                    notification.error({
                        message: 'There was an error',
                        description: response.data.message
                    });
                }
            } catch (e) {}
        }

        switch (type) {
            case 'view':
                setViewState({...viewState, ...params});
                break;
            case 'composer':
                setComposeState({...composeState, ...params});
                break;
        }
    }

    const fetchData = async (params: any = tableState) => {
        setTableState((previous: any) => {
            return {
                ...previous,
                loading: true
            }
        });

        let request: any = {
            filter: mail_filter.filter
        };

        if ('pagination' in params && Object.keys(params.pagination).length > 0) {
            request.page = params.pagination.current;
            request.limit = params.pagination.pageSize;
        }

        if ('sorter' in params && 'field' in params.sorter) {
            request.sort = (typeof params.sorter.field === 'string' ? params.sorter.field : params.sorter.field.join('.'));
            if (params.sorter.order) {
                request.direction = (params.sorter.order === 'descend' ? 'desc' : 'asc');
            }
        }

        if ('filters' in params && Object.keys(params.filters).length > 0) {
            Object.keys(params.filters).forEach((key: any) => {
                if (params.filters[key]) {
                    request['filter_' + key] = params.filters[key];
                }
            });
        }

        let response: any;
        try {
            response = await AjaxService.get(`mails/`, request);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setTableState((previous: any) => {
            if (!('filters' in params)) {
                params.filters = previous.filters;
            }
            if (!('sorter' in params)) {
                params.sorter = previous.sorter;
            }
            if (!('pagination' in params)) {
                params.pagination = previous.pagination;
            }
            if (!('filterActive' in params)) {
                params.filterActive = previous.filterActive;
            }
            if (!response) {
                return {...previous, loading: false}
            }
            return {
                ...previous,
                ...params,
                loading: false,
                filterOpen: false,
                data: response.data,
                pagination: {
                    ...params.pagination,
                    pageSize: response.meta.per_page,
                    total: response.meta.total
                }
            }
        });
    }

    const resizeTable = () => {
        let wrappers = document.getElementsByClassName('ant-table-wrapper');
        Array.from(wrappers).forEach((wrapper: any) => {
            let newHeight = wrapper.parentNode.clientHeight - 180;
            if (tableState.height !== newHeight) {
                setTableState((previous: any) => {
                    return {...previous, height: newHeight}
                });
            }
        });
    }

    useEffect(() => {
        resizeTable();
    });

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        assignForm.resetFields();
        filterForm.resetFields();
        replyForm.resetFields();
        forwardForm.resetFields();
        composeForm.resetFields();
        fetchData();
        fetchUsers();
        window.addEventListener('resize', resizeTable);
        return () => {
            isCancelled = true;
            window.removeEventListener('resize', resizeTable);
        };
    }, [props]);

    const columns = [{
        title: 'From',
        key: 'from',
        dataIndex: 'from',
        render: (text: any, row: any) => {
            let name = (row.from.name ? row.from.name : row.from.email);
            //email = (row.from.email ? row.from.email : row.from.name);
            return (row.viewed_at ? name : <strong>{name}</strong>);
        }
    }, {
        title: 'Subject',
        key: 'subject',
        dataIndex: 'subject',
        render: (text: any, row: any) => {
            if (row.viewed_at) {
                return <span className="clickable" onClick={() => viewMail(row.id)}>{text}</span>
            }
            return <strong className="clickable" onClick={() => viewMail(row.id)}>{text}</strong>
        }
    }, {
        title: 'Date',
        key: 'date',
        width: 200,
        dataIndex: 'created_at',
        render: (text: any, row: any) => <>
            {row.viewed_at ? moment(text ? text : row.sent_at).format('DD/MM/YYYY HH:mm') :
                <strong>{moment(text ? text : row.sent_at).format('DD/MM/YYYY HH:mm')}</strong>}
            {row.has_attachments && (
                <PaperClipOutlined style={{marginLeft: '.5rem'}}/>
            )}
        </>
    }, {
        key: 'assigned_to',
        dataIndex: 'assigned_to',
        width: 60,
        render: (text: any, row: any) => <>
            {row.assigned_to ? row.assigned_to.initials : ''}
        </>
    }, {
        title: '',
        key: 'actions',
        width: 100,
        align: 'right' as 'right',
        render: (text: any, row: any) => <Dropdown overlay={mailItemMenu(row)} placement="bottomRight">
            <Button icon={<DownOutlined/>}/>
        </Dropdown>
    }];

    const mailItemMenu = (row: any, viewing: any = false) => (
        <Menu>
            {can('mail.link') && (
                <Menu.Item key="link" onClick={() => link(row)} icon={<LinkOutlined/>}>Link</Menu.Item>
            )}
            {!row.is_sent && can('mail.reply') && (
                <Menu.Item key="reply" onClick={() => reply(row, viewing)} icon={<SendOutlined/>}>Reply</Menu.Item>
            )}
            {!row.is_sent && can('mail.forward') && (
                <Menu.Item key="forward" onClick={() => forward(row, viewing)} icon={<ForwardOutlined/>}>Forward</Menu.Item>
            )}
            {!row.is_sent && can('mail.assign') && (
                <Menu.Item key="assign" onClick={() => assign(row)} icon={<UserSwitchOutlined/>}>Assign</Menu.Item>
            )}
            {!row.is_sent && can('mail.process') && (
                <Menu.Item key="process" onClick={() => process(row)} icon={<RocketOutlined/>}>Process</Menu.Item>
            )}
            {row.is_trashed && can('mail.delete') && (
                <Menu.Item key="restore" onClick={() => restoreSingle(row.id)}
                           icon={<UndoOutlined/>}>Restore</Menu.Item>
            )}
            {!row.is_trashed && can('mail.delete') && (
                <Menu.Item key="delete" onClick={() => deleteSingle(row.id)} icon={<DeleteOutlined/>}>Delete</Menu.Item>
            )}
        </Menu>
    );

    const reply = async (mail: any, viewing: any = false) => {
        if (viewing) {
            setViewState({...viewState, current: mail, showReply: true});
        } else {
            viewMail(mail.id, true);
        }
    }

    const assign = async (mail: any) => {
        setViewState({...viewState, assign: mail, showAssign: true});
    }

    const process = async (mail: any) => {
        setViewState({...viewState, process: mail, showProcess: true, bulk: false});
    }

    const forward = async (mail: any, viewing: any = false) => {
        viewMail(mail.id, false, true);
    }

    function discardReply() {
        replyForm.resetFields();
        setViewState({...viewState, showReply: false, attachments: []});
    }

    function discardCompose() {
        composeForm.resetFields();
        setComposeState({...composeState, open: false, attachments: []});
    }

    function discardForward() {
        composeForm.resetFields();
        setForwardState({...forwardState, current: false, open: false, attachments: []});
    }

    const processAttachment = async (file: any) => {
        return new Promise((resolve: any, reject: any) => {
            if (file.size > 52428800) {
                reject('The file has exceeded the 50mb limit.');
            }
            let reader = new FileReader();
            reader.onload = (e: any) => {
                if (!e.target.result) {
                    reject('There was a problem reading this file.');
                }
                resolve({filename: file.originFileObj.name, content: e.target.result});
            }
            reader.onerror = (e: any) => {
                reject(e);
            }
            reader.readAsDataURL(file.originFileObj);
        });
    }

    const sendReply = async (values: any) => {
        if (viewState.attachments.filter((file: any) => file.status === 'error').length) {
            return;
        }
        setViewState({...viewState, sending: true});
        let body: any = {
            thread_id: viewState.current.id,
            ticket_ids: ('case_id' in values ? values.case_id : null),
            body: values.response,
            attachments: []
        };
        try {
            body.attachments = await Promise.all(viewState.attachments.map(processAttachment));
            let response = await AjaxService.post('mails', body);
            if (response) {
                setViewState({...viewState, current: response.data, showReply: false, sending: false, attachments: []});
                replyForm.resetFields();
                notification.success({
                    message: 'Mail Sent',
                    description: 'Your reply has been successfully sent.'
                });
            } else {
                setViewState({...viewState, sending: false});
            }
        } catch (e: any) {
            setViewState({...viewState, sending: false});
            notification.error({
                message: 'Send Mail',
                description: 'There was an error sending your mail, please try again.'
            });
        }
    }

    const sendMail = async (values: any) => {
        if (viewState.attachments.filter((file: any) => file.status === 'error').length) {
            return;
        }
        setComposeState({...composeState, sending: true});
        let body: any = {
            to: values.to,
            subject: values.subject,
            body: values.message,
            ticket_ids: ('case_id' in values ? values.case_id : null),
            attachments: []
        };
        try {
            body.attachments = await Promise.all(composeState.attachments.map(processAttachment));
            let response = await AjaxService.post('mails', body);
            if (response) {
                setComposeState({...composeState, open: false, sending: false, attachments: []});
                composeForm.resetFields();
                notification.success({
                    message: 'Mail Sent',
                    description: 'Your mail has been successfully sent.'
                });
            } else {
                setComposeState({...composeState, sending: false});
            }
        } catch (e: any) {
            setViewState({...viewState, sending: false});
            notification.error({
                message: 'Send Mail',
                description: 'There was an error sending your mail, please try again.'
            });
        }
    }

    const forwardMail = async (values: any) => {
        if (viewState.attachments.filter((file: any) => file.status === 'error').length) {
            return;
        }
        setForwardState({...forwardState, sending: true});
        let body: any = {
            to: values.to,
            subject: values.subject,
            action: 'forward',
            thread_id: forwardState.current.id,
            body: values.message,
            ticket_ids: ('case_id' in values ? values.case_id : null),
            attachments: []
        };
        try {
            body.attachments = await Promise.all(forwardState.attachments.map(processAttachment));
            let response = await AjaxService.post('mails', body);
            if (response) {
                setForwardState({...forwardState, current: false, open: false, sending: false, attachments: []});
                forwardForm.resetFields();
                notification.success({
                    message: 'Mail Forwarded',
                    description: 'Your mail has been successfully forwarded.'
                });
            } else {
                setComposeState({...composeState, sending: false});
            }
        } catch (e: any) {
            setViewState({...viewState, sending: false});
            notification.error({
                message: 'Forward Mail',
                description: 'There was an error forwarding your mail, please try again.'
            });
        }
    }

    const onFilter = (values: any) => {
        fetchData({
            ...tableState,
            filters: {...values},
            pagination: {
                ...tableState.pagination,
                current: 1
            },
            filterActive: true
        });
    }

    const onClearFilter = () => {
        filterForm.resetFields();
        fetchData({
            ...tableState,
            filterActive: false,
            filters: {
                query: ''
            }
        });
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchData({
            ...tableState,
            pagination,
            sorter
        });
    }

    const deleteSelected = () => {
        Modal.confirm({
            title: `Delete Mail`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to delete the selected items?',
            onOk() {
                try {
                    AjaxService.delete(`mails`, {
                        ids: tableState.selectedRows
                    }).then((result: any) => {
                        if (result !== false) {
                            notification.success({
                                message: 'Delete Mail',
                                description: 'The selected items have been successfully deleted.'
                            });
                        }
                        fetchData({...tableState, selectedRows: [], loading: true});
                    });
                } catch (e: any) {
                    notification.error({
                        message: 'Delete Mail',
                        description: 'There was an error sending your mail, please try again.'
                    });
                }
            }
        });
    }

    const restoreSelected = () => {
        Modal.confirm({
            title: `Restore Mail`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to restore the selected items?',
            onOk() {
                AjaxService.put(`mails/restore`, {
                    ids: tableState.selectedRows
                }).then((result) => {
                    if (result !== false) {
                        notification.success({
                            message: 'Restore Mail',
                            description: 'The selected items have been successfully restored.'
                        });
                    }
                    fetchData({...tableState, selectedRows: [], loading: true});
                });
            }
        });
    }

    const deleteSingle = (id: any) => {
        Modal.confirm({
            title: `Delete Mail`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to delete this item?',
            onOk() {
                AjaxService.delete(`mails`, {
                    ids: id
                }).then((result) => {
                    if (result !== false) {
                        notification.success({
                            message: 'Delete Mail',
                            description: 'The mail has been successfully deleted.'
                        });
                    }
                    setViewState({...viewState, current: false, showReply: false, open: false});
                    fetchData({...tableState, selectedRows: [], loading: true});
                });
            },
            onCancel() {
            },
        });
    }

    const restoreSingle = (id: any) => {
        Modal.confirm({
            title: `Restore Mail`,
            icon: <QuestionCircleOutlined/>,
            content: 'Are you sure you want to restore this item?',
            onOk() {
                AjaxService.put(`mails/restore`, {
                    ids: id
                }).then((result) => {
                    if (result !== false) {
                        notification.success({
                            message: 'Restore Mail',
                            description: 'The mail has been successfully restored.'
                        });
                    }
                    setViewState({...viewState, current: false, showReply: false, open: false});
                    fetchData({...tableState, selectedRows: [], loading: true});
                });
            }
        });
    }

    const link = (mail: any) => {
        setViewState({...viewState, linkModalVisible: true, linkedMail: mail})
    }

    const mailLinked = (mailId: any, ticketId: any) => {
        notification.success({
            message: 'Mail Linked',
            description: 'The mail has been successfully linked to the case'
        });
        fetchData();
    }

    const rowSelection: any = {
        type: 'checkbox',
        selectedRowKeys: tableState.selectedRows,
        onChange: (keys: any) => {
            setTableState({...tableState, selectedRows: keys});
        }
    };

    const searchCases = async (search: any) => {
        setComposeState((previous: any) => {
            return {
                ...previous,
                loadingCases: true,
                cases: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`cases`, {
                filter_query: search
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        setComposeState((previous: any) => {
            return {
                ...previous,
                loadingCases: false,
                cases: (response ? response.data : previous.cases)
            };
        });
    }

    const filterSelect = (input: any, option: any) => {
        let value = ('children' in option ? option.children : option.label);
        if (Array.isArray(value)) {
            value = value.join('');
        }
        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const assignMail = (values: any) => {
        let body = {
            mail_id: viewState.assign.id,
            user_id: (values.assigned_to ?? null)
        };
        AjaxService.put(`mails/assign`, body).then((result) => {
            if (result !== false) {
                notification.success({
                    message: 'Mail Assigned',
                    description: 'The mail has been successfully assigned.'
                });
            }
            setViewState({...viewState, current: false, showAssign: false, sending: false});
            fetchData({...tableState, selectedRows: [], loading: true});
        });
    };

    const processMail = (values: any) => {
        let body = {
            case_type: values.case_type,
            mail_ids: (viewState.bulk ? tableState.selectedRows : [viewState.process.id])
        };
        AjaxService.put(`mails/process`, body).then((result) => {
            if (result !== false) {
                notification.success({
                    message: 'Mail Processed',
                    description: 'The mail has been successfully processed.'
                });
                let openTabs = JSON.parse(localStorage.getItem('open-tabs') ?? '[]'),
                    exists = false;

                openTabs.forEach((tab: any) => {
                    if (tab.id == result.data.id) {
                        exists = true;
                    }
                });

                if (!exists) {
                    openTabs.push({
                        id: result.data.id,
                        key: result.data.id,
                        type: values.case_type,
                        checksum: Date.now()
                    });
                    localStorage.setItem('open-tabs', JSON.stringify(openTabs));
                }

                localStorage.setItem('active-tab', '"' + result.data.id + '"');
                history.push('/cases');
            } else {
                notification.error({
                    message: 'There was an error',
                    description: 'Unfortunately we could not load the required information. Please try again.'
                });
            }
        });
    };

    const fetchUsers = async () => {
        try {
            let response = await AjaxService.get(`users/`, null, true);
            setViewState((previous: any) => {
                return {
                    ...previous,
                    loadingUsers: false,
                    users: response.data.map((user: any) => {
                        return {
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    const searchContacts = async (search: any) => {
        if (!search) {
            return;
        }
        setContactState((previous: any) => {
            return {
                ...previous,
                loading: true,
                contacts: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`contacts`, {filter_query: search, filter_email: true}, true);
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setContactState((previous: any) => {
            return {
                ...previous,
                loading: false,
                contacts: (response ? response.data : previous.contacts)
            };
        });
    }

    return (
        <Master className={`Mail Inbox`}>
            <Drawer
                title="Filter Mail"
                visible={tableState.filterOpen}
                closable={true}
                destroyOnClose
                forceRender
                onClose={() => setTableState({...tableState, filterOpen: false})}
                footer={
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Button onClick={() => onClearFilter()} type="text" danger={true}>
                            Clear Filter
                        </Button>
                        <Button onClick={() => setTableState({...tableState, filterOpen: false})}
                                style={{marginRight: 8, marginLeft: 'auto'}}>
                            Close
                        </Button>
                        <Button onClick={() => filterForm.submit()} type="primary">
                            Apply Filters
                        </Button>
                    </div>
                }
            >
                <Form
                    {...filterFormLayout}
                    form={filterForm}
                    onFinish={onFilter}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Search"
                                name="query"
                            >
                                <Input placeholder="Search" value={tableState.filters.query}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
            <Drawer
                title={viewState.current && (
                    <div className="MailHeader">
                        <strong>Subject:</strong> {viewState.current.subject}<br/>
                        <strong>From:</strong> {viewState.current.from.name ? (viewState.current.from.email ? `${viewState.current.from.name} <${viewState.current.from.email}>` : viewState.current.from.name) : viewState.current.from.email}<br/>
                        <strong>To:</strong> {viewState.current.to.name ? (viewState.current.to.email ? `${viewState.current.to.name} <${viewState.current.to.email}>` : viewState.current.to.name) : viewState.current.to.email}<br/>
                        <strong>Date:</strong> {moment(viewState.current.sent_at).format('DD/MM/YYYY HH:mm')}
                        {viewState.current.has_attachments && (
                            <>
                                <br/>
                                <strong>Attachments: </strong> {viewState.current.attachments.map((attachment: any, idx: any) => {
                                return (
                                    <React.Fragment key={idx}>{(idx > 0 ? ', ' : '')}<a
                                        href={attachment.url}>{attachment.name}</a></React.Fragment>
                                );
                            })}
                            </>
                        )}
                    </div>
                )}
                className="MailDrawer"
                visible={viewState.open}
                closable={true}
                destroyOnClose
                forceRender
                onClose={() => closeMail('view', {current: false, showReply: false, open: false})}
                footer={
                    <Spin spinning={viewState.sending}>
                        {can('mail.reply') && (
                            <div className={`MailReplyForm ${viewState.showReply ? 'Open' : ''}`}>
                                <Form
                                    {...filterFormLayout}
                                    form={replyForm}
                                    onFinish={sendReply}
                                >
                                    <Form.Item
                                        name="response"
                                        rules={[{required: true, message: 'Please enter your reply!'}]}
                                    >
                                        <Input.TextArea rows={8} placeholder="Reply"/>
                                    </Form.Item>
                                    <Upload onChange={(info: any) => setViewState({
                                        ...viewState,
                                        attachments: info.fileList
                                    })} fileList={viewState.attachments}></Upload>
                                    <div className="MailViewButtons">
                                        <Button htmlType="button" onClick={discardReply} style={{marginRight: 'auto'}}>
                                            Discard
                                        </Button>
                                        <Tooltip title="Upload Attachments" placement="topLeft">
                                            <Upload showUploadList={false} beforeUpload={() => false}
                                                    onChange={(info: any) => setViewState({
                                                        ...viewState,
                                                        attachments: info.fileList
                                                    })}>
                                                <Button htmlType="button" icon={<PaperClipOutlined/>}/>
                                            </Upload>
                                        </Tooltip>
                                        <Button onClick={() => replyForm.submit()} type="primary" icon={<SendOutlined/>}
                                                style={{marginLeft: '.5rem'}}>
                                            Send
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        )}
                        <div className={`MailViewButtons ${viewState.showReply ? 'Hide' : ''}`}>
                            {canAny(['mail.link', 'mail.reply', 'mail.forward', 'mail.delete']) && (
                                <Dropdown overlay={mailItemMenu(viewState.current, true)} placement="topLeft">
                                    <Button icon={<UpOutlined/>}/>
                                </Dropdown>
                            )}
                            <Button onClick={() => closeMail('view', {current: false, showReply: false, open: false})}
                                    style={{marginLeft: 'auto'}}>
                                Close
                            </Button>
                        </div>
                    </Spin>
                }
            >
                {viewState.current && (
                    <>
                        {viewState.current.body.split('\n').map((chunk: any, key: any) => {
                            return (
                                <React.Fragment key={key}>
                                    {chunk}
                                    <br/>
                                </React.Fragment>
                            );
                        })}
                    </>
                )}
            </Drawer>
            {can('mail.compose') && (
                <Drawer
                    title="Compose"
                    className="MailDrawer"
                    visible={composeState.open}
                    closable={true}
                    destroyOnClose
                    forceRender
                    onClose={() => closeMail('compose', {open: false, sending: false})}
                    footer={
                        <>
                            <div className="MailViewButtons">
                                <Button htmlType="button" onClick={discardCompose} style={{marginRight: 'auto'}}>
                                    Discard
                                </Button>
                                <Tooltip title="Upload Attachments" placement="topLeft">
                                    <Upload showUploadList={false} beforeUpload={() => false}
                                            onChange={(info: any) => setComposeState({
                                                ...composeState,
                                                attachments: info.fileList
                                            })}>
                                        <Button htmlType="button" icon={<PaperClipOutlined/>}/>
                                    </Upload>
                                </Tooltip>
                                <Button onClick={() => composeForm.submit()} type="primary" icon={<SendOutlined/>}
                                        style={{marginLeft: '.5rem'}}>
                                    Send
                                </Button>
                            </div>
                        </>
                    }
                >
                    <Form
                        labelCol={{span: 3}}
                        wrapperCol={{span: 21}}
                        form={composeForm}
                        onFinish={sendMail}
                        className="MailComposeForm"
                    >
                        <Form.Item
                            label="To"
                            name="to"
                            rules={[{
                                required: true,
                                min: 1,
                                type: 'array',
                                message: 'Please enter at least one valid email address!',
                                defaultField: {type: 'email', message: 'Invalid email address'},
                            }]}
                        >
                            <Select
                                mode="tags"
                                placeholder="To"
                                showSearch
                                onSearch={debounce(searchContacts, 300)}
                                filterOption={false}
                                notFoundContent={contactState.loading ? <Spin size="small"/> : null}
                                loading={contactState.loading}
                                tagRender={composeToTagRenderer}
                            >
                                {contactState.contacts.map((contact: any) => {
                                    return (
                                        <Option key={contact.id.toString()} value={contact.email}>
                                            <span>{contact.first_name} {contact.last_name}</span>
                                            <small style={{display: 'block'}}>{contact.email}</small>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[{required: true, message: 'Please enter a subject!'}]}
                        >
                            <Input placeholder="Subject"/>
                        </Form.Item>
                        <Form.Item
                          label="Case"
                          name="case_id"
                        >
                            <Select
                              placeholder="Search for a case"
                              showSearch
                              onSearch={searchCases}
                              filterOption={false}
                              allowClear={true}
                              notFoundContent={composeState.loadingCases ? <Spin size="small"/> : null}
                              loading={composeState.loadingCases}
                              mode="multiple"
                            >
                                {composeState.cases.map((item: any) => (
                                  <Select.Option key={item.id} value={item.id}>
                                            <span
                                              className={`TrafficLight Is-${item.traffic_light}`}/> {item.reference ?? '#' + item.id} {item.contact && (
                                    <>- {item.contact.first_name} {item.contact.last_name}</>
                                  )}
                                      <small
                                        style={{display: 'block'}}>{item.operator && item.operator.title}</small>
                                  </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Message"
                            name="message"
                            className="FullHeight"
                            rules={[{required: true, message: 'Please enter a message!'}]}
                        >
                            <Input.TextArea rows={8} placeholder="Message"/>
                        </Form.Item>
                        {composeState.attachments.length > 0 && (
                            <Form.Item label="Attachments">
                                <Upload onChange={(info: any) => setComposeState({
                                    ...composeState,
                                    attachments: info.fileList
                                })} fileList={composeState.attachments}></Upload>
                            </Form.Item>
                        )}
                    </Form>
                </Drawer>
            )}
            {can('mail.forward') && (
                <Drawer
                    title="Forward"
                    className="MailDrawer"
                    visible={forwardState.open}
                    closable={true}
                    destroyOnClose
                    forceRender
                    onClose={() => setForwardState({...forwardState, current: false, open: false, sending: false})}
                    footer={
                        <>
                            <div className="MailViewButtons">
                                <Button htmlType="button" onClick={discardForward} style={{marginRight: 'auto'}}>
                                    Discard
                                </Button>
                                <Tooltip title="Upload Attachments" placement="topLeft">
                                    <Upload showUploadList={false} beforeUpload={() => false}
                                            onChange={(info: any) => setForwardState({
                                                ...forwardState,
                                                attachments: info.fileList
                                            })}>
                                        <Button htmlType="button" icon={<PaperClipOutlined/>}/>
                                    </Upload>
                                </Tooltip>
                                <Button onClick={() => forwardForm.submit()} type="primary" icon={<SendOutlined/>}
                                        style={{marginLeft: '.5rem'}}>
                                    Send
                                </Button>
                            </div>
                        </>
                    }
                >
                    <Form
                        labelCol={{span: 4}}
                        wrapperCol={{span: 20}}
                        form={forwardForm}
                        onFinish={forwardMail}
                        className="MailForwardForm"
                        initialValues={{
                            subject: (forwardState.current ? `FW: ${forwardState.current?.subject?.replace(/re:/gi, '')}` : '')
                        }}
                    >
                        <Form.Item
                            label="To"
                            name="to"
                            rules={[{
                                required: true,
                                min: 1,
                                type: 'array',
                                message: 'Please enter at least one valid email address!',
                                defaultField: {type: 'email', message: 'Invalid email address'},
                            }]}
                        >
                            <Select
                                mode="tags"
                                placeholder="To"
                                showSearch
                                onSearch={debounce(searchContacts, 300)}
                                filterOption={false}
                                notFoundContent={contactState.loading ? <Spin size="small"/> : null}
                                loading={contactState.loading}
                                tagRender={composeToTagRenderer}
                            >
                                {contactState.contacts.map((contact: any) => {
                                    return (
                                        <Option key={contact.id.toString()} value={contact.email}>
                                            <span>{contact.first_name} {contact.last_name}</span>
                                            <small style={{display: 'block'}}>{contact.email}</small>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[{required: true, message: 'Please enter a subject!'}]}
                        >
                            <Input placeholder="Subject"/>
                        </Form.Item>
                        <Form.Item
                            label={<>
                                <Tooltip title="The mail being forwarded will be added to the bottom of your message.">
                                    <QuestionCircleOutlined style={{marginRight: '.25rem'}}/>
                                </Tooltip>
                                Message
                            </>}
                            name="message"
                            rules={[{required: true, message: 'Please enter a message!'}]}
                        >
                            <Input.TextArea rows={8} placeholder="Message"/>
                        </Form.Item>
                        {forwardState.attachments.length > 0 && (
                            <Form.Item label="Attachments">
                                <Upload onChange={(info: any) => setForwardState({
                                    ...forwardState,
                                    attachments: info.fileList
                                })} fileList={forwardState.attachments}></Upload>
                            </Form.Item>
                        )}
                    </Form>
                </Drawer>
            )}
            <div className="CaseTableActions">
                <span className="CaseTableTitle">{mail_filter.title}</span>
                {can('mail.compose') && (
                    <Button onClick={() => setComposeState({...composeState, open: true})}>Compose</Button>
                )}
                {can('mail.process') && (
                    <Button type="primary" id="ProcessMail" disabled={!tableState.selectedRows || tableState.selectedRows.length === 0} onClick={() => setViewState({...viewState, showProcess: true, bulk: true })}
                            style={{marginLeft: '.5rem'}}>Process</Button>
                )}
                {can('mail.delete') && mail_filter.filter !== 'trash' && tableState.selectedRows && tableState.selectedRows.length > 0 && (
                    <Tooltip title="Delete Selected Items" placement="left">
                        <Button
                            danger
                            icon={<DeleteOutlined/>}
                            onClick={deleteSelected}
                            style={{marginLeft: '.5rem'}}
                        />
                    </Tooltip>
                )}
                {can('mail.link') && mail_filter.filter !== 'trash' && tableState.selectedRows && tableState.selectedRows.length > 0 && (
                    <Tooltip title="Link Selected Items" placement="left">
                        <Button
                            icon={<LinkOutlined/>}
                            onClick={() => link(tableState.selectedRows.map((_id: any) => {
                                return tableState.data.find((item: any) => item.id == _id);
                            }))}
                            style={{marginLeft: '.5rem'}}
                        />
                    </Tooltip>
                )}
                {can('mail.delete') && mail_filter.filter === 'trash' && tableState.selectedRows && tableState.selectedRows.length > 0 && (
                    <Tooltip title="Restore Selected Items" placement="left">
                        <Button
                            icon={<UndoOutlined/>}
                            onClick={restoreSelected}
                            style={{marginLeft: '.5rem'}}
                        />
                    </Tooltip>
                )}
                <Tooltip title="Refresh Mail" placement="left">
                    <Button
                        icon={<SyncOutlined/>}
                        onClick={() => fetchData()}
                        style={{marginLeft: '.5rem'}}
                    />
                </Tooltip>
                <Tooltip title="Filter Mail" placement="left">
                    <Button
                        icon={(tableState.filterActive ? <FilterTwoTone twoToneColor="#52c41a"/> : <FilterOutlined/>)}
                        onClick={() => setTableState({...tableState, filterOpen: true})}
                        style={{marginLeft: '.5rem'}}
                    />
                </Tooltip>
            </div>
            <Table
                className="MailTable"
                columns={columns}
                dataSource={tableState.data}
                loading={tableState.loading}
                rowKey="id"
                onChange={handleTableChange}
                pagination={tableState.pagination}
                rowSelection={rowSelection}
                scroll={{y:tableState.height}}
                rowClassName={(row: any, idx: any) => (row.assigned_to && row.assigned_to.id === profile.id ? 'assigned-to-user' : '') + ' idx-' + (idx % 2)}
            />
            {can('mail.link') && viewState.linkModalVisible && (
                <LinkModal onLink={mailLinked} mail={viewState.linkedMail}
                           onClose={() => setViewState({...viewState, linkModalVisible: false})}/>
            )}
            {can('mail.assign') && (
                <Modal
                    title="Assign Mail"
                    destroyOnClose
                    visible={viewState.showAssign}
                    onOk={() => assignForm.submit()}
                    onCancel={() => {
                        setViewState({...viewState, showAssign: false, sending: false});
                    }}
                    confirmLoading={viewState.sending}
                >
                    <Form
                        {...layout}
                        form={assignForm}
                        preserve={false}
                        onFinish={assignMail}
                        initialValues={{
                            assigned_to: (viewState.assign.assigned_to ? viewState.assign.assigned_to.id : '')
                        }}
                    >

                        <Form.Item
                            label="Assigned To"
                            name="assigned_to"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Search for a user"
                                filterOption={filterSelect}
                                loading={viewState.loadingUsers}
                                options={viewState.users}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            )}
            {can('mail.process') && (
                <Modal
                    title="Process Mail"
                    destroyOnClose
                    visible={viewState.showProcess}
                    onOk={() => processForm.submit()}
                    onCancel={() => {
                        setViewState({...viewState, showProcess: false, bulk: false, sending: false});
                    }}
                    confirmLoading={viewState.sending}
                >
                    <Form
                        {...layout}
                        form={processForm}
                        preserve={false}
                        onFinish={processMail}
                    >
                        {tableState.selectedRows && tableState.selectedRows.length > 0 && (
                            <p><strong>{ tableState.selectedRows.length }</strong> mail selected for processing.</p>
                        )}

                        <Form.Item
                            label="Case Type"
                            name="case_type"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Search for a case type"
                                filterOption={filterSelect}
                            >
                                <Select.Option key="bab" value="bab">BAB</Select.Option>
                                <Select.Option key="complaint" value="complaint">Complaint</Select.Option>
                                <Select.Option key="enquiry" value="enquiry">Enquiry</Select.Option>
                                <Select.Option key="feedback" value="feedback">Feedback</Select.Option>
                                <Select.Option key="ybm" value="ybm">YBM</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </Master>
    );
}

export default List;
