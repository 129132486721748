import React, {useState,useEffect} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import {PrivateRoute} from '../Routes';
import Dashboard from '../../Pages/Dashboard';
import ReportsList from '../../Pages/Reporting/List';
import AllCases from '../../Pages/Cases/All';
import MailList from '../../Pages/Mail/List';
import ContactList from '../../Pages/Contacts/List';
import UserList from '../../Pages/Users/List';
import RoleList from '../../Pages/Roles/List';
import AuditTrailList from '../../Pages/AuditTrail/List';
import LocationList from '../../Pages/Settings/Location/List';
import CaseStatusList from '../../Pages/Settings/CaseStatus/List';
import RegionList from '../../Pages/Settings/Region/List';
import OperatorList from '../../Pages/Settings/Operator/List';
import CaseTypeList from '../../Pages/Settings/CaseType/List';
import Login from '../../Pages/Auth/Login';
import {ProfileContext, useCan} from '../../Contexts/ProfileContext';
import './index.less';
import AjaxService from "../../Services/AjaxService";

function App() {
    // Maintain the current users state.
    const [profile, updateProfile] = useState(false as any);
    const {can, canAny} = useCan(profile);

    const getProfile = async () => {
        const profile = await AjaxService.get('users/profile');
        updateProfile(profile.data);
    }

    useEffect(() => {
        if (!profile && localStorage.getItem('auth_token')) {
            getProfile();
        }
    }, []);

    return (
        <ProfileContext.Provider value={{profile, updateProfile}}>
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <PrivateRoute isAuthenticated={true} component={Dashboard} path="/" exact/>
                        <PrivateRoute isAuthenticated={true} component={AllCases} path="/cases/:filter?"/>
                        {canAny(['mail.inbox', 'mail.sent', 'mail.trash']) && (
                            <PrivateRoute isAuthenticated={true} component={MailList} path="/mail/:filter?"/>
                        )}
                        {can('contacts.view') && (
                            <PrivateRoute isAuthenticated={true} component={ContactList} path="/contacts/:filter?"/>
                            // <PrivateRoute isAuthenticated={true} component={ContactList} path="/address-book/"/>
                        )}
                        {can('reporting') && (
                            <PrivateRoute isAuthenticated={true} component={ReportsList} path="/reporting/:report?"/>
                        )}
                        {can('audit_trail') && (
                            <PrivateRoute isAuthenticated={true} component={AuditTrailList} path="/users/audit-trail"/>
                        )}
                        {canAny(['roles.add', 'roles.edit']) && (
                            <PrivateRoute isAuthenticated={true} component={RoleList} path="/users/roles"/>
                        )}
                        {canAny(['users.add', 'users.edit']) && (
                            <PrivateRoute isAuthenticated={true} component={UserList} path="/users"/>
                        )}
                        {canAny(['settings.case_statuses.add', 'settings.case_statuses.edit']) && (
                            <PrivateRoute isAuthenticated={true} component={CaseStatusList} path="/settings/case-statuses"/>
                        )}
                        {canAny(['settings.locations.add', 'settings.locations.edit']) && (
                            <PrivateRoute isAuthenticated={true} component={LocationList} path="/settings/locations"/>
                        )}
                        {canAny(['settings.regions.add', 'settings.regions.edit']) && (
                            <PrivateRoute isAuthenticated={true} component={RegionList} path="/settings/regions"/>
                        )}
                        {canAny(['settings.operators.add', 'settings.operators.edit']) && (
                            <PrivateRoute isAuthenticated={true} component={OperatorList} path="/settings/operators"/>
                        )}
                        {canAny(['settings.case_types.add', 'settings.case_types.edit']) && (
                            <PrivateRoute isAuthenticated={true} component={CaseTypeList} path="/settings/case-types"/>
                        )}
                        <Route component={Login} path="/login" exact/>
                        <Redirect to='/' />
                    </Switch>
                </BrowserRouter>
            </div>
        </ProfileContext.Provider>
    );
}

export default App;
