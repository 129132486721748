import React, {useState} from 'react';
import {Alert, Col, Form, Modal, notification, Row, Select, Spin} from 'antd';
import './index.less';
import AjaxService from "../../Services/AjaxService";

function LinkModal(props: any) {

    const [form] = Form.useForm();
    const {Option} = Select;

    const [modalState, setModalState] = useState({
        loading: false,
        cases: [] as any,
        loadingCases: false,
        error: ''
    });

    const searchCases = async (search: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                loadingCases: true,
                cases: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`cases`, {
                filter_query: search
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        setModalState((previous: any) => {
            return {
                ...previous,
                loadingCases: false,
                cases: (response ? response.data : previous.cases)
            };
        });
    }

    const linkItem = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            var ids = ('id' in props.mail ? [props.mail.id] : props.mail.reduce((acc: any, item: any) => { acc.push(item.id); return acc; }, []));
            await AjaxService.post('mails/link/' + ids.join(','), values);
            ids.forEach((id: any) => {
                props.onLink(id, values.case_id);
            });
            closeModal();
        } catch (e: any) {
            notification.error({
                message: 'Link Response',
                description: 'There was an issue linking the response. Please try again.'
            });
            setModalState((previous: any) => {
                return {
                    ...previous,
                    loading: false
                };
            });
        }
    }

    const closeModal = async () => {
        setModalState((previous: any) => {
            return {
                ...previous,
                loading: false
            };
        });
        form.resetFields();
        props.onClose();
    }

    return (
        <>
            <Modal
                visible
                title="Link to Case"
                onOk={() => form.submit()}
                onCancel={closeModal}
                destroyOnClose
                forceRender
                confirmLoading={modalState.loading}
            >
                <Form
                    form={form}
                    onFinish={linkItem}
                    layout="vertical"
                >
                    {modalState.error !== '' && (
                        <Alert style={{marginBottom: '1rem'}} message={modalState.error} type="error" showIcon/>
                    )}
                    {props.mail && 'id' in props.mail && props.mail.is_linked && (
                        <Alert style={{marginBottom: '1rem'}}
                               message="This has already been linked to one or more cases." type="info" showIcon/>
                    )}
                    {Array.isArray(props.mail) && props.mail.reduce((acc: any, item: any) => { if (item.is_linked) { acc = true; } return acc; }, false) && (
                        <Alert style={{marginBottom: '1rem'}}
                               message="One or more items have already been linked to one or more cases." type="info" showIcon/>
                    )}
                    {Array.isArray(props.mail) && (
                        <p>Linking <strong>{props.mail.length}</strong> email{props.mail.length === 1 ? '' : 's'} to the selected case.</p>
                    )}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Case"
                                name="case_id"
                                rules={[{required: true, message: 'Please select a case!'}]}
                            >
                                <Select
                                    placeholder="Search for a case"
                                    showSearch
                                    onSearch={searchCases}
                                    filterOption={false}
                                    notFoundContent={modalState.loadingCases ? <Spin size="small"/> : null}
                                    loading={modalState.loadingCases}
                                >
                                    {modalState.cases.map((item: any) => (
                                        <Option key={item.id} value={item.id}>
                                            <span
                                                className={`TrafficLight Is-${item.traffic_light}`}/> {item.reference ?? '#' + item.id} {item.contact && (
                                            <>- {item.contact.first_name} {item.contact.last_name}</>
                                        )}
                                            <small
                                                style={{display: 'block'}}>{item.operator && item.operator.title}</small>
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default LinkModal;