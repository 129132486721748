import React, {useEffect, useImperativeHandle, useState} from 'react';
import {
    Button,
    Cascader,
    Col,
    DatePicker,
    Form,
    Input,
    notification,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    Tooltip
} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import moment from 'moment';
import OperatorModal from '../../../../Components/OperatorModal';
import './index.less';
import AjaxService from "../../../../Services/AjaxService";
import LocationModal from "../../../../Components/LocationModal";
import {CascaderOptionType, FilledFieldNamesType} from "antd/lib/cascader";
import { debounce } from 'lodash';

const YBMForm = React.forwardRef((props: any, ref: any) => {

    let isCancelled: any = false;

    const [form] = Form.useForm();

    const [formState, setFormState] = useState({
        ticket: false as any,
        ticketLoading: (props.action === 'edit'),
        locations: [] as any,
        loadingLocations: true,
        locationModalVisible: false as any,
        operators: [] as any,
        loadingOperators: true,
        operatorModalVisible: false,
        caseStatuses: [] as any,
        loadingCaseStatuses: true,
        caseStatusSearch: '',
        users: [] as any,
        loadingUsers: true
    });

    const locationAdded = (location: any) => {
        formState.locations.forEach((tmp: any, idx: any) => {
            if (tmp.value == location.region_id) {
                formState.locations[idx].children.push({
                    label: location.title,
                    value: location.id,
                    children: []
                });
            }
        });
        setFormState({
            ...formState,
            loadingLocations: true,
            locations: [
                ...formState.locations
            ],
        });
        form.setFieldsValue({
            location_id: (location ? [location.region_id, location.id] : [])
        });
        fetchLocationTree((location ? [location.region_id, location.id] : []));
        notification.success({
            message: 'Add Location',
            description: `The location was successfully created.`
        });
    }

    const operatorAdded = (operator: any) => {
        formState.operators.push({
            label: operator.title,
            value: operator.id
        });
        setFormState({
            ...formState,
            operators: [
                ...formState.operators
            ],
            operatorModalVisible: false
        });
        form.setFieldsValue({
            operator_id: operator.id
        });
        notification.success({
            message: 'Add Operator',
            description: `The operator was successfully created..`
        });
    }

    const fetchOperators = async () => {
        try {
            let response = await AjaxService.get(`operators/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingOperators: false,
                    operators: response.data.map((operator: any) => {
                        return {
                            label: operator.title,
                            value: operator.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCaseStatuses = async () => {
        try {
            let response = await AjaxService.get(`case-statuses/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingCaseStatuses: false,
                    caseStatuses: response.data.map((status: any) => {
                        return {
                            label: status.title,
                            value: status.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchUsers = async () => {
        try {
            let response = await AjaxService.get(`users/`, null, true);
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loadingUsers: false,
                    users: response.data.map((user: any) => {
                        return {
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id
                        }
                    })
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchCase = async () => {
        let response: any;
        try {
            console.log('YBM/Form: fetching data...');
            response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta'], 'initial_response': true});
            form.setFieldsValue({
                operator_id: response.data.operator?.id,
                // operator: response.data.operator,
                status_id: response.data.status?.id,
                assigned_to: response.data.assigned_to?.id,
                location_id: (response.data.location ? [response.data.location.region_id, response.data.location.id] : null),
                // location: response.data.location,
                'meta:Date': (response.data.meta['Date'] ? moment(response.data.meta['Date']) : null),
                'meta:Date Confirmed': (response.data.meta['Date Confirmed'] && parseInt(response.data.meta['Date Confirmed'], 10) === 1),
                'meta:Bus Provider/Sponsor': response.data.meta['Bus Provider/Sponsor'],
                'meta:BUUK Attendees': (response.data.meta['BUUK Attendees'] ? response.data.meta['BUUK Attendees'] : []),
                'meta:Councils/Bus Ops Invited': response.data.meta['Councils/Bus Ops Invited'],
                'meta:Local Reps Invited': response.data.meta['Local Reps Invited'],
                'meta:Councils Attending': response.data.meta['Councils Attending'],
                'meta:Operators Attending': response.data.meta['Operators Attending'],
                'meta:Passenger Focus': parseInt(response.data.meta['Passenger Focus'], 10),
                'meta:Poster': response.data.meta['Poster'],
                'meta:Pre Press Release': response.data.meta['Pre Press Release'],
                'meta:Post Press Release': response.data.meta['Post Press Release'],
                'meta:Forms in Shepperton/Cardiff': parseInt(response.data.meta['Forms in Shepperton/Cardiff'], 10),
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
        setFormState((previous: any) => {
            return {
                ...previous,
                ticket: (response ? response.data : previous.ticket),
                ticketLoading: false
            }
        });
    }

    const fetchLocationTree = async (selected_id: any = null) => {
        setFormState((previous: any) => {
            return {
                ...previous,
                loadingLocations: true,
                locations: []
            }
        });

        let response: any;
        try {
            response = await AjaxService.get(`locations/tree`, {});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }

        if (isCancelled) {
            return;
        }

        setFormState((previous: any) => {
            return {
                ...previous,
                loadingLocations: false,
                locations: (response ? response : previous.locations)
            };
        });
        if (response && selected_id) {
            form.setFieldsValue({
                location_id: selected_id
            });
        }
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        form.resetFields();
        if (props.action === 'edit') {
            fetchCase();
        }
        fetchOperators();
        fetchCaseStatuses();
        fetchLocationTree();
        fetchUsers();
        return () => {
            isCancelled = true;
        };
    }, []);

    const filterSelect = (input: any, option: any) => {
        let value = ('children' in option ? option.children : option.label);
        if (Array.isArray(value)) {
            value = value.join('');
        }
        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const onSubmit = async (values: any) => {

        try {
            let body = JSON.parse(JSON.stringify(values));
            if (body.location_id) {
                body.location_id = body.location_id.pop();
            }
            body.type = 'ybm';

            let response;
            if (props.action === 'edit') {
                response = await AjaxService.put(`cases/${props.id}`, body);
            } else {
                response = await AjaxService.post('cases', body);
            }
            props.onAdd(response.data.id, (response.data.reference ? response.data.reference : response.data.id), response.data.type.value, props.action);
        } catch (e: any) {
            notification.error({
                message: (props.action === 'edit' ? 'Edit' : 'Add') + ' YBM',
                description: 'There was an issue ' + (props.action === 'edit' ? 'updat' : 'creat') + 'ing the case. Please try again.'
            });
            setFormState((previous: any) => {
                return {
                    ...previous,
                    loading: false
                };
            });
        }

    }

    useImperativeHandle(ref, () => ({
        submitForm() {
            form.submit();
        }
    }));

    return (
        <Spin spinning={formState.ticketLoading}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onSubmit}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-required">Date</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="meta:Date"
                                rules={[{required: false, message: 'Please specify a date!'}]}
                            >
                                <DatePicker style={{width: '100%'}}/>
                            </Form.Item>
                            <Form.Item
                                name="meta:Date Confirmed"
                                valuePropName="checked"
                            >
                                <Switch style={{marginLeft: '1rem'}} checkedChildren="Confirmed"
                                        unCheckedChildren="Unconfirmed"/>
                            </Form.Item>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                         {/* <Form.Item
                          label="Location"
                          name="location"
                          rules={[{required: false, message: 'Please enter the contact!'}]}
                        >
                            <Input placeholder="Location"/>
                        </Form.Item>  */}
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-required">Location</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="location_id"
                                rules={[{required: false, message: 'Please select a location or create a new one!'}]}
                                style={{width: "calc(100% - 32px)"}}
                            >
                                <Cascader
                                    disabled={formState.loadingLocations}
                                    options={formState.locations}
                                    style={{width: '100%'}}
                                    showSearch={{
                                        filter: (inputValue, path) => {
                                            return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                                        }
                                    }}
                                    placeholder="Search for a location"
                                />
                            </Form.Item>
                            <Tooltip title="Add Location">
                                <Button disabled={formState.loadingLocations} icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, locationModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                         {/* <Form.Item
                          label="Operator"
                          name="operator"
                          rules={[{required: false, message: 'Please enter the contact!'}]}
                        >
                            <Input placeholder="Operator"/>
                        </Form.Item>  */}
                        <div className="ant-form-item-label">
                            <label className="ant-form-item-required">Operator</label>
                        </div>
                        <Input.Group compact>
                            <Form.Item
                                name="operator_id"
                                rules={[{required: false, message: 'Please select a operator or create a new one!'}]}
                                style={{width: "calc(100% - 32px)"}}
                            >
                                <Select
                                    placeholder="Search for a operator"
                                    style={{width: '100%'}}
                                    showSearch
                                    filterOption={filterSelect}
                                    loading={formState.loadingOperators}
                                    options={formState.operators}
                                />
                            </Form.Item>
                            <Tooltip title="Add Operator">
                                <Button icon={<PlusOutlined/>}
                                        onClick={() => setFormState({...formState, operatorModalVisible: true})}/>
                            </Tooltip>
                        </Input.Group>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Bus Provider/Sponsor"
                            name="meta:Bus Provider/Sponsor"
                            rules={[{required: false, message: 'Please specify the Bus Provider/Sponsor!'}]}
                        >
                            <Input placeholder="BUUK Organiser"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="BUUK Attendees"
                            name="meta:BUUK Attendees"
                            rules={[{required: false, message: 'Please enter one or more attendees!'}]}
                        >
                            <Select
                                mode="tags"
                                maxTagCount={3}
                                placeholder="BUUK Attendees"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Councils/Bus Ops Invited"
                            name="meta:Councils/Bus Ops Invited"
                        >
                            <Input placeholder="Councils/Bus Ops Invited"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Local Reps Invited"
                            name="meta:Local Reps Invited"
                        >
                            <Input placeholder="Local Reps Invited"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Councils Attending"
                            name="meta:Councils Attending"
                        >
                            <Input placeholder="Councils Attending"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Operators Attending"
                            name="meta:Operators Attending"
                        >
                            <Input placeholder="Operators Attending"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Passenger Focus"
                            name="meta:Passenger Focus"
                            rules={[{required: false, message: 'Please select a value!'}]}
                            initialValue={0}
                        >
                            <Radio.Group
                                options={[
                                    {label: 'Yes', value: 1},
                                    {label: 'No', value: 0}
                                ]}
                                optionType="button"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Poster"
                            name="meta:Poster"
                            rules={[{required: false, message: 'Please select a value!'}]}
                            initialValue={'N/A'}
                        >
                            <Radio.Group
                                options={[
                                    {label: 'N/A', value: 'N/A'},
                                    {label: 'Drafted', value: 'Drafted'},
                                    {label: 'Sent', value: 'Sent'}
                                ]}
                                optionType="button"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Pre Press Release"
                            name="meta:Pre Press Release"
                            rules={[{required: false, message: 'Please select a value!'}]}
                            initialValue={'N/A'}
                        >
                            <Radio.Group
                                options={[
                                    {label: 'N/A', value: 'N/A'},
                                    {label: 'Drafted', value: 'Drafted'},
                                    {label: 'Sent', value: 'Sent'}
                                ]}
                                optionType="button"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Post Press Release"
                            name="meta:Post Press Release"
                            rules={[{required: false, message: 'Please select a value!'}]}
                            initialValue={'N/A'}
                        >
                            <Radio.Group
                                options={[
                                    {label: 'N/A', value: 'N/A'},
                                    {label: 'Drafted', value: 'Drafted'},
                                    {label: 'Sent', value: 'Sent'}
                                ]}
                                optionType="button"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Forms in Shepperton/Cardiff"
                            name="meta:Forms in Shepperton/Cardiff"
                            rules={[{required: false, message: 'Please select a value!'}]}
                            initialValue={0}
                        >
                            <Radio.Group
                                options={[
                                    {label: 'Yes', value: 1},
                                    {label: 'No', value: 0}
                                ]}
                                optionType="button"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Status"
                            name="status_id"
                            initialValue={1}
                            rules={[{required: true, message: 'Please select a status or create a new one!'}]}
                        >
                            <Select
                                style={{width: '100%'}}
                                showSearch
                                onSearch={(value: any) => setFormState({...formState, caseStatusSearch: value})}
                                filterOption={filterSelect}
                                loading={formState.loadingCaseStatuses}
                                onChange={(value: any, option: any) => {
                                    if (option.key === 'custom') {
                                        setFormState({
                                            ...formState,
                                            caseStatusSearch: '',
                                            caseStatuses: [
                                                ...formState.caseStatuses, {
                                                    value: value,
                                                    label: value
                                                }
                                            ]
                                        });
                                        form.setFieldsValue({
                                            'status_id': value
                                        });
                                    }
                                }}
                                placeholder="Search for a status"
                            >
                                {formState.caseStatuses && formState.caseStatuses.map((status: any) => {
                                    return <Select.Option key={status.value}
                                                          value={status.value}>{status.label}</Select.Option>
                                })}
                                {formState.caseStatusSearch && (
                                    <Select.Option key="custom"
                                                   value={formState.caseStatusSearch}>Add &ldquo;{formState.caseStatusSearch}&rdquo;</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Assigned To"
                            name="assigned_to"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Search for a user"
                                filterOption={filterSelect}
                                loading={formState.loadingUsers}
                                options={formState.users}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {formState.operatorModalVisible && (
                <OperatorModal onAdd={operatorAdded}
                               onClose={() => setFormState({...formState, operatorModalVisible: false})}/>
            )}
            {formState.locationModalVisible && (
                <LocationModal onAdd={locationAdded}
                               onClose={() => setFormState({...formState, locationModalVisible: false})}/>
            )}
        </Spin>
    );
})

export default YBMForm;
