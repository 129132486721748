import React, {useContext, useEffect, useState} from 'react';
import {notification, Skeleton, Modal, Button} from 'antd';
import BABView from '../../../Pages/Cases/BAB/View';
import ComplaintView from '../../../Pages/Cases/Complaint/View';
import EnquiryView from '../../../Pages/Cases/Enquiry/View';
import FeedbackView from '../../../Pages/Cases/Feedback/View';
import YBMView from '../../../Pages/Cases/YBM/View';
import AjaxService from '../../../Services/AjaxService';
import {ProfileContext, useCan} from "../../../Contexts/ProfileContext";
import './index.less';

function CaseWrapper(props: any) {

    let isCancelled = false;

    const [content, setContent] = useState({
        loading: true,
        rowData: false as any,
        lockedStatus: false as any,
        readOnly: false
    });

    const {profile} = useContext(ProfileContext);
    const {can, canCaseAction} = useCan(profile);

    const checkStatus = async () => {
        try {
            let response = await AjaxService.get(`cases/lock-status/${props.id}`);
            setContent({
                ...content,
                readOnly: props.readOnly,
                lockedStatus: {
                    locked_by: response.data.locked_by,
                    locked_at: response.data.locked_at
                }
            });
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    const fetchData = async () => {
        try {
            console.log('CaseWrapper/View: fetching data...');
            let response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta'], 'initial_response': true});
            if (!isCancelled) {
                if (response.data.locked_by && !response.data.locked_by.me && !can('cases.unlock')) {
                    return props.closeTab(response.data.id);
                }
                setContent({
                    ...content,
                    loading: false,
                    rowData: response.data,
                    readOnly: props.readOnly,
                    lockedStatus: {
                        locked_by: response.data.locked_by,
                        locked_at: response.data.locked_at
                    }
                });
            }
        } catch (e: any) {
            props.closeTab(props.id);
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    };

    const watch = async (status: any) => {

        if (!can('cases.watch')) {
            return notification.error({
                message: 'Unauthorised Action',
                description: 'You are not permitted to complete this action.'
            });
        }

        try {
            status = (status ? 1 : 0);
            await AjaxService.put(`cases/${content.rowData.id}/watch/${status}`, {});

            setContent({
                ...content, rowData: {
                    ...content.rowData,
                    watched: status
                }
            });

            notification.success({
                message: 'Watch Case',
                description: 'You are ' + (status ? 'now' : 'no longer') + ' watching this case',
                placement: 'topRight',
            });
        } catch (e: any) {
            props.closeTab(props.id);
            notification.error({
                message: 'Watch Case',
                description: 'There was a problem ' + (status ? '' : 'un') + 'watching this case. Please try again.'
            });
        }
    }

    const lockCase = async () => {
        let response = await AjaxService.put(`cases/lock/${props.id}/true`, {});
        if (response.data.status !== 'success') {
            notification.error({
                message: 'There was an error',
                description: response.data.message
            });
            return;
        }
    }

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        if (profile) {
            fetchData();
        }
        return () => {
            isCancelled = true;
        };
    }, [profile, props.checksum]);

    useEffect(() => {
        if (props.activeTab) {
            checkStatus();
        }
    }, [props.activeTab]);

    return (
        <Skeleton
            loading={content.loading}
            active
        >
            {content.rowData && canCaseAction(content.rowData, 'view') && (
                <>
                    {content.rowData.type.value === 'bab' && (
                        <BABView {...content} id={props.id} readOnly={content.readOnly} checkTabLock={props.checkTabLock} checksum={props.checksum} openTab={props.openTab} caseAction={props.caseAction} editCase={props.editCase} addCase={props.addCase}
                                 watch={watch} archiveCase={props.archiveCase}/>
                    )}
                    {content.rowData.type.value === 'complaint' && (
                        <ComplaintView {...content} id={props.id} readOnly={content.readOnly} checkTabLock={props.checkTabLock} checksum={props.checksum} openTab={props.openTab} caseAction={props.caseAction} editCase={props.editCase} addCase={props.addCase}
                                       watch={watch} archiveCase={props.archiveCase}/>
                    )}
                    {content.rowData.type.value === 'enquiry' && (
                        <EnquiryView {...content} id={props.id} readOnly={content.readOnly} checkTabLock={props.checkTabLock} checksum={props.checksum} openTab={props.openTab} caseAction={props.caseAction} editCase={props.editCase} addCase={props.addCase}
                                     watch={watch} archiveCase={props.archiveCase}/>
                    )}
                    {content.rowData.type.value === 'feedback' && (
                        <FeedbackView {...content} id={props.id} readOnly={content.readOnly} checkTabLock={props.checkTabLock} checksum={props.checksum} openTab={props.openTab} caseAction={props.caseAction} editCase={props.editCase} addCase={props.addCase}
                                      watch={watch} archiveCase={props.archiveCase}/>
                    )}
                    {content.rowData.type.value === 'ybm' && (
                        <YBMView {...content} id={props.id} readOnly={content.readOnly} checkTabLock={props.checkTabLock} checksum={props.checksum} openTab={props.openTab} caseAction={props.caseAction} editCase={props.editCase} addCase={props.addCase}
                                 watch={watch} archiveCase={props.archiveCase}/>
                    )}
                </>
            )}
        </Skeleton>
    );
}

export default CaseWrapper;
