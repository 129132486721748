import React, {useContext, useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Input, Modal, notification, Row, Select, Spin, Tooltip} from 'antd';
import RegionModal from "../RegionModal";
import './index.less';
import AjaxService from "../../Services/AjaxService";
import {PlusOutlined} from "@ant-design/icons";
import {ProfileContext, useCan} from "../../Contexts/ProfileContext";

function LocationModal(props: any) {

    const [form] = Form.useForm();

    const {profile} = useContext(ProfileContext);
    const {can, canAny} = useCan(profile);

    const [modalState, setModalState] = useState({
        loading: false,
        regions: [] as any,
        loadingRegions: true,
        error: '',
        search: ''
    });

    const [regionState, setRegionState] = useState({
        visible: false
    });

    const fetchRegions = async () => {
        try {
            const regions = await AjaxService.get('regions');
            setModalState({...modalState, regions: regions.data, loadingRegions: false});
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    }

    useEffect(() => {
        fetchRegions();
    }, []);

    useEffect(() => {
        if (props.current) {
            props.current.region_id = parseInt(props.current.region_id, 10);
            form.setFieldsValue(props.current);
        }
    }, [props, form]);

    const addLocation = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            let response = await AjaxService.post('locations', values);
            props.onAdd(response.data);
            closeModal();
        } catch (e: any) {
            if (!('title' in e.data.errors)) {
                notification.error({
                    message: 'Add Location',
                    description: 'There was an issue adding the location. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    error: ('title' in e.data.errors ? e.data.errors.title[0] : ''),
                    loading: false
                };
            });
        }
    }

    const editLocation = async (values: any) => {
        setModalState((previous: any) => {
            return {
                ...previous,
                error: '',
                loading: true
            };
        });

        try {
            let response = await AjaxService.put('locations/' + props.current.id, values);
            props.onAdd(response.data);
            closeModal();
        } catch (e: any) {
            if (!('title' in e.data.errors)) {
                notification.error({
                    message: 'Edit Location',
                    description: 'There was an issue editing the location. Please try again.'
                });
            }
            setModalState((previous: any) => {
                return {
                    ...previous,
                    error: ('title' in e.data.errors ? e.data.errors.title[0] : ''),
                    loading: false
                };
            });
        }
    }

    const closeModal = async () => {
        setModalState((previous: any) => {
            return {
                ...previous,
                loading: false
            };
        });
        form.resetFields();
        props.onClose();
    }

    const regionChanged = (region: any) => {
        fetchRegions();
        if (region) {
            form.setFieldsValue({
                region_id: region.id
            });
            notification.success({
                message: 'Add Region',
                description: `The region was successfully created.`
            });
        }
    }

    return (
        <>
            <Modal
                visible
                title={props.current ? 'Edit Location' : 'Add Location'}
                onOk={() => form.submit()}
                onCancel={closeModal}
                destroyOnClose
                forceRender
                confirmLoading={modalState.loading}
            >
                <Form
                    form={form}
                    onFinish={(values: any) => {
                        (props.current ? editLocation(values) : addLocation(values));
                    }}
                    layout="vertical"
                >
                    {modalState.error !== '' && (
                        <Alert style={{marginBottom: '1rem'}} message={modalState.error} type="error" showIcon/>
                    )}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Location"
                                name="title"
                                rules={[{required: true, message: 'Location is required!'}]}
                            >
                                <Input placeholder="Location"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className="ant-form-item-label">
                                <label className="ant-form-item-required">Region</label>
                            </div>
                            <Input.Group compact>
                                <Form.Item
                                    name="region_id"
                                    rules={[{required: true, message: 'Region is required!'}]}
                                    style={{width: "calc(100% - 32px)"}}
                                >
                                    <Select
                                        showSearch
                                        onChange={(value: any, option: any) => {
                                            if (option.key === 'custom') {
                                                setModalState({
                                                    ...modalState,
                                                    search: '',
                                                    regions: [
                                                        ...modalState.regions, {
                                                            id: value,
                                                            title: value
                                                        }
                                                    ]
                                                });
                                                form.setFieldsValue({
                                                    region_id: value
                                                });
                                            }
                                        }}
                                        onSearch={(value: any) => setModalState({...modalState, search: value})}
                                    >
                                        {modalState.regions && modalState.regions.map((region: any) => {
                                            return <Select.Option key={region.id}
                                                                  value={region.id}>{region.title}</Select.Option>
                                        })}
                                        {modalState.search && (
                                            <Select.Option key="custom"
                                                           value={modalState.search}>Add &ldquo;{modalState.search}&rdquo;</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                {can('settings.regions.add') && (
                                    <Tooltip title="Add Region">
                                        <Button icon={<PlusOutlined/>} onClick={() => setRegionState({...regionState, visible: true})} />
                                    </Tooltip>
                                )}
                            </Input.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {can('settings.regions.add') && regionState.visible && (
                <RegionModal onAdd={(region: any) => regionChanged(region)}
                             onClose={() => setRegionState({...regionState, visible: false})}/>
            )}
        </>
    );
}

export default LocationModal;