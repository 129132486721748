import React, {useEffect, useState} from 'react';
import './index.less';
import {Col, notification, Row, Skeleton} from "antd";
import AjaxService from "../../../../Services/AjaxService";
import moment from "moment";

function YBMRow(props: any) {

    let isCancelled = false;

    const [rowState, setRowState] = useState({
        loading: true,
        rowData: false as any
    });

    const fetchData = async () => {
        try {
            console.log('YBM/Row: fetching data...');
            let response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta']});
            if (!isCancelled) {
                setRowState({...rowState, loading: false, rowData: response.data});
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        if ('id' in props && props.id && !rowState.rowData) {
            fetchData();
        } else {
            setRowState({...props});
        }
        return () => {
            isCancelled = true;
        };
    }, [props.id, props.rowData]);

    return (
        <Skeleton active loading={rowState.loading}>
            {!rowState.loading && rowState.rowData !== false && (
                <Row>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Date</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.meta['Date'] ? moment(rowState.rowData.meta['Date'], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') + ' ' + (parseInt(rowState.rowData.meta['Date Confirmed'], 10) === 1 ? ' (Confirmed)' : '') : 'N/A')}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Location</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.location ? `${rowState.rowData.location.title}, ${rowState.rowData.location.region}` : 'N/A')}</Col>
                         {/* style={{padding: '4px 0'}}>{rowState.rowData.location ?? 'N/A'}</Col> */}

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Bus Company</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{(rowState.rowData.operator ? rowState.rowData.operator.title + (rowState.rowData.operator.deleted_at ? ' (D)' : '') : 'N/A')}</Col>
                    {/* <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{rowState.rowData.operator ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>BUUK Attendees</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>
                        {rowState.rowData.meta['Organiser'] ? rowState.rowData.meta['Organiser'] + '*' : ''}
                        {rowState.rowData.meta['Organiser'] && rowState.rowData.meta['BUUK Attendees'] && rowState.rowData.meta['BUUK Attendees'].length ? ', ' : ''}
                        {rowState.rowData.meta['BUUK Attendees'] && rowState.rowData.meta['BUUK Attendees'].length ? rowState.rowData.meta['BUUK Attendees'].join(', ') : ''}
                        {!rowState.rowData.meta['Organiser'] && rowState.rowData.meta['BUUK Attendees'] && rowState.rowData.meta['BUUK Attendees'].length === 0 ? 'N/A' : ''}
                        {(!rowState.rowData.meta['BUUK Attendees'] && !rowState.rowData.meta['Organiser'] ? 'N/A' : '')}
                    </Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Councils/Bus Ops
                        Invited</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Councils/Bus Ops Invited'] ? rowState.rowData.meta['Councils/Bus Ops Invited'] : 'N/A'}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Local Reps Invited</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Local Reps Invited'] ? rowState.rowData.meta['Local Reps Invited'] : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Councils Attending</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Councils Attending'] ? rowState.rowData.meta['Councils Attending'] : 'N/A'}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Operators Attending</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Operators Attending'] ? rowState.rowData.meta['Operators Attending'] : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Passenger Focus</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Passenger Focus'] ? (parseInt(rowState.rowData.meta['Passenger Focus'], 10) === 1 ? 'Yes' : 'No') : 'N/A'}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Poster</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Poster'] ? rowState.rowData.meta['Poster'] : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Pre Press Release</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Pre Press Release'] ? rowState.rowData.meta['Pre Press Release'] : 'N/A'}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Post Press Release</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Post Press Release'] ? rowState.rowData.meta['Post Press Release'] : 'N/A'}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Forms in
                        Shepperton/Cardiff</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Forms in Shepperton/Cardiff'] ? (parseInt(rowState.rowData.meta['Forms in Shepperton/Cardiff'], 10) === 1 ? 'Yes' : 'No') : 'N/A'}</Col>
                </Row>
            )}
        </Skeleton>
    );
}

export default YBMRow;