import React from 'react';
import Master from '../../Components/Layouts/Master';
import './index.less';

function Dashboard() {
    return (
        <Master className="Dashboard">
        </Master>
    );
}

export default Dashboard;
