import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Input, PageHeader, Row, Space, Tag, Tooltip} from 'antd';
import CaseTabs from '../../../../Components/CaseTabs';
import {
    CommentOutlined,
    EditOutlined,
    EyeInvisibleTwoTone,
    EyeOutlined, HistoryOutlined,
    MinusOutlined,
    PlusOutlined
} from '@ant-design/icons';
import YBMRow from '../Row';
import './index.less';
import {ProfileContext, useCan} from "../../../../Contexts/ProfileContext";

function YBM(props: any) {

    const [tabState, setTabState] = useState(props);
    const [modalState, setModalState] = useState({
        visible: false,
        loading: false,
        response_type: 'external'
    });
    const [feedbackInputState, setFeedbackInputState] = useState('');
    const [feedbackEntryState, setFeedbackEntryState] = useState({
        visible: false,
        items: [{
            id: '1',
            title: 'Service reliability',
            current: 0
        }, {
            id: '2',
            title: 'Driver/staff attitude',
            current: 0
        }, {
            id: '3',
            title: 'Frequency of Service',
            current: 0
        }, {
            id: '4',
            title: 'Vehicle Condition/type',
            current: 0
        }, {
            id: '5a',
            title: 'Bus failed to stop',
            current: 0
        }, {
            id: '5b',
            title: 'Bus failed to show',
            current: 0
        }, {
            id: '6',
            title: 'Infrastructure',
            current: 0
        }, {
            id: '7',
            title: 'Compliment',
            current: 0
        }, {
            id: '8',
            title: 'Capacity',
            current: 0
        }, {
            id: '9a',
            title: 'Disability Issues',
            current: 0
        }, {
            id: '9b',
            title: 'Accessibility Issues',
            current: 0
        }, {
            id: '10a',
            title: 'Ticket Prices',
            current: 0
        }, {
            id: '10b',
            title: 'Ticket Acceptance',
            current: 0
        }, {
            id: '10c',
            title: 'Ticket Systems (Other)',
            current: 0
        }, {
            id: '10d',
            title: 'Ticket Systems (Concessionary pass matters)',
            current: 0
        }, {
            id: '11',
            title: 'Personal injury and accident',
            current: 0
        }, {
            id: '12',
            title: 'Incorrect/Inadequate information',
            current: 0
        }, {
            id: '13',
            title: 'Smoking',
            current: 0
        }, {
            id: '14',
            title: 'Other',
            current: 0
        }, {
            id: '15',
            title: 'Luggage',
            current: 0
        }, {
            id: '16 ',
            title: 'EPRR',
            current: 0
        }]
    });

    const {profile} = useContext(ProfileContext);
    const {can, canCaseAction} = useCan(profile);

    let caseTabsRef = React.createRef();

    const decreaseFeedback = (idx: any) => {
        feedbackEntryState.items[idx].current--;
        if (feedbackEntryState.items[idx].current < 0) {
            feedbackEntryState.items[idx].current = 0;
        }
        setFeedbackEntryState({...feedbackEntryState, items: feedbackEntryState.items});
    }

    const increaseFeedback = (idx: any) => {
        feedbackEntryState.items[idx].current++;
        setFeedbackEntryState({...feedbackEntryState, items: feedbackEntryState.items});
    }

    const handleFeedbackKeypress = (e: any) => {
        if (e.which === 13) {
            for (let idx = 0; idx < feedbackEntryState.items.length; idx++) {
                if (feedbackEntryState.items[idx].id === e.target.value) {
                    increaseFeedback(idx);
                    break;
                }
            }
            setFeedbackInputState('');
        }
    }

    useEffect(() => {
        setTabState(props);
    }, [props]);

    return (
        <div className="CaseYBM">
            <PageHeader title="Your Bus Matters" extra={[
                (can('cases.watch') && (
                    <Tooltip title={(tabState.rowData.watched ? 'Unwatch' : 'Watch') + ' YBM'} key="watch">
                        <Button onClick={() => props.watch(!tabState.rowData.watched)}
                                icon={(tabState.rowData.watched ? <EyeInvisibleTwoTone twoToneColor="#52c41a"/> :
                                    <EyeOutlined/>)}/>
                    </Tooltip>
                )),
                (can('cases.ybm.edit') && (
                    <Tooltip title="Edit YBM" key="edit">
                        <Button onClick={() => props.editCase('ybm', tabState.rowData.id)} icon={<EditOutlined/>}/>
                    </Tooltip>
                )),
                (can('cases.ybm.archive') && (tabState.rowState?.status?.title || tabState.rowState?.status?.title !== 'Archived') && (
                    <Tooltip title={'Archive YBM'} key="archive">
                        <Button onClick={() => props.archiveCase(tabState.rowData.id)} icon={<HistoryOutlined />}/>
                    </Tooltip>
                )),
                (canCaseAction(tabState.rowData, 'respond') && (
                    <Button key="respond" onClick={() => setModalState({...modalState, visible: true, response_type: 'external'})}
                            icon={<PlusOutlined/>}>
                        Add response
                    </Button>
                )),
                (canCaseAction(tabState.rowData, 'feedback') && (
                    <Button key="feedback" onClick={() => setFeedbackEntryState({...feedbackEntryState, visible: true})}
                            icon={<CommentOutlined/>}>
                        Add feedback
                    </Button>
                ))
            ]} tags={[
                <Tag key="status-tag" color="green">{tabState.rowData.status.title}</Tag>
            ]}/>
            {!feedbackEntryState.visible && (
                <>
                    <YBMRow {...tabState} openTab={props.openTab}/>
                    <CaseTabs case={tabState.rowData} ref={caseTabsRef} checksum={props.checksum} modalState={modalState}
                              setModalState={setModalState} checkTabLock={props.checkTabLock}/>
                </>
            )}
            {feedbackEntryState.visible && (
                <>
                    <div style={{padding: '0 8px'}}>
                        <Row align="middle" style={{marginBottom: '30px'}}>
                            <Col span={24} md={10}>
                                <strong>Entering comment data for: Kirkwall, Orkney
                                    - 25/2/2019</strong>
                            </Col>
                            <Col span={24} md={4}>
                                <Input value={feedbackInputState}
                                       onChange={(e) => setFeedbackInputState(e.target.value)}
                                       onKeyPress={handleFeedbackKeypress}
                                       placeholder="Enter comment code and press enter"/>
                            </Col>
                            <Col span={24} md={10} style={{textAlign: 'right'}}>
                                <Space>
                                    <Button onClick={() => setFeedbackEntryState({
                                        ...feedbackEntryState,
                                        visible: false
                                    })}>Back</Button>
                                    <Button type="primary">Add Feedback</Button>
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            {feedbackEntryState.items.map((item: any, idx: any) => (
                                <Col span={24} md={8} lg={6} xl={3} key={idx}>
                                    <div className="YBMFeedbackEntry">
                                        <strong>{item.id}</strong>
                                        <small>{item.title}</small>
                                        <span>{item.current}</span>
                                        <Space>
                                            <Button size="small" icon={<MinusOutlined/>}
                                                    onClick={() => decreaseFeedback(idx)} key="minus"/>
                                            <Button size="small" icon={<PlusOutlined/>}
                                                    onClick={() => increaseFeedback(idx)} key="plus"/>
                                        </Space>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </>
            )}
        </div>
    );
}

export default YBM;