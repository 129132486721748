import { createContext } from 'react';
export const ProfileContext = createContext({
    profile: false as any,
    updateProfile: (profile: any) => {}
});

export const useCan = (profile: any) => {

    let permissions = (profile ? Object.keys(profile.permissions).map((id: any) => profile.permissions[id]) : []);

    const can = (permission: any) => {
        return profile && permissions.indexOf(permission) > -1;
    }

    const canAny = (toCheck: any) => {
        return profile && toCheck.filter((p: any) => permissions.indexOf(p) > -1).length > 0;
    }

    const canAll = (toCheck: any) => {
        return profile && toCheck.filter((p: any) => permissions.indexOf(p) > -1).length === toCheck.length;
    }

    const canCaseAction = (ticket: any, action: any, contextual: any = true) => {
        return (
            can('cases.' + ticket.type.value + '.' + action + (contextual ? '_any' : '')) ||
            (contextual && can('cases.' + ticket.type.value + '.' + action + '_own') &&
                ticket.assigned_to?.id === profile.id)
        );
    }

    const canCaseActionAll = (ticket: any, actions: any) => {
        return actions.filter((a: any) => canCaseAction(ticket, a)).length === actions.length;
    }

    const canCaseActionAny = (ticket: any, actions: any) => {
        return actions.filter((a: any) => canCaseAction(ticket, a)).length > 0;
    }

    return {
        can,
        canAny,
        canAll,
        canCaseAction,
        canCaseActionAll,
        canCaseActionAny
    }
}