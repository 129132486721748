import React, {useContext, useEffect, useState} from 'react';
import {Button, PageHeader, Tag, Tooltip} from 'antd';
import CaseTabs from '../../../../Components/CaseTabs';
import Row from '../Row';
import {EditOutlined, EyeInvisibleTwoTone, EyeOutlined, HistoryOutlined, PlusOutlined} from '@ant-design/icons';
import './index.less';
import {ProfileContext, useCan} from "../../../../Contexts/ProfileContext";

function BAB(props: any) {

    const [tabState, setTabState] = useState(props);
    const [modalState, setModalState] = useState({
        visible: false,
        loading: false,
        response_type: 'external'
    });

    const {profile} = useContext(ProfileContext);
    const {can, canCaseAction} = useCan(profile);

    let caseTabsRef = React.createRef();

    useEffect(() => {
        setTabState(props);
    }, [props]);

    return (
        <div className="CaseBAB">
            <PageHeader title="BAB" extra={[
                (can('cases.watch') && (
                    <Tooltip title={(tabState.rowData.watched ? 'Unwatch' : 'Watch') + ' BAB'} key="watch">
                        <Button onClick={() => props.watch(!tabState.rowData.watched)}
                            icon={(tabState.rowData.watched ? <EyeInvisibleTwoTone twoToneColor="#52c41a"/> :
                                <EyeOutlined/>)}/>
                    </Tooltip>
                )),
                (can('cases.bab.edit') && (
                    <Tooltip title="Edit BAB" key="edit">
                        <Button onClick={() => props.editCase('bab', tabState.rowData.id)} icon={<EditOutlined/>}/>
                    </Tooltip>
                )),
                (can('cases.bab.archive') && (tabState.rowState?.status?.title || tabState.rowState?.status?.title !== 'Archived') && (
                    <Tooltip title={'Archive BAB'} key="archive">
                        <Button onClick={() => props.archiveCase(tabState.rowData.id)} icon={<HistoryOutlined />}/>
                    </Tooltip>
                )),
                (canCaseAction(tabState.rowData, 'respond') && (
                    <Button key="respond" onClick={() => setModalState({...modalState, visible: true, response_type: 'external'})}
                            icon={<PlusOutlined/>}>
                        Add response
                    </Button>
                ))
            ]} tags={[
                <Tag key="status-tag" color="green">{tabState.rowData.status.title}</Tag>
            ]}/>
            <Row {...tabState} openTab={props.openTab}/>
            <CaseTabs case={tabState.rowData} ref={caseTabsRef} checksum={props.checksum} modalState={modalState}
                      setModalState={setModalState} checkTabLock={props.checkTabLock}/>
        </div>
    );
}

export default BAB;