import React, {useContext, useEffect, useState} from 'react';
import {Col, notification, Row, Skeleton} from 'antd';
import AjaxService from '../../../../Services/AjaxService';
import moment from 'moment';
import {ProfileContext, useCan} from "../../../../Contexts/ProfileContext";
import './index.less';

function EnquiryRow(props: any) {

    let isCancelled = false;

    const {profile} = useContext(ProfileContext);
    const {can} = useCan(profile);

    const [rowState, setRowState] = useState({
        loading: true,
        rowData: false as any
    });

    const fetchData = async () => {
        try {
            console.log('Enquiry/Row: fetching data...');
            let response = await AjaxService.get(`cases/${props.id}`, {'include': ['meta'], 'initial_response': true});
            if (!isCancelled) {
                setRowState({...rowState, loading: false, rowData: response.data});
            }
        } catch (e: any) {
            notification.error({
                message: 'There was an error',
                description: 'Unfortunately we could not load the required information. Please try again.'
            });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line
        isCancelled = false;
        if ('id' in props && props.id && !rowState.rowData) {
            fetchData();
        } else {
            setRowState({...props});
        }
        return () => {
            isCancelled = true;
        };
    }, [props.id, props.rowData]);

    return (
        <Skeleton active loading={rowState.loading}>
            {!rowState.loading && rowState.rowData !== false && (
                <Row>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Date</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{moment(rowState.rowData.created_at).format('DD/MM/YYYY')}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Taken By</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.taken_by ? rowState.rowData.taken_by.initials : 'N/A')}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Name</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.contact ? `${rowState.rowData.contact.first_name} ${rowState.rowData.contact.last_name}` : 'N/A')}</Col>
                         {/* style={{padding: '4px 0'}}>{rowState.rowData.meta['Name'] ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Type</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{(rowState.rowData.meta['Type'] ?? 'N/A')}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Location</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.location ? `${rowState.rowData.location.title}, ${rowState.rowData.location.region}` : 'N/A')}</Col>
                         {/* style={{padding: '4px 0'}}>{rowState.rowData.location ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Cross Border</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{Number.parseInt(rowState.rowData.meta['Cross Border']) ? 'Yes' : 'No'}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Out of Remit Code</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Out of Remit Code'] ?? 'N/A'}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Complaint</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{rowState.rowData.meta['Complaint'] ? (
                        <span onClick={() => props.openTab(
                            rowState.rowData.meta['Complaint'].id,
                            rowState.rowData.meta['Complaint'].reference,
                            'complaint'
                        )}
                              className="clickable">{(rowState.rowData.meta['Complaint'].reference ? rowState.rowData.meta['Complaint'].reference : rowState.rowData.meta['Complaint'].id)}</span>) : <>
                        N/A{can('cases.enquiry.add') && ([' - ',<a onClick={() => props.addCase('complaint', rowState.rowData, 'Add Complaint from Enquiry')}>Create</a>])}
                    </>}</Col>

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Contact</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{(rowState.rowData.contact && rowState.rowData.contact.email ? rowState.rowData.contact.email : 'N/A')}</Col>
                    {/* <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{rowState.rowData.contact ?? 'N/A'}</Col> */}
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Bus Company</strong></Col>
                    <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{(rowState.rowData.operator ? rowState.rowData.operator.title + (rowState.rowData.operator.deleted_at ? ' (D)' : '') : 'N/A')}</Col>
                    {/* <Col span={24} sm={12} lg={8} style={{padding: '4px 0'}}>{rowState.rowData.operator ?? 'N/A'}</Col> */}

                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Classification</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{rowState.rowData.meta['Classification'] ? rowState.rowData.meta['Classification'] : 'N/A'}</Col>
                    <Col span={24} sm={12} lg={4} style={{padding: '4px 0'}}><strong>Assigned To</strong></Col>
                    <Col span={24} sm={12} lg={8}
                         style={{padding: '4px 0'}}>{(rowState.rowData.assigned_to ? rowState.rowData.assigned_to.initials : 'N/A')}</Col>
                </Row>
            )}
        </Skeleton>
    );
}

export default EnquiryRow;